import React, { useState } from 'react'
import Header from './header'
import Navbar from './navbar'
import Footer from './footer'
import sideGridRightImage from '../assets/images/sideGridRight.png';
import kartheekapuranamData from '../assets/jsons/Kartheeka_Puranam';

const Karthikapuranam = () => {
    const [activeKey, setActiveKey] = useState(0);

    const handleToggle = (index) => {
        setActiveKey(activeKey === index ? null : index);
    };
    const [leftSticky, setLeftSticky] = useState(true);
    const [rightSticky, setRightSticky] = useState(true);

    return (
        <div>
            <Header />
            <Navbar />
            <div className="container-fluid" style={{ background: '#ffe6d0' }}>
                <div className="row">
                    <div className="col-lg-1 col-md-1 leftFlowerImage"></div>
                    <div
                        className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
                        onMouseEnter={() => {
                            setLeftSticky(false);
                            setRightSticky(true);
                        }}
                        style={{
                            overflowY: leftSticky ? 'hidden' : 'auto',
                            height: '80vh',
                            position: leftSticky ? 'sticky' : 'static',
                            top: 0,
                        }}
                    >
                        <div className="row">
                            <div id="accordion">
                                {kartheekapuranamData.map((section, index) => (
                                    <div className="card mb-3" key={index}
                                        onClick={() => handleToggle(index)}
                                        style={{
                                            backgroundColor: activeKey === index ? '#B35C0C' : '#754213',
                                            color: '#FFFFFF',
                                            cursor: 'pointer'
                                        }}>
                                        <div className="card-header" id={`heading${index}`}>
                                            <h5 className="mb-0">
                                                <div
                                                    className="btn btn"
                                                    aria-expanded={activeKey === index}
                                                    aria-controls={`collapse${index}`}
                                                    style={{ color: '#FFFFFF' }}
                                                >
                                                    {section.title}
                                                </div>
                                            </h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
                        onMouseEnter={() => {
                            setLeftSticky(true);
                            setRightSticky(false);
                        }}
                        style={{
                            overflowY: rightSticky ? 'hidden' : 'auto',
                            height: '80vh',
                            position: rightSticky ? 'sticky' : 'static',
                            top: 0,
                        }}
                    >
                        {kartheekapuranamData.map((section, index) => (
                            <div
                                id={`collapse${index}`}
                                className={`collapse  ${activeKey === index ? 'show' : ''}`}
                                aria-labelledby={`heading${index}`}
                                data-parent="#accordion"
                                key={`collapse-content-${index}`}
                            >

                                <div className="card-body rightPanel">
                                    <h5> {section.title}</h5>
                                    {section.CatID.map((content, idx) => (
                                        <div>
                                            <ul key={idx}>{content.title}</ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-1 col-md-1 rightFlowerImage"></div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Karthikapuranam