import './App.css';
import { Routes, Route } from "react-router-dom";
import Index from './components';
import Calendar from './components/calendar';
import Rasiphalalu from './components/rasiphalalu';
import Panchangam from './components/panchagam';
import Bhavishyavani from './components/astrology/bhavishyavani';
import Grahalu from './components/astrology/grahalu';
import Sankyashastram from './components/astrology/sankyashastram';
import Vastuchitkalu from './components/astrology/vastuchitkalu';
import Pillishastram from './components/shakunashastram/pillishastram';
import Ballishastram from './components/shakunashastram/ballishastram';
import Villuvinasamacharam from './components/villuvinasamacharam/villuvinasamacharam';
import Samskrutham from './components/villuvinasamacharam/samskrutham';
import Samethalu from './components/villuvinasamacharam/samethalu';
import Nithipadhyalu from './components/villuvinasamacharam/nithipadhyalu';
import Nithyasathyalu from './components/villuvinasamacharam/nithyasathyalu';
import Rastralabashalu from './components/villuvinasamacharam/rastralabashalu';
import Grahanam from './components/villuvinasamacharam/grahanam';
import Sankyapradanyata from './components/etharamulu/sankyapradanyata';
import Telugusamskrutham from './components/etharamulu/telugusamskrutham';
import Namakaranam from './components/etharamulu/namakaranam';
import Upavasam from './components/bhakthiranjini/upavasam';
import Thirdayathra from './components/bhakthiranjini/thirdayathra';
import Slokalu from './components/bhakthiranjini/slokalu';
import Shotramlu from './components/bhakthiranjini/shotramlu';
import Ashtotharalu from './components/bhakthiranjini/ashtotharalu';
import Athyadhimikashakthi from './components/bhakthiranjini/athyadhimikashakthi';
import Muhurthalu from './components/nakshatrapontana/muhurthalu';
import Vivahanakshatralu from './components/nakshatrapontana/vivahanakshatralu';
import Dharmasandehalu from './components/hindudarmavaibavam/dharmasandehalu';
import Jeevanashaili from './components/hindudarmavaibavam/jeevanashaili';
import Masavishistatha from './components/hindudarmavaibavam/masavishistatha';
import Srustirahasyalu from './components/hindudarmavaibavam/srustirahasyalu';
import Hindudarmashastram from './components/hindudarmavaibavam/hindudarmashastram';
import Pandugapramukyata from './components/hindudarmavaibavam/pandugapramukyata';
import Mukhyamainasamacharam from './components/mukhyamainasamacharam';
import Astakalu from './components/astakalu';
import Karthikapuranam from './components/karthikapuranam';
import Pandugalu from './components/pandugalu';
import Panchangam1 from './components/etharamulu/panchangam1';
import Ramayanams from './components/ramayanam';
import Mahabharatam from './components/mahabharatham/mahabharatam';
import Adhiparvam from './components/mahabharatham/adhiparvam';
import Sabhaparvam from './components/mahabharatham/sabhaparvam';
import Vanaparvam from './components/mahabharatham/vanaparvam';
import Virataparvam from './components/mahabharatham/virataparvam';
import Vudhyogaparvam from './components/mahabharatham/vudhyogaparvam';
import Bhishmaparvam from './components/mahabharatham/bhishmaparvam';
import Dhronaparvam from './components/mahabharatham/dhronaparvam';
import Karnaparvam from './components/mahabharatham/karnaparvam';
import Salyaparvam from './components/mahabharatham/salyaparvam';
import Swothikaparvam from './components/mahabharatham/swothikaparvam';
import Sriparvam from './components/mahabharatham/sriparvam';
import Santhiparvam from './components/mahabharatham/santhiparvam';
import Anusasanikaparvam from './components/mahabharatham/anusasanikaparvam';
import Aswamedhaparvam from './components/mahabharatham/aswamedhaparvam';
import Asramavasaparvam from './components/mahabharatham/asramavasaparvam';
import Mousalaparvam from './components/mahabharatham/mousalaparvam';
import Mahaprasthanikaparvam from './components/mahabharatham/mahaprasthanikaparvam';
import Swargarohanaparvam from './components/mahabharatham/swargarohanaparvam';
import Krishnajananam from './components/bhagavathgita/srikrishunijivitham';
import Pandugavisistatha from './components/bhagavathgita/pandugavisistatha';
import Bhagavathgita from './components/bhagavathgita/bhagavathgita';
import Pandugaluu from './components/bhagavathgita/pandugalu';
import Srikrishnaastrotharalu from './components/bhagavathgita/srikrishnaastrotharalu';
import Srikrishnastakam from './components/bhagavathgita/srikrishnastakam';
import Sukthulu from './components/bhagavathgita/sukthulu';
import Vasthavalu from './components/bhagavathgita/vasthavalu';
import Geethasaramsam from './components/bhagavathgita/geethasaramsam'
import Panchagammain from './components/panchagammain';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />
        <Route path="/panchagam" element={<Panchangam />} />
        <Route path="/panchagammain" element={<Panchagammain />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/rasiphalalu" element={<Rasiphalalu />} />
        <Route path="/Upavasam" element={<Upavasam />} />
        <Route path="/Athyadhimikashakthi" element={<Athyadhimikashakthi />} />
        <Route path="/Thirdayathra" element={<Thirdayathra />} />
        <Route path="/Shotramlu" element={<Shotramlu />} />
        <Route path="/Ashtotharalu" element={<Ashtotharalu />} />
        <Route path="/Slokalu" element={<Slokalu />} />
        <Route path="/Muhurthalu" element={<Muhurthalu />} />
        <Route path="/Vivahanakshatralu" element={<Vivahanakshatralu />} />
        <Route path="/Dharmasandehalu" element={<Dharmasandehalu />} />
        <Route path="/Hindudarmashastram" element={<Hindudarmashastram />} />
        <Route path="/Pandugapramukyata" element={<Pandugapramukyata />} />
        <Route path="/Masavishistatha" element={<Masavishistatha />} />
        <Route path="/Srustirahasyalu" element={<Srustirahasyalu />} />
        <Route path="/Jeevanashaili" element={<Jeevanashaili />} />
        <Route path="/sankyashastram" element={<Sankyashastram />} />
        <Route path="/grahalu" element={<Grahalu />} />
        <Route path="/bhavishyavani" element={<Bhavishyavani />} />
        <Route path="/vastuchitkalu" element={<Vastuchitkalu />} />
        <Route path="/ballishastram" element={<Ballishastram />} />
        <Route path="/Pillishastram" element={<Pillishastram />} />
        <Route path="/villuvinasamacharam" element={<Villuvinasamacharam />} />
        <Route path="/samskrutham" element={<Samskrutham />} />
        <Route path="/samethalu" element={<Samethalu />} />
        <Route path="/rastralabashalu" element={<Rastralabashalu />} />
        <Route path="/Nithyasathyalu" element={<Nithyasathyalu />} />
        <Route path="/Nithipadhyalu" element={<Nithipadhyalu />} />
        <Route path="/Grahanam" element={<Grahanam />} />
        <Route path="/Telugusamskrutham" element={<Telugusamskrutham />} />
        <Route path="/Panchangam" element={<Panchangam />} />
        <Route path="/Sankyapradanyata" element={<Sankyapradanyata />} />
        <Route path="/Mukhyamainasamacharam" element={<Mukhyamainasamacharam />} />
        <Route path="/Astakalu" element={<Astakalu />} />
        <Route path="/Karthikapuranam" element={<Karthikapuranam />} />
        <Route path="/Namakaranam" element={<Namakaranam />} />
        <Route path="/Pandugalu" element={<Pandugalu />} />
        <Route path="/Panchangam1" element={<Panchangam1 />} />
        <Route path="/Ramayanams" element={<Ramayanams />} />
        <Route path="/Mahabharatam" element={<Mahabharatam />} />
        <Route path="/Adhiparvam" element={<Adhiparvam />} />
        <Route path="/Sabhaparvam" element={<Sabhaparvam />} />
        <Route path="/Vanaparvam" element={<Vanaparvam />} />
        <Route path="/Virataparvam" element={<Virataparvam />} />
        <Route path="/Vudhyogaparvam" element={<Vudhyogaparvam />} />
        <Route path="/Bhishmaparvam" element={<Bhishmaparvam />} />
        <Route path="/Dhronaparvam" element={<Dhronaparvam />} />
        <Route path="/Karnaparvam" element={<Karnaparvam />} />
        <Route path="/Salyaparvam" element={<Salyaparvam />} />
        <Route path="/Swothikaparvam" element={<Swothikaparvam />} />
        <Route path="/Sriparvam" element={<Sriparvam />} />
        <Route path="/Santhiparvam" element={<Santhiparvam />} />
        <Route path="/Anusasanikaparvam" element={<Anusasanikaparvam />} />
        <Route path="/Aswamedhaparvam" element={<Aswamedhaparvam />} />
        <Route path="/Asramavasaparvam" element={<Asramavasaparvam />} />
        <Route path="/Mousalaparvam" element={<Mousalaparvam />} />
        <Route path="/Mahaprasthanikaparvam" element={<Mahaprasthanikaparvam />} />
        <Route path="/Swargarohanaparvam" element={<Swargarohanaparvam />} />
        <Route path="/Krishnajananam" element={<Krishnajananam />} />
        <Route path="/Pandugavisistatha" element={<Pandugavisistatha />} />
        <Route path="/Geethasaramsam" element={<Geethasaramsam />} />
        <Route path="/Bhagavathgita" element={<Bhagavathgita />} />
        <Route path="/Pandugaluu" element={<Pandugaluu />} />
        <Route path="/Srikrishnaastrotharalu" element={<Srikrishnaastrotharalu />} />
        <Route path="/Srikrishnastakam" element={<Srikrishnastakam />} />
        <Route path="/Sukthulu" element={<Sukthulu />} />
        <Route path="/Vasthavalu" element={<Vasthavalu />} />

      </Routes>
    </div>
  );
}

export default App;
