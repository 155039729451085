import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './header';
import Navbar from './navbar';
import Footer from './footer';
// import sideGridRightImage from '../assets/images/sideGridRight.png';
// import FlowerArrows from '../assets/images/flowerArrows.png';
// import RashiIcons from "../assets/images/rashiIcons/image.png";
// import Vrushabha from "../assets/images/rashiIcons/vrushabha.png";
// import Midhuna from "../assets/images/rashiIcons/midhuna.png";
// import Karkata from "../assets/images/rashiIcons/karkata.png";
// import Simha from "../assets/images/rashiIcons/simha.png";
// import Kanya from "../assets/images/rashiIcons/kanya.png";
// import Tula from "../assets/images/rashiIcons/tula.png";
// import Vrushika from "../assets/images/rashiIcons/vrushika.png";
// import Dhanushu from "../assets/images/rashiIcons/dhanushu.png";
// import Makara from "../assets/images/rashiIcons/makara.png";
// import Kumbha from "../assets/images/rashiIcons/kumbha.png";
// import Meena from "../assets/images/rashiIcons/meena.png";
// import Rectangle1545 from '../assets/images/Rectangle 1545.png';
import { useState } from 'react';
import "../assets/css/styles.css";
import Rectangle1547 from "../assets/images/Rectangle 1547.png";
import Rectangle1545 from '../assets/images/Rectangle 1545.png';
import ViewImg from "../assets/images/Group.png";
import FlowerArrows from "../assets/images/flowerArrows.png";
import TextBg from "../assets/images/textBg.png";
import RashiIcons from "../assets/images/rashiIcons/image.png";
import Image2 from "../assets/images/devotional/image2.png";
import sideGridRightImage from "../assets/images/sideGridRight.png";
import Image3 from "../assets/images/devotional/image3.png";
import Image4 from "../assets/images/devotional/image4.png";
import Image5 from "../assets/images/devotional/image5.png";
import Image6 from "../assets/images/devotional/image6.png";
import Image1 from "../assets/images/devotional/image1.png";
import Vrushabha from "../assets/images/rashiIcons/vrushabha.png";
import Midhuna from "../assets/images/rashiIcons/midhuna.png";
import Karkata from "../assets/images/rashiIcons/karkata.png";
import Simha from "../assets/images/rashiIcons/simha.png";
import Kanya from "../assets/images/rashiIcons/kanya.png";
import Tula from "../assets/images/rashiIcons/tula.png";
import Vrushika from "../assets/images/rashiIcons/vrushika.png";
import Dhanushu from "../assets/images/rashiIcons/dhanushu.png";
import Makara from "../assets/images/rashiIcons/makara.png";
import Kumbha from "../assets/images/rashiIcons/kumbha.png";
import Meena from "../assets/images/rashiIcons/meena.png";
import Sun from '../assets/images/sun.png';
import Uparrow from '../assets/images/uparrow.png'
import Downarrow from '../assets/images/downarrow.png'
import Moon from '../assets/images/moon.png';
import Telugu from "../assets/images/telugu 1.png";
import Rectangle1614 from "../assets/images/Rectangle 1614.png";
import Rectangle1564 from "../assets/images/Rectangle 1564.png"
import Right from "../assets/images/rightside.png";
import Left from "../assets/images/leftside.png";
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';

import '../assets/css/styles.css';
import { Row } from 'react-bootstrap';
import { PanchangamData } from '../assets/jsons/panchangam1';
import { LocalData } from '../assets/jsons/localdata';
import ItaramuluData from '../assets/jsons/ItaramuluData';
const teluguMonths = {
  January: "జనవరి",
  February: "ఫిబ్రవరి",
  March: "మార్చి",
  April: "ఏప్రిల్",
  May: "మే",
  June: "జూన్",
  July: "జూలై",
  August: "ఆగస్టు",
  September: "సెప్టెంబర్",
  October: "అక్టోబర్",
  November: "నవంబర్",
  December: "డిసెంబర్"
};

function Panchagammain() {
  const rashiData = [
    { src: RashiIcons, name: "మేష రాశి" },
    { src: Vrushabha, name: "వృషభ రాశి" },
    { src: Midhuna, name: "మిథున రాశి" },
    { src: Karkata, name: "కర్కాటక రాశి" },
    { src: Simha, name: "సింహ రాశి" },
    { src: Kanya, name: "కన్యా రాశి" },
    { src: Tula, name: "తులా రాశి" },
    { src: Vrushika, name: "వృశ్చిక రాశి" },
    { src: Dhanushu, name: "ధనుస్సు రాశి" },
    { src: Makara, name: "మకర రాశి" },
    { src: Kumbha, name: "కుంభ రాశి" },
    { src: Meena, name: "మీన రాశి" },
  ];
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dayData, setDayData] = useState(null);
  const [dayDataMap, setDayDataMap] = useState({});
  const styles = {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 'calc(-1 * var(--bs-gutter-y))',
    marginRight: 'calc(-0.5 * var(--bs-gutter-x))',
    marginLeft: 'calc(-0.5 * var(--bs-gutter-x))',
  };

  //For Pandugalu Json
  const [selectedFestival, setSelectedFestival] = useState(null);
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [displayedFestivals, setDisplayedFestivals] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed: 0 = January, 11 = December

    // Filter data for the current month
    const currentMonthData = LocalData.FestivalUpdated.flatMap(yearData =>
      yearData.filter((monthData, monthIndex) => monthIndex === currentMonth)
    );

    setCurrentMonthData(currentMonthData);

    // Display all festivals for the current month initially
    const allFestivals = currentMonthData.flatMap(monthData =>
      monthData.day.map(dateInfo => ({
        date: dateInfo.Date,
        festival: dateInfo.Festival
      }))
    );

    setDisplayedFestivals(allFestivals);

  }, []);

  useEffect(() => {
    console.log('Displayed festivals updated:', displayedFestivals);
  }, [displayedFestivals]);

  const loadDayData = (date) => {
    const year = 2024;
    const monthIndex = date.getMonth();
    const dayIndex = date.getDate() - 1;

    const yearData = PanchangamData.PanchangamUpdated[year];

    if (yearData && yearData[monthIndex] && yearData[monthIndex].day) {
      const monthData = yearData[monthIndex].day;
      if (monthData && monthData[dayIndex]) {
        setDayData(monthData[dayIndex]);


      } else {
        setDayData(null);
      }
    } else {
      setDayData(null);
    }
  };

  useEffect(() => {

    loadDayData(currentMonth);
  }, [currentMonth]);

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  const dateFormat = "MMMM yyyy";
  const formattedDate = format(currentMonth, dateFormat);
  const [month, year] = formattedDate.split(" ");
  const teluguMonth = teluguMonths[month];
  ///////////////////////Monthly Festivals///////////
  useEffect(() => {
    if (filteredData) {
      const currentDay = currentDate.getDate();

      const dateKeys = ['Amavas', 'Chatur', 'Ekadas', 'Purnim', 'Prados', 'Shast', 'Chavith', 'MasaShivarat'];
      const upcomingDates = [];

      dateKeys.forEach(key => {

        const dataForKey = filteredData[key];
        if (dataForKey && dataForKey.trim() !== "-" && dataForKey.trim() !== "") {
          const dateStrings = filteredData[key].split(',');

          dateStrings.forEach(dateStr => {
            const [dayOfWeek, month, day] = dateStr.trim().split(' ');
            const trimmedMonth = month.trim();
            const monthIndex = monthsInTelugu.indexOf(trimmedMonth) + 1;

            if (monthIndex > 0) {
              const eventDate = new Date(`${currentYear}-${monthIndex}-${parseInt(day, 10)}`);

              if (!isNaN(eventDate)) {
                if (eventDate >= currentDate) {
                  upcomingDates.push({ key, date: eventDate, label: dateStr.trim() });
                }
              } else {

              }
            } else {

            }

          });

        }

      });

      upcomingDates.sort((a, b) => a.date - b.date);


      const nextThreeDates = upcomingDates.slice(0, 4);
      setUpavas(nextThreeDates);
    } else {
      console.log('No data available for the current month.');
    }
  }, []);
  const monthsInTelugu = ['జనవరి', 'ఫిబ్రవరి', 'మార్చి', 'ఏప్రిల్', 'మే', 'జూన్', 'జూలై', 'ఆగష్టు', 'సెప్టెంబర్', 'అక్టోబర్', 'నవంబర్', 'డిసెంబర్'];
  const [upavas, setUpavas] = useState([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  // const currentMonth = monthsInTelugu[currentDate.getMonth()];

  const yearIndex = currentYear === '2024' ? 0 : currentYear === '2025' ? 1 : null;
  const monthIndex = monthsInTelugu.indexOf(currentMonth);
  const filteredData = yearIndex !== null && monthIndex !== -1
    ? ItaramuluData.upavasamDates[yearIndex][monthIndex]
    : null;

  const formatDate = (dateString) => {
    // Mapping of full day names to their shortened forms
    const dayOfWeekMap = {
      'ఆదివారము': 'ఆది',
      'సోమవారము': 'సోమ',
      'మంగళవారము': 'మంగళ',
      'బుధవారము': 'బుధ',
      'గురువారము': 'గురు',
      'శుక్రవారము': 'శుక్ర',
      'శనివారము': 'శని'
    };

    // Check if dateString is valid
    if (!dateString || typeof dateString !== 'string') {
      console.warn('Invalid date string provided:', dateString);
      return { dayOfWeek: '', day: '', monthYear: '' }; // Return a default object or handle the error as needed
    }

    const parts = dateString.split(', ');

    // Check if split was successful and has the expected number of parts
    if (parts.length < 2) {
      console.warn('Unexpected date string format:', dateString);
      return { dayOfWeek: '', day: '', monthYear: '' }; // Or handle the error differently
    }

    const [dayOfWeek, rest] = parts;

    const [day, monthYear] = rest.split(' ');

    // Shorten the day of the week using the map
    const shortenedDayOfWeek = dayOfWeekMap[dayOfWeek] || dayOfWeek;

    return { dayOfWeek: shortenedDayOfWeek, day, monthYear };
  };



  return (
    <div>
      <Header />
      <Navbar />

      <div className="container-fluid" style={{ background: "#ffe6d0" }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: "repeat-y",
              transform: "rotateY(180deg)",
            }}
          ></div>

          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3 center">
            {/* <div className="row"> */}
            <Row>
              <div className="col-lg-3 col-md-2" style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={Left}
                  // onClick={!isDecrementDisabled ? decrementMonth : null}
                  // className={`img-fluid ${isDecrementDisabled ? "disabled" : ""}`}
                  // style={
                  //   isDecrementDisabled
                  //     ? { opacity: 0.5, cursor: "not-allowed" }
                  //     : {}
                  // }
                  onClick={prevMonth}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "60%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Previous
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <h2 style={{ color: "#6B3300", whiteSpace: 'nowrap' }}>
                  {/* {months[currentMonthIndex]} {currentYear} */}
                  {/* 2024 */}
                  {dayData ? (<span>&nbsp;&nbsp;{`${dayData.Date} `}</span>) : (<span>{`${teluguMonth} ${year}`}</span>)}

                </h2>
              </div>
              <div className="col-lg-3 col-md-2" style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={Right}
                  onClick={nextMonth}
                // onClick={!isIncrementDisabled ? incrementMonth : null}
                // className={`img-fluid ${isIncrementDisabled ? "disabled" : ""
                //   }`}
                // style={
                //   isIncrementDisabled
                //     ? { opacity: 0.5, cursor: "not-allowed" }
                //     : {}
                // }
                />
                <div
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Next
                </div>
              </div>
            </Row>
            <div style={{ border: '2px solid #84470F', borderRadius: '15px 15px 15px 15px' }}>

              <div className="image-container">
                <img
                  src={Rectangle1614}
                  alt="Responsive"
                />

                {dayData ? (
                  <span className="image-overlay-text">
                    {`${dayData.Masam} ${dayData.Date}`}
                  </span>
                ) : (
                  <span className="image-overlay-text">
                    {`${teluguMonth} ${year}`}
                  </span>
                )}
              </div>
              <h5 style={{ color: "#6B3300", whiteSpace: 'nowrap', textAlign: 'center' }}>
                {dayData ? (dayData.Ruthuvu) : (<span>{ }</span>)}<br />
                {dayData ? (dayData.Ayanam) : (<span>{ }</span>)}<br />
                {dayData ? (dayData.Masam) : (<span>{ }</span>)}<br />
                {dayData ? (dayData.Paksham) : (<span>{ }</span>)}<br />
              </h5>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="row">
                      {dayData ? (
                        <>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} className="grid-img-top" alt="Sunrise" />
                              <div className="grid-body">
                                <h5 className="grid-title">తిధి</h5>
                                <div className="custom-divider"></div>
                                <p className="grid-text">{dayData.Thiti}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} className="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">నక్షత్రము</h5>
                                <div className="custom-divider"></div>
                                <p className="grid-text">{dayData.Nakshatra1}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} class="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">గుళిక కాలం</h5>
                                <div className="custom-divider"></div>
                                <p className="grid-text">{dayData.GulikaiKalam}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} class="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">యోగం</h5>
                                <div className="custom-divider"></div>
                                <p className="grid-text">{dayData.Yoga}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>పంచాంగం సమాచారం అందుబాటులో లేదు</p>
                      )}
                    </div>
                    <div className="row">
                      {dayData ? (
                        <>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} class="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">
                                  <img src={Sun} />
                                  <img src={Uparrow} />
                                </h5>
                                <p className="grid-text">{dayData.Sunrise}<br></br>
                                  సూర్యోదయం</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} className="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">
                                  <img src={Sun} />
                                  <img src={Downarrow} />
                                </h5>
                                <p className="grid-text">{dayData.Sunset}<br></br>
                                  సూర్యాస్తమయం</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} class="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">
                                  <img src={Moon} />
                                  <img src={Uparrow} />
                                </h5>
                                <p className="grid-text">{dayData.Moonrise}<br></br>
                                  చంద్రోదయం</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-4 ml-2">
                            <div className="grid">
                              <img src={Rectangle1564} className="grid-img-top" alt="..." />
                              <div className="grid-body">
                                <h5 className="grid-title">
                                  <img src={Moon} />
                                  <img src={Downarrow} />
                                </h5>
                                <p className="grid-text">{dayData.Moonset}<br></br>
                                  చంద్రాస్తమయం</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <p>పంచాంగం సమాచారం అందుబాటులో లేదు</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {dayData ? (
                <>
                  <div className="row align-items-center contain">
                    <div className="col labels">అమృత కాలం</div>
                    <div className="col bar" >{dayData.Amrutakalam}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">రాహుకాలం</div>
                    <div className="col bar" >{dayData.RahuKalam}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">యమగండ కాలం</div>
                    <div className="col bar" >{dayData.Yamaganda}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">దుర్ముహుర్తము</div>
                    <div className="col bar" >{dayData.DurMuhurtamulu}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">వర్జ్యం</div>
                    <div className="col bar" >{dayData.Varjyam}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">అభిజిత్</div>
                    <div className="col bar" >{dayData.Abhijit}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">చాంద్ర రాశి</div>
                    <div className="col bar" >{dayData.Chandrashtama}</div>
                  </div>
                  <br />
                  <div className="row align-items-center contain">
                    <div className="col labels">శక</div>
                    <div className="col bar" >{dayData.Shaka}</div>
                  </div>
                  <br />
                </>
              ) : (
                <p>పంచాంగం సమాచారం అందుబాటులో లేదు</p>
              )}
            </div>
              <div className="row">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                    <img
                      src={FlowerArrows}
                      className="img-fluid"
                      alt="Flower Arrows"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                    <div className="rashipalalu text-center">
                      <h1>ఏప్రిల్, 2024</h1>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                    <img
                      src={FlowerArrows}
                      className="img-fluid"
                      alt="Flower Arrows"
                    />
                  </div>

                  <div className="festival-container">
                    {displayedFestivals.length > 0 ? (
                      displayedFestivals.map((festivalInfo, index) => {
                        const { dayOfWeek, day, monthYear } = formatDate(festivalInfo.date);
                        return (
                          <div key={index} className="festival-item">
                            <div className="rectangle" style={{ position: 'relative', width: '65%', marginBottom: '30px' }}>
                              <img className="rectangle" src={Rectangle1545} style={{ width: '50%', height: 'auto' }} />
                              <div style={{
                                position: 'absolute',
                                top: '55%',
                                left: '25%',
                                transform: 'translate(-50%, -50%)',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                width: '75%',
                              }}>
                                <h5 className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "16px", color: '#FFFFFF', marginBottom: '3px' }}>{monthYear}</h5>
                                <div style={{
                                  borderBottom: '2px solid #FF9E46',
                                  width: '67%',
                                  margin: '0 auto 10px auto',
                                  marginBottom: '1px'
                                }}></div>
                                <small className="Pandagalu" style={{ fontFamily: 'Arbutus', fontSize: '20px', position: 'relative', display: 'block' }}>{day}</small>
                                <small className="Pandagalu" style={{ fontFamily: "Anek Telugu", fontSize: "16px", position: 'relative', display: 'block' }}>{dayOfWeek}</small>
                              </div>
                            </div>
                            <div className="festival-text browncolor">
                              <p style={{
                                textAlign: 'center',
                                marginTop: '20px',
                                fontFamily: "Anek Telugu",
                                fontSize: "20px",
                                marginRight: '10px',
                                whiteSpace: 'nowrap'
                              }}>
                                {festivalInfo.festival}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p>దయచేసి తేదీని ఎంచుకోండి</p>
                    )}
                  </div>

                </div>

                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                    <img
                      src={FlowerArrows}
                      className="img-fluid"
                      alt="Flower Arrows"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                    <div className="rashipalalu text-center">
                      <h1>మీ రాశిని ఎంచుకోండి</h1>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-3 text-center">
                    <img
                      src={FlowerArrows}
                      className="img-fluid"
                      alt="Flower Arrows"
                    />
                  </div>
                </div>

                <section className="pt-4">
                  <div className="container px-lg-5">
                    <div className="row gx-lg-5" >
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={RashiIcons} />
                                <br />
                              </div>
                              <p className="rasiGrid">మేష రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Vrushabha} />
                                <br />
                              </div>
                              <p className="rasiGrid">వృషభ రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Midhuna} />
                                <br />
                              </div>
                              <p className="rasiGrid">మిథున రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Karkata} />
                                <br />
                              </div>
                              <p className="rasiGrid">కర్కాటక రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Simha} />
                                <br />
                              </div>
                              <p className="rasiGrid">సింహ రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>

                      <br></br><br></br>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Kanya} />
                                <br />
                              </div>
                              <p className="rasiGrid">కన్యా రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Tula} />
                                <br />
                              </div>
                              <p className="rasiGrid">తులా రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Vrushika} />
                                <br />
                              </div>
                              <p className="rasiGrid">వృశ్చిక రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Dhanushu} />
                                <br />
                              </div>
                              <p className="rasiGrid">ధనుస్సు రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Makara} />
                                <br />
                              </div>
                              <p className="rasiGrid">మకర రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Kumbha} />
                                <br />
                              </div>
                              <p className="rasiGrid">కుంభ రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-2 col-xxl-2 mb-5">
                        <Link to='/rasiphalalu'>
                          <div className="card bg-brown border-0 h-100">
                            <div className="text-center">
                              <div className="feature mt-n4">
                                <img src={Meena} />
                                <br />
                              </div>
                              <p className="rasiGrid">మీన రాశి</p>
                              <br />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: "repeat-y",
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Panchagammain;