import React, { useEffect, useState } from 'react';
import Header from '../header';
import Navbar from '../navbar';
import Footer from '../footer';
import sideGridRightImage from '../../assets/images/sideGridRight.png';
import ItaramuluData from '../../assets/jsons/ItaramuluData';

const Sankyashastram = () => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [leftSticky, setLeftSticky] = useState(true);
  const [rightSticky, setRightSticky] = useState(true);

  useEffect(() => {
    setData(ItaramuluData.numericastrology);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleFilterData = () => {
    if (!data) return;

    const secondChar = searchTerm.length > 2 ? searchTerm[2].toLowerCase() : '';
    const textIndex = secondChar ? secondChar.charCodeAt(0) - 'a'.charCodeAt(0) : -1;

    const dateIndex = selectedDate ? parseInt(selectedDate.split('-')[2], 10) : -1;

    const filtered = {
      letters: data.letter[dateIndex] || '',
      luckyColors: data.luckycolor[dateIndex] || '',
      luckyDays: data.luckyday[dateIndex] || '',
      luckyNumbers: data.luckynumber[dateIndex] || '',
      negativeTraits: data.negative[dateIndex] || '',
      positiveTraits: data.positive[dateIndex] || '',
      textMessages: textIndex >= 0 && textIndex < data.text.length ? [data.text[textIndex]] : []
    };

    setFilteredData(filtered);
  };

  if (!data) {
    return <div>Loading...</div>;
  }


  return (
    <div>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              transform: 'rotateY(180deg)',
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(false);
              setRightSticky(true);
            }}
            style={{
              overflowY: leftSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: leftSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            <div className="row">
              <div>
                <div>
                  <h6 htmlFor="searchInput">మీ పేరు నమోదు చేయండి</h6>

                  <input
                    id="searchInput"
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Enter text to search"
                  />
                </div>
                <div>
                  <h6 htmlFor="dateInput">మీ పుట్టిన తేది నమోదు చేయండి</h6>
                  <input
                    id="dateInput"
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>
                <button onClick={handleFilterData}>లేకించు</button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-3"
            onMouseEnter={() => {
              setLeftSticky(true);
              setRightSticky(false);
            }}
            style={{
              overflowY: rightSticky ? 'hidden' : 'auto',
              height: '80vh',
              position: rightSticky ? 'sticky' : 'static',
              top: 0,
            }}
          >
            {filteredData && (
              <div className='card-body rightPanel'>
                <h5>What your name represents:</h5>
                <ul>
                  <h6>Lucky Colors</h6>

                  <li>{filteredData.luckyColors}</li>
                </ul>
                <ul>
                  <h6>Lucky Days</h6>
                  <li>{filteredData.luckyDays}</li>
                </ul>
                <ul>
                  <h6>Lucky Numbers</h6>
                  <li>{filteredData.luckyNumbers}</li>
                </ul>
                <ul>
                  <h6>Negative Traits</h6>
                  <li>{filteredData.negativeTraits}</li>
                </ul>
                <ul>
                  <h6>Positive Traits</h6>
                  <li>{filteredData.positiveTraits}</li>
                </ul>
                <ul>
                  <h6>Text Messages</h6>
                  {filteredData.textMessages.map((text, index) => (
                    <li key={index}>{text}</li>
                  ))}
                </ul>
                <br />
              </div>

            )}
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sankyashastram;
