import React, { useState, useEffect } from 'react';
import Logo from '../assets/images/logo.png'
import Google from '../assets/images/socialMediaIcons/GooglePlayStore.png'
import Playstore from '../assets/images/socialMediaIcons/PlayStoreBadge.png'
import LanguageEng from '../assets/images/socialMediaIcons/LanguageEng.png'
import LanguageTel from '../assets/images/socialMediaIcons/LanguageTel.png'
import Timer from '../assets/images/socialMediaIcons/timer.png'
import '../assets/css/styles.css'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [currentDateTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    });

    const formattedDate = currentDateTime.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });

    return (
        <div>
            <div className="container-fluid">
                <div className="row bg-primary">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 p-3 text-white textHide">
                        <div className="row">
                        <div className="col-lg-2 col-md-6">
                            </div>
                            
                            <div className="col-lg-4 col-md-6">

                            <a href="https://apps.apple.com/in/app/telugu-calendar-panchangam-app/id1448360812" target="_blank">
                                    <img src={Playstore} alt="Play Store Badge" width="100%" /><br></br>
                                </a> 
                                </div>

                            <div className="col-lg-4 col-md-6">
                                <a href="https://play.google.com/store/apps/details?id=telugu.indian.holidays.telugucalendar&pli=1" target="_blank">
                                    <img src={Google} alt="Google Play Store" width="100%" />
                                </a>

                               
                            </div>

                        <div className="col-lg-2 col-md-6">
                            </div>
 
                            <div className="col-lg-12 col-md-4">
                                <div className="topNav">
                                    <ul>
                                        <li>
                                            <NavLink
                                                to="/index"  activeClassName="active"
                                            >
                                                హోం
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink to='/panchagammain' activeClassName="active">పంచాంగం</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/rasiphalalu' activeClassName="active">రాశి&nbsp;ఫలాలు</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/Pandugalu' activeClassName="active"> పండుగలు</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-white">
                        <NavLink to='/index'>
                            <img className="logo" src={Logo} alt="Logo" width="100%" />
                        </NavLink>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 p-3 text-white textHide">
                        <div className="row">
                            <div className="col-lg-5 col-md-4">
                                <form className="form-inline my-2 my-lg-0">
                                    <input className="form-control mr-sm-2" type="search" placeholder="పంచాంగాన్ని శోధించండి" aria-label="Search" />
                                    <i className="fas fa-search search-icon"></i>
                                </form>
                            </div>
                            <div className="col-lg-2 col-md-4">
                                 <img src={LanguageTel} alt="Telugu Language Change" width="50px" /> 
                            
                                </div>

                            <div className="col-lg-5 col-md-4">
                              
                                <div className='timer'>
                                    <p className='timerText'>{formattedTime}<br></br> {formattedDate} </p>
                                </div>
                               
                            </div>

                            <div className="col-lg-12 col-md-4">
                                <div className="topNav">
                                    <ul>
                                        <li>
                                            <NavLink to='/Karthikapuranam'>కార్తీకపురాణం</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/Astakalu'>అష్టకాలు</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/Villuvinasamacharam'>ముఖ్యమైన సమాచారం</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header