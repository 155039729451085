import React from 'react';
import '../assets/css/styles.css';
import Twitter from '../assets/images/socialMediaIcons/twitter.png'
import Linkedin from '../assets/images/socialMediaIcons/linkedin.png'
import Instagram from '../assets/images/socialMediaIcons/instagram.png'
import Youtube from '../assets/images/socialMediaIcons/youtube.png'
import Facebook from '../assets/images/socialMediaIcons/facebook.png'
import { Link } from 'react-router-dom';
import footerBg from '../assets/images/footerbackground.png'

function Footer() {
    return (
        <><footer className="footer">
            <div className="container"
            style={{ paddingTop: '25px', background: `url(${footerBg}) no-repeat center`, backgroundSize: '100%'}}> <div className="row">
                    <div className="col-md-2">
                        <h5>భక్తిరంజని</h5>
                        <ul className="list-unstyled">
                            <li>< Link to="/Ashtotharalu">అష్టోత్తరాలు</Link></li>
                            <li>< Link to="/Slokalu">శ్లోకాలు</Link></li>
                            <li>< Link to="/Shotramlu">స్తోత్రములు</Link></li>
                            <li>< Link to="/Thirdayathra">తీర్థ యాత్ర</Link></li>
                            <li>< Link to="/Athyadhimikashakthi">అధ్యాత్మిక శక్తి</Link></li>
                            <li>< Link to="/Upavasam">ఉపవాసము</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>హిందూ ధర్మ వైభవం</h5>
                        <ul className="list-unstyled">
                            <li>< Link to="/Dharmasandehalu">ధర్మ సందేశాలు</Link></li>
                            <li> < Link to="/Hindudarmashastram">హిందూ ధర్మ శాస్త్రాలు</Link></li>
                            <li> < Link to="/Pandugapramukyata">పండుగ ప్రాముఖ్యత</Link></li>
                            <li>< Link to="/Masavishistatha">మాస విశిష్టత</Link></li>
                            <li>< Link to="/Srustirahasyalu" >సృష్టి రహస్యాలు</Link></li>
                            {/* <li><a href="#">సంప్రదాయాలు</a></li> */}
                            <li>< Link to="/Jeevanashaili">జీవిత శైలి</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>ఆస్త్రాలజీ</h5>
                        <ul className="list-unstyled">
                            <li>< Link to="/sankyashastram">సంక్య శాస్త్రం</Link></li>
                            <li>< Link to="/grahalu">గ్రహాలు</Link></li>
                            <li>< Link to="/bhavishyavani">భవిష్య వాణి</Link></li>
                            <li>< Link to="/vastuchitkalu">వాస్తు చిట్కాలు</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>శకున శాస్త్రం</h5>
                        <ul className="list-unstyled">
                            <li>< Link to="/ballishastram">బల్లి శాస్త్రం</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>నక్షత్ర పొంతన</h5>
                        <ul className="list-unstyled">
                            <li>< Link to="/Muhurthalu">ముహూర్తాలు</Link></li>
                            <li>< Link to="/Vivahanakshatralu">వివాహ నక్షత్రాలు</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5>విలువైన సమాచారం</h5>
                        <ul className="list-unstyled">
                            <li>< Link to="/Villuvinasamacharam">విలువైన సమాచారం</Link></li>
                            <li>< Link to="/Nithyasathyalu">నిత్య సత్యాలు</Link></li>
                            <li>< Link to="/Nithipadhyalu">నీతి పద్యాలు</Link></li>
                            <li>< Link to="/samethalu">సామెతలు</Link></li>
                            <li>< Link to="/samskrutham" className="dropdown-item" >సంస్కృతం</Link></li>
                            <li>< Link to="/rastralabashalu" className="dropdown-item" >రాష్ట్రాల భాషలు</Link></li>
                            <li>< Link to="/Grahanam" className="dropdown-item" >గ్రహణం</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
            <div className="container-fluid">
                <div className="row" style={{ background: '#965110' }}>
                    <div className="col-md-12 text-center topNav">
                        <div className=' footer-style'>
                        
                        <p style={{ color: '#fff' }}>Copyright ©  <a href="https://telugucalendarpanchangam.com/" rel="noopener noreferrer"> Telugu Calendar Panchangam  </a></p>
                               
                            <p style={{ color: '#fff' }}>Managed by <a href="https://telugucalendarpanchangam.com/" target="_blank" rel="noopener noreferrer">Anju Siima Technologies Pvt Ltd</a></p>
                            {/* <div className="social-icons">
                                <a href="#">
                                    <img src={Twitter} alt="Twitter" className='iconstyle' />
                                </a>
                                <a href="#">
                                    <img src={Linkedin} alt="LinkedIn" className='iconstyle' />
                                </a>
                                <a href="#">
                                    <img src={Instagram} alt="Instagram" className='iconstyle' />
                                </a>
                                <a href="#">
                                    <img src={Youtube} alt="YouTube" className='iconstyle' />
                                </a>
                                <a href="#">
                                    <img src={Facebook} alt="Facebook" className='iconstyle' />
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div></>
    );
}

export default Footer;
