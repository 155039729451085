// import React, { useState } from 'react';
// import { Carousel } from 'react-bootstrap';
// import { festivaldetailsData } from '../../assets/jsons/festivalDetails';
// import Header from '../header';
// import Footer from '../footer';
// import Navbar from '../navbar';

// const Upavasam = () => {
//   const [selectedFestival, setSelectedFestival] = useState(null);

//   const handleTitleClick = (festival) => {
//     setSelectedFestival(festival);
//   };

//   return (
//     <>
//     <Header />
//     <Navbar/>
//     <div>
//       <Carousel>
//         {Object.keys(festivaldetailsData).map((festivalKey) => {
//           const festival = festivaldetailsData[festivalKey];
//           return (
//             <Carousel.Item key={festivalKey} onClick={() => handleTitleClick(festival)}>
//               <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 schedule-card-3">
//                 <h2>{festival.title}</h2>
//               </div>
//             </Carousel.Item>
//           );
//         })}
//       </Carousel>

//       {selectedFestival && (
//         <div className="selected-festival-content">
//           <h3>{selectedFestival.title}</h3>
//           {selectedFestival.CatID && Array.isArray(selectedFestival.CatID) && (
//             <div>
//               {selectedFestival.CatID.map((item, index) => (
//                 <div key={index}>
//                   <h4>{item.title}</h4>
//                   <p>{item.content}</p>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//     <Footer/>
//     </>
//   );
// };

// export default Upavasam;


import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Navbar from '../navbar';
import sideGridRightImage from '../../assets/images/sideGridRight.png';


const Pandugapramukyata = () => {
  const location = useLocation();
  const { festival } = location.state || {};

  if (!festival) {
    return <div>No festival details available.</div>;
  }

  return (
    <>
      <Header />
      <Navbar />
      <div className="container-fluid" style={{ background: '#ffe6d0' }}>
        <div className="row">
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              transform: 'rotateY(180deg)',
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-3">
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 schedule-card-3">
                
                <h3>{festival.title}</h3>
              </div>
              {festival.CatID && Array.isArray(festival.CatID) && (
                <div>
                  {festival.CatID.map((item, index) => (
                    <div key={index}>

                      <h6>{item.title}</h6>
                      <p>{item.content}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            className="col-lg-1 col-md-1"
            style={{
              background: `url(${sideGridRightImage})`,
              backgroundRepeat: 'repeat-y',
            }}
          >
          </div>
        </div>

      </div>


      <Footer />
    </>
  );
};

export default Pandugapramukyata;
