import Dhanushu from '../images/rashiIcons/dhanushu.png';
import Vrushabha from "../images/rashiIcons/vrushabha.png";
import Midhuna from "../images/rashiIcons/midhuna.png";
import Karkata from "../images/rashiIcons/karkata.png";
import Simha from "../images/rashiIcons/simha.png";
import Kanya from "../images/rashiIcons/kanya.png";
import Tula from "../images/rashiIcons/tula.png";
import Vrushika from "../images/rashiIcons/vrushika.png";
import Makara from "../images/rashiIcons/makara.png";
import Kumbha from "../images/rashiIcons/kumbha.png";
import Meena from "../images/rashiIcons/meena.png";
import RashiIcons from "../images/rashiIcons/image.png";

export const RasiPhalaluData = {
  "RashiUpdated": [
    [
      {
        DhanasuRasi: {
          Aadayama: "08",
          Avamanam: "03",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "2",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. పనులలో సామాన్య ఫలితాలు. అనవసర సమస్యలు. చికాకులు. ధన పరమైన ఒత్తిడులు. ఆలోచనలలో అస్థిరిత. ఉద్యోగ జీవనంలో ఆఖస్మిక మార్పులు. ప్రయాణాలలో జాగ్రత్త మరియు సమయ పాలన అవసరం. కుటుంబ సహాయం. కర్షక వర్గానికి శ్రమధిక ఫలం. 20 వ తేదీ తదుపరి పరిస్థితులలో మెరుగుదల. చురుకైన పాత్ర వలన గుర్తింపు.",
          Path: Dhanushu,
          Rajapujyam: "06",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "11",
        },
        KanyaRasi: {
          Aadayama: "02",
          Avamanam: "07",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "4",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో వ్యవహారములు కొద్దిగా అనుకూలంగా నడచును. విజయాలు పొందుదురు. మీ మీ రంగాలలో చక్కటి గుర్తింపు ఏర్పడును. నూతన స్నేహ వర్గాలు ఏర్పడును. సంబంధాలు విస్తరిస్తాయి. ఇతరులకు మీరు సలహాలు ఇవ్వవలసిన పరిస్టితులు. ద్వితీయ తృతీయ వారాలలో చిన్నపాటి అనారోగ్యం. విదేశీ జీవన ప్రయాణాలు ఫలిస్తాయి. ఈ మాసంలో నూతన వాహన కొనుగోలు ప్రయత్నాలు శుభకరం.",
          Path:Kanya,
          Rajapujyam: "04",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "11",
        },
        KumbhaRasi: {
          Aadayama: "11",
          Avamanam: "06",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసం కొద్దిగా అనుకూలమైన ఫలితాలు ఇచ్చును. గౌరవ ప్రధమైన జీవనం. నూతన ఆదాయ మార్గాలు. ద్వితీయ తృతీయ వారాలలో ఆఖస్మిక కుటుంబ పరమైన ప్రయాణాలు. ప్రయనములందు చికాకులు. ధనాదాయంలో పెరుగుదల. మానసిక శ్రమ అధికం. ఈ మాసంలో 22 నుండి 29 తేదీల మధ్య కాలంలో వ్యక్తిగత జీవన సంతోషాలు.",
          Path: Kumbha,
          Rajapujyam: "05",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "05",
        },
        MakaraRasi: {
          Aadayama: "11",
          Avamanam: "06",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో సామాన్య ఫలితాలు లభిస్తాయి. సంతాన సంబంద ఆనందం లేదా సౌఖ్యం. జీవనంలో ప్రశాంతత. సజ్జన సాంగత్యం. పుత్రికా సంతతి కలిగిన వారికి నూతన బాధ్యతలు. చివరి వారంలో అధిక శారీరక శ్రమ. పేర్కొనదగిన ఇతర ప్రధాన ఫలితాలు ఏమియూ ఈ మాసంలో లేవు.",
          Path: Makara,
          Rajapujyam: "02",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "05",
        },
        MeenaRasi: {
          Aadayama: "08",
          Avamanam: "02",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "2",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో వ్యవహారములు, ప్రయత్నములు సానుకూలత పొందును. గత కాలంలో ఆపివేసిన పనులు తిరిగి ప్రారంభించుటకు ఇది మంచి సమయం. ధనాదాయం పెరుగును. కోరికలు నెరవేరును. అవసరానికి ఆశించిన ఆర్ధిక సర్దుబాటు జరుగును. కుటుంబంలో సంతోష సమయాలు. వాహన సౌఖ్యం. చివరి వారంలో విద్యార్ధులకు సమస్యలు. ఈ మాసంలో 4 నుండి 16 వ తేదీ మధ్య అన్నిరకముల ప్రయత్నాలు కలసివచ్చు సమయం.",
          Path: Meena,
          Rajapujyam: "01",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "11",
        },
        MeshaRasi: {
          Aadayama: "05",
          Avamanam: "01",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "1",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. వ్యక్త్రిగత వ్రుత్తి పరమైన జీవనంలో మిశ్రమ ఫలితాలు ఏర్పడును. వైవాహిక జీవనంలో చికాకులు, అన్యుల ప్రమేయం వలన గొడవలు ఏర్పడు సూచన. మానసిక శాంతి లోపించును. ఇతరులకు సహాయం చేయుట కూడా ఆత్మ తృప్తి ని కలుగ చేయదు. వాహన సంభందిత వ్యయం ఏర్పడును. విద్యార్ధులకు మంచి కాలం కాదు. విద్యావిఘ్నములను ఏర్పరచు సూచన. ద్వితియ మరియు తృతీయ వారములలో ఉద్యోగ మరియు వ్యాపారములలో ఆశించిన విజయం. శ్రమకు తగిన ఫలితం, గుర్తింపు ఏర్పడును. మాసాంతంలో ఆర్ధిక పరంగా వృద్ధి లభిస్తుంది.",
          Path: RashiIcons,
          Rajapujyam: "03",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "05",
        },
        MithunaRasi: {
          Aadayama: "02",
          Avamanam: "04",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "4",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో తగాదాల వలన కోర్టుకేసులు లేదా పోలిసుల జోక్యం వలన చికాకులు. వ్యాపార వ్యవహారాలు సామాన్యం. అవసరాలకు సరిపడు ధనాదాయం. మిత్రుల తోడ్పాటు లభించును. ప్రయానములక ఆరోగ్య భంగములు. జీవిత భాగస్వామి కొరకు ధనం వ్యయం చేయుదురు. అతిగా ఆలోచించడం వలన నష్టపోవు సంఘటనలు. వ్యక్తిగత జీవనంలో సౌఖ్యం. సంతాన ప్రయత్నాలు ఫలప్రదం.",
          Path: Midhuna,
          Rajapujyam: "02",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "11",
        },
        SimhaRasi: {
          Aadayama: "14",
          Avamanam: "07",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో కూడా చక్కటి ఫలితాలు కొనసాగును. నూతన వ్యాపార వ్యవహారాలకు, ఉద్యోగ ప్రయత్నాలకు ఈ మాసం చక్కటి కాలం. ఎత్తైన ప్రాంతాలు, కొండ మార్గాలలో సంచరించునపుడు జాగ్రత్తగా ఉండవలెను. విదేశే ఉద్యోగ ప్రయత్నాలు చేయువారికి కోర్కెలు నెరవేరును. కీర్తి ప్రతిష్టలు లభించును.",
          Path:Simha,
          Rajapujyam: "01",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "02",
        },
        TulaRasi: {
          Aadayama: "14",
          Avamanam: "07",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "0",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. షేర్ మార్కెట్లో పెట్టుబడులు నష్టపరుచును. ధర్మ కార్యములకు, దానములకు ధనం వినియోగించేదారు. నిరుద్యోగులకు శుభవార్తలు. వ్యాపార వ్యవహారములు, స్థిరాస్థి సంబంధ క్రయవిక్రయాలు, వ్యవసాయ సంబంధ వ్యాపారములకు చక్కటి అభివృద్ధి. పాత మిత్రులతో కలయిక వలన కాలం ఉత్సాహంగా గడచును. తృతీయ, చతుర్ధ వారములలో ఖర్చులు, శ్రమ అధికం అగును.",
          Path: Tula,
          Rajapujyam: "07",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "11",
        },
        karkatakaRasi: {
          Aadayama: "11",
          Avamanam: "04",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "2",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసం కూడా ఆశాజనకంగా ఉండును. దూర ప్రాంత ప్రయాణాలు, విదేశీ సంబంధ ఆదాయం, విదేశీ ప్రయాణాలకు అనువైన కాలం. వ్యక్తిగత జీవనంలో మాత్రం కొద్దిపాటి సమస్యలు. ధనాదాయం బాగుండును. ఆశించిన స్థాన చలనం. నూతన బాద్యతలు లేదా పదవులు పొందుటకు సూచనలు కలవు. శ్రమకు తగిన గుర్తింపు లభించును. ఈ మాసంలో 11,13,27,29 తేదీలు అనుకూలమైనవి కావు.",
          Path: Karkata,
          Rajapujyam: "05",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "08",
        },
        vrischikaRashi: {
          Aadayama: "05",
          Avamanam: "03",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో కుటుంబంలో మీ మాట మీద నమ్మకం పెరుగు సంఘటనలు, కుటుంబ వ్యవహారములందు విజయం ప్రాప్తించును. ధనాదాయం బాగుండును. విద్యార్ధులకు కొద్దిపాటి నిరాశ. పెద్ద వయస్షు వారికి వెన్నుపూస సంబంధ సమస్యలు. శస్త్ర చికిత్సకు కూడా దారి తీయవచ్చు. కుటుంబ పరమైన వ్యయం అధికంగా ఉండుటకు సూచనలు కలవు. ప్రయాణాలు శ్రమతో కూడి ఉండును. తృతీయ వారం నుండి గాయత్రీ దేవి ఆరాధన ఆరోగ్య ఆర్ధిక విషయాలలో ఉన్నతిని ఏర్పరచును. నూతన వ్యాపార ఆరంభ విషయాలలో ఆటంకములు వున్నవి. పట్టుదల అవసరం.",
          Path: Vrushika,
          Rajapujyam: "03",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "05",
        },
        vrishabhaRashi: {
          Aadayama: "14",
          Avamanam: "01",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "0",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో వ్యక్తిగత జీవన సంతోషాలు కొంచెం తక్కువగా ఉండును. వ్యాపార వ్యవహారాలు సామాన్యంగా కొనసాగును. పై అధికారులతో వివాదాలు తగ్గును. ధనవ్యయం కొద్దిగా అదుపులోకి వచ్చును. నూతన ఆలోచనలు కార్యరూపంలోకి పెట్టుటకు, పెద్దలను సంప్రదించుటకు ఈ మాసం లో 16 వ తేదీ నుండి 22 వ తేదీ వరకూ అనుకూలమైనది. చివరి వారంలో ఉద్యోగ జీవనంలో ఒక నష్టం లేదా ఆందోళన, ఆవేశపడే సంఘటనలు, వాహన సంబంధ నష్టములు ఎదురగును. ఈ మాసంలో ఉత్తరాయణ కాల ప్రారంభం తదుపరి ఈశ్వర అభిషేకములు, సకల భూత గ్రహ శాంతి జపములు జరిపించుకోనుట మంచిది.",
          Path: Vrushabha,
          Rajapujyam: "06",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "11",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "08",
          Avamanam: "03",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "2",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసం అంత ప్రోత్సాహకరమైన కాలం కాదు. ఆరోగ్య పరంగా లేదా మానసిక ఆందోళన వలన స్తబ్ధత. కుటుంబ సభ్యుల వలన తగాదాలు. ఆదాయంలో తగ్గుదల. ఊహించని విధంగా ఆర్ధిక వ్యయం. విధి నిర్వహణలో సమస్యలు.",
          Path: Dhanushu,
          Rajapujyam: "06",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "11",
        },
        KanyaRasi: {
          Aadayama: "02",
          Avamanam: "07",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "4",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో విదేశీ ప్రయాణ ప్రయత్నాలు, స్థానచలన ప్రయత్నాలు, ఉద్యోగ మార్పు ప్రయత్నాలు, నిరుద్యోగుల ఉద్యోగ అన్వేషణ ప్రయత్నాలు ఫలించును. ధనాదాయం బాగుండును. ప్రొత్సాహపూరిత కాలం. సంతోషకరమైన సమాచారం. కుటుంబంలో శుభకార్యాలు. నూతన వస్తు లాభములు. వ్యక్తిగత జీవనంలో సుఖం. ప్రణాళికాబద్ధమైన జీవనం. ధనయోగాలు. విద్యాసంబంధ ప్రయత్నాలు విజయం పొందును.",
          Path:Kanya,
          Rajapujyam: "04",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "11",
        },
        KumbhaRasi: {
          Aadayama: "11",
          Avamanam: "06",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో పనులు నెమ్మదిస్తాయి. ఆర్ధికంగా కొంత అభద్రతా భావం. కుటుంబ సహకారం. వ్యపార వ్యవహారములు సామాన్యం. విదేశీ పర్యటనలు లేదా దురాక్షేత్ర సందర్శన. భాగస్వామ్య వ్యాపారాలు లాభించును. కొత్త ఆలోచనలు గుర్తింపునిచ్చును. సృజనాత్మకత ఉపయోగపడును. సంతాన విషయాలు కొద్దిపాటి ఆందోళన కలిగించును.",
          Path: Kumbha,
          Rajapujyam: "05",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "05",
        },
        MakaraRasi: {
          Aadayama: "11",
          Avamanam: "06",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో 12వ తేదీ వరకూ వ్యాపారస్థులకు అనుకూలం. క్రీడా రంగాలోని వారికి కూడా బాగా కలసివచ్చును. స్పోర్ట్స్ కోటాలో ఉద్యోగం పొందుటకు తగిన బలాలు కలవు. కోర్టు వ్యవహారాలు అనుకూలం. తృతీయ వారం నుండి మిశ్రమ ఫలితాలు. ఒత్తిడి పెరుగును. విలువైన వస్తువులు పోగొట్టుకొండురు. వృధా వ్యయం. ఆశించిన ఫలితాలు లభించవు. రక్షణ రంగంలో సేవ చేయువారికి మంచిది కాదు. ఈ మాసంలో 21 నుండి 26 వరకూ అనుకూలమైన రోజులు కావు.",
          Path: Makara,
          Rajapujyam: "02",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "05",
        },
        MeenaRasi: {
          Aadayama: "08",
          Avamanam: "02",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "2",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో బంధు వర్గం వలన ఒక నష్టం. కుటుంబంలో ప్రతికూలత. వ్యాపార వ్యవహారాలు సామాన్యం. సకాలంలో పనులు పూర్తి అవ్వవు. విమర్శకుల వలన మానసిక అశాంతి. ఆర్ధికంగా ఇబ్బందులకు అవకాశం. నిర్ణయాల పట్ల స్థిరత్వం అవసరం. 1,2,9,13,14,25 వ తేదీలు అనుకూలమైనవి కావు. మొత్తం మీద ఆర్ధిక పరంగా ఈ దశ మిశ్రమ ఫలితాలు ఇచ్చును.",
          Path: Meena,
          Rajapujyam: "01",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "11",
        },
        MeshaRasi: {
          Aadayama: "05",
          Avamanam: "01",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "1",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో మీ ప్రమేయం లేకుండా అవమానములు, అపవాదులు ఏర్పడు సూచన. కాని చివరకు విజయం మీదే. ధనాదాయం సామాన్యం. రాజకీయంగా పదవులు, హోదా పొందుటకు ఇది అనువైన కాలం కాదు. దూర ప్రాంతంలో స్థిరత్వం కొరకు చేయు ప్రయత్నాలు కష్టం మీద ఫలించును. మాసాంతంలో ప్రతిభకు తగిన ప్రోత్సాహం ఉండును. ఈ మాసంలో సాహసోపేతమైన నిర్ణయాలు తీసుకొనుట మంచిది కాదు. ముఖ్యంగా ఈ మాసంలో 8, 10, 14, 17 తేదీలు మంచివి కావు.",
          Path: RashiIcons,
          Rajapujyam: "03",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "05",
        },
        MithunaRasi: {
          Aadayama: "02",
          Avamanam: "04",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "4",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో వైవాహిక జీవనంలో అననుకూల ఫలితాలు. ద్వితీయ తృతీయ వారాలలో కొద్దిపాటి ఆరోగ్య సమస్యలు. భవిష్యత్ ప్రణాళికలు రచించుటకు లేదా పెద్ద నిర్ణయాలు తీసుకొనుటకు 21 వ తేదీ తదుపరి కొంత అనుకూలమైన కాలం. ఈ మాసంలో 9,10,11 తేదీలలో ప్రమాదం లేదా వాహనముల వలన సమస్యలు.",
          Path: Midhuna,
          Rajapujyam: "02",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "11",
        },
        SimhaRasi: {
          Aadayama: "14",
          Avamanam: "07",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసం పితృ వర్గీయులకు మంచిది కాదు. ఆకస్మిక సమస్యలు లేదా కష్ట నష్టములు కలుగు సూచన. ఆదాయం తగ్గును. స్నేహితులతో విరోధాలు లేదా వారి వలన వ్యక్తిగత నష్టాలు. ఆరోగ్య సమస్యలు కలిగిన వారికి ఈ మాసంలో వ్యాధి తీవ్రత అధికం. వ్యక్తిగత జీవనం బాగుండదు. ఈ మాసంలో 1,5,6,12,19,29 తేదీలు అనుకూలమైనవి కావు.",
          Path:Simha,
          Rajapujyam: "01",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "02",
        },
        TulaRasi: {
          Aadayama: "14",
          Avamanam: "07",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "0",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ప్రతీ పని అనుకూలంగా జరుగును. నూతన వాహన కోరిక నెరవేరును. ధనాదాయం బాగుండును. చెవికి సంబంధించిన చిన్న సమస్య. నూతన వ్యక్తులతో పరిచయాలు. తన్మూలక లాభములు. విద్యార్ధులకు విజయములు. మైత్రీ వ్యవహారములకు ఈ మాసం అనుకూలమైనది. ఈ మాసంలో 13,20,26 తేదీలు అనుకూలమైన రోజులు కావు.",
          Path: Tula,
          Rajapujyam: "07",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "11",
        },
        karkatakaRasi: {
          Aadayama: "11",
          Avamanam: "04",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "2",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో వ్యాపార వృత్తి జీవనదారులకు పనులు విజయవంతం అగును. ధనాదాయం బాగుండును. ప్రయత్నాలు లాభించును. ఈ మాసంలో కూడా వ్యక్తిగత జీవనంలో మాత్రం సమస్యలు కొనసాగును. ఉద్యోగంలో ఉత్సహపురిత వాతావరణం ఉంటుంది. నూతన గృహ లేదా గృహమార్పిడి ప్రయత్నాలు ఫలించును. ఆపదలు నుండి తప్పించుకొందురు. చివరి వారం ప్రారంభం నుండి భోగ భాగ్యములు అనుభవించెదరు. వంశ గౌరవం పెరుగును.",
          Path: Karkata,
          Rajapujyam: "05",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "08",
        },
        vrischikaRashi: {
          Aadayama: "05",
          Avamanam: "03",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో అవివాహితుల వివాహ ప్రయత్నాలు లాభించును. జీవిత భాగస్వామి సంబంధ విషయాలలో సౌఖ్యం. ఆశించిన ధనాదాయం. ఉద్యోగులకు ప్రతివిమర్శల వలన నష్టం. భూ సంబంధ లేదా గృహసంబంధ యోగం. విద్యార్ధులకు కృషి అవసరం. ఈ మాసంలో 18 వ తేదీ నుండి 22 వ తేదీ వరకూ అనువైన కాలం.",
          Path: Vrushika,
          Rajapujyam: "03",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "05",
        },
        vrishabhaRashi: {
          Aadayama: "14",
          Avamanam: "01",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "0",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో కొంత అనుకూల వాతావరణం ఉంటుంది. పరిస్థితులు సజావుగా కొనసాగును. మానసిక అశాంతి తొలగును. కుటుంబంలో నూతన వ్యక్తుల చేరికలు ఏర్పడతాయి. అవివాహితుల వివాహ ప్రయత్నాలు లాభించును. వివాదాలలో జయం లభిస్తుంది. ద్వితీయ వారంలో అన్ని కార్యములలో కార్య సిద్ధి లభిస్తుంది. జీవనంలో స్థిరత్వం ప్రాప్తిస్తుంది. 21,22,23 తేదీలలో కాలం దుర్వినియోగం అవుతుంది. కుటుంబం తో కాలం గడపడానికి ప్రయత్నించండి. కుటుంబ సభ్యులతో మీ అభిప్రాయాలను పంచుకోండి. కుటుంబ సహకారం లభిస్తుంది.",
          Path: Vrushabha,
          Rajapujyam: "06",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "11",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "08",
          Avamanam: "03",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "2",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ఆర్ధిక భారం నుండి కొద్దిపాటి ఉపశమనం. ముఖ్యమైన పనులు సజావుగా పూర్తి అగును. విద్యార్ధులకు మంచి కాలం. వాహనాల విషయమై ఖర్చులు. ఉద్యోగులకు ఆశాభంగం. తృతీయ, చతుర్ధ వారాలలో వృధా వ్యయం. శ్రమ అధికం అగును. సన్నిహితులకు లేదా జీవిత భాగస్వామికి దూరంగా ఉండవలసి వచ్చుట. చెడు కలలు. ఈ మాసంలో 4,7,13,18,29 తేదీలు మంచివి కావు. జాగ్రత్త అవసరం.",
          Path: Dhanushu,
          Rajapujyam: "06",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "11",
        },
        KanyaRasi: {
          Aadayama: "02",
          Avamanam: "07",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "4",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో ఆదాయ వ్యయాలు సంతృప్తికరం. ఆరోగ్య సమస్యలు తగ్గును. ఈ మాసంలో పట్టుదల వహించకూడదు. వివాహ ప్రయత్నాలలో ఆశాభంగములు. ద్వితీయ వారంలో సన్నిహిత వర్గాలతో సమస్యలు. మనసుకు కష్టం. తృతీయ చతుర్ధ వారాలలో నూతన పనులు, కార్యోన్ముఖత. సువర్ణ సంబంధ లాభాలు. ఈ మాసంలో 9, 10,11 తేదీలు అనుకూలమైనవి కావు.",
          Path:Kanya,
          Rajapujyam: "04",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "11",
        },
        KumbhaRasi: {
          Aadayama: "11",
          Avamanam: "06",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసం ప్రధమార్ధం ఆహ్లాదకరంగా ఉండును. వ్యక్తిగత జీవనంలో సౌఖ్యం. ద్వితియార్ధంలో ఉద్యోగస్తులకు చేతినిండా పని. అవిశ్రాంతంగా పని చేయవలసిన పరిస్టితులు. పనిపై శ్రద్ధాసక్తులు లోపించును. శ్రమకు గుర్తింపు ఉండదు. ధనాదాయం సామాన్యం. ఆదాయ మార్పిడి ప్రయత్నాలు విఫలం అగును. సహోద్యోగుల వలన సమస్యలు. ఈ మాసంలో 18,19,20 తేదీలు అనుకూలమైనవి కావు.",
          Path: Kumbha,
          Rajapujyam: "05",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "05",
        },
        MakaraRasi: {
          Aadayama: "11",
          Avamanam: "06",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "7",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో కూడా ఫలితాలు మిశ్రమంగా ఉండును. విద్యార్ధులకు కలసివచ్చును. ఉన్నత విద్యావంతులు అగును. ప్రేమవ్యవహరములలో లేదా వ్యక్తిగత జీవితంలో స్పష్టత లభించును. హామీలు నిలబెట్టుకోలేరు. ఆర్ధిక విషయాలలో అత్యాశ వలన చక్కటి అవకాశములు చేజారుటకు సూచన. ధనాదాయం సామాన్యం.",
          Path: Makara,
          Rajapujyam: "02",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "05",
        },
        MeenaRasi: {
          Aadayama: "08",
          Avamanam: "02",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "2",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో తిరిగి సానుకూల పరిస్థితులు ఏర్పడును. పై అధికారుల వలన లాభాములుండును. పదోన్నతికై లేదా ఉద్యోగ మార్పుకు, గృహాలపై పెట్టుబడులు పెట్టుటకు ఈ మాసం కలసి వచ్చును. పెట్టుబడుల వలన కలసివచ్చును. సంతాన ప్రయత్నాలు ఫలించును. రావలసిన ధనం చేతికి వచ్చును.",
          Path: Meena,
          Rajapujyam: "01",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "11",
        },
        MeshaRasi: {
          Aadayama: "05",
          Avamanam: "01",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "1",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో జీవిత భాగస్వామి మూలాన ధనప్రాప్తి లభిస్తుంది. ఊహించని విధంగా ఆకస్మిక ధన లాభములు పొందుతారు. వృత్తి వ్యాపారముల వారికి చక్కటి ధన సంపాదన లభిస్తుంది. గృహ వాతావరణంలో కొద్దిపాటి చికాకులు ఉన్నప్పటికీ ప్రసాంతత లోపించదు. 10వ తేదీ తదుపరి నిరుద్యోగుల ఉద్యోగ ప్రయత్నాలు ఫలించును. మాసాంతంలో మిగులు ధనదయం. కార్య సఫలత. ప్రభుత్వ వ్యవహారములలో, కాంట్రాక్టు పనులందు చక్కటి అనుకూలత. ఈ మాసంలో 3, 6, 16, 23, 25, 26 తేదీలు నూతన కార్య ప్రారంభములకు అనుకూలమైనవి.",
          Path: RashiIcons,
          Rajapujyam: "03",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "05",
        },
        MithunaRasi: {
          Aadayama: "02",
          Avamanam: "04",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "4",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం ఆశించిన శుభ ఫలితాలు కలుగచేయును. వ్యాపార, ఉద్యోగ వ్యవహారాలలో విజయం. పై అధికారుల వలన మన్ననలు. నూతన కార్యములను ప్రారంభించవచ్చు. అవకాశములు సద్వినియోగం చేసుకుందురు. కుటుంబ వాతావరణం అనుకూలంగా ఉండును. శ్రమకు తగిన గుర్తింపు. జీవన విధానాలలో పురోగతి. ఖర్చులు తగ్గును. కాలం కలసివచ్చును.",
          Path: Midhuna,
          Rajapujyam: "02",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "11",
        },
        SimhaRasi: {
          Aadayama: "14",
          Avamanam: "07",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో స్తంభించిన కార్యములు పునః ప్రారంభం అగును. పట్టుదలతో వ్యవహారాలలో విజయం. ధనాదాయం బాగుండును. కుటుంబ శ్రేయస్సుకై శ్రమించెదరు. అవకాశములను సద్వినియోగం చేసుకొందురు. సంపద వ్రుద్ధి చెందును. మాసాంతంలో కీర్తి ప్రతిష్టలు పెరుగును. కాలం అనుకూలంగా ఉండును. అన్ని విధములైన విఘ్నాలు తొలగును.",
          Path:Simha,
          Rajapujyam: "01",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "02",
        },
        TulaRasi: {
          Aadayama: "14",
          Avamanam: "07",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "0",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసం కూడా ప్రోత్సాహపురిత వాతావరణమును ఏర్పరచును. కుటుంబ, మిత్ర వర్గ సహకారం పూర్తిగా ఉండును. ధనలాభాలు ఏర్పడును. భూ లేదా పాత గృహ సంబంధ క్రయ విక్రయాలకు ఈ మాసం అనువైనది. కుటుంబంలో మాట గౌరవం పెరుగుతుంది. నూతన వస్తువుల ఆగమనం. సంతోషం. ఈ మాసంలో 17,18,19 తేదీలు వివాహ ప్రయత్నములకు, సంతాన ప్రయత్నములకు, ప్రేమ వ్యవహారములకు అనువైనవి.",
          Path: Tula,
          Rajapujyam: "07",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "11",
        },
        karkatakaRasi: {
          Aadayama: "11",
          Avamanam: "04",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "2",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసం అనుకూలమైన గ్రహ బాలలను కలిగి ఉన్నది. పుష్యమి నక్షత్రం వారికి ఈ మాసం సంతాన విషయాలలో లాభములను, ఆర్ధిక స్థిరత్యాన్ని ఏర్పర్చును. నిరుద్యోగులకు శుభవార్త. ప్రభుత్వ ఉద్యోగం ఆశించిన విజయాలు ఈ మాసం ఏర్పరచును. సంతృప్తికరమైన జీవితం ఏర్పడును. శ్రమ భారం తగ్గును. కుటుంబంలో సంతోషాలు, ఆనందకర సంఘటనలు. కార్య సిద్హి.",
          Path: Karkata,
          Rajapujyam: "05",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "08",
        },
        vrischikaRashi: {
          Aadayama: "05",
          Avamanam: "03",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "1",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో మిత్రుల వలన నూతన ఆదాయ మార్గాలు ఏర్పడును. నిల్వ ధనం కలిగి వుందురు. భాత్రు వర్గం వారికి మంచిది కాదు. ముఖ్యమైన పనులు సకాలంలో పూర్తి అగును. కుటుంబ జీవితంలో సామాన్య ఫలితాలు. బాధ్యతలు పెరుగును. వ్యాపారస్థులకు ఈ మాసం చక్కటి లాభాలను ఏర్పరచును. మానసిక ఉత్సాహం అవసరం అగును. 28వ తేదీ తదుపరి దూర ప్రయాణాలు వాయిదా వేయుట మంచిది",
          Path: Vrushika,
          Rajapujyam: "03",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "05",
        },
        vrishabhaRashi: {
          Aadayama: "14",
          Avamanam: "01",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "0",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో ప్రత్యర్ధుల వలన చికాకులు. గౌరవానికి సంబందించిన సమస్యలు భాదిస్తాయి. నేత్ర సంబంధ అనారోగ్యం కలిగిన వారికి సమస్య తీవ్రం అవుతుంది. శస్త్ర చికిత్సకు దారి తీయును. ప్రయాణాలు లాభిస్తాయి. వ్యాపార ఉద్యోగ వ్యవహారాలు సామాన్యం. ధనాదాయం సామాన్యం. నూతన వ్యవహారాలు మిశ్రమ ఫలితాలు ఇచ్చును. అశ్రద్ధ వలన నష్టములు లేదా శ్రమ. ఈ మాసంలో ఉద్యోగ మార్పిడికి ప్రయత్నములు చేయకుండా వుండడం మంచిది.",
          Path: Vrushabha,
          Rajapujyam: "06",
          RasiDates: "ది.22-మార్చి-2023 నుండి ది.08-ఏప్రిల్-2024 వరకు",
          Samvatsaram: "శ్రీ శోభకృత్ నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "11",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో చక్కటి సుఖసంతోషాలు ఉండును. భూ లాభం లేదా గృహ లాభం ఏర్పడును. వ్యాపార వ్రుత్తి జీవనంలోని వారికి ఆశించిన ధన లాభములు ప్రాప్తించును. నూతన భాద్యతల వలన విశ్రాంతి వుండదు. ప్రధమ ద్వితియ వారములలో నూతన స్నేహ పరిచయాలు ఏర్పడతాయి. సంఘంలో చక్కటి గుర్తింపు లభిస్తుంది. ధనాదాయం బాగుండును. 18 వ తేదీ తదుపరి ఉద్యోగ పరమైన లాభములు పొందుదురు. నూతన ఉద్యోగ ప్రయత్నములలో జయము సిద్ధిస్తుంది. ఈ మాసం మొత్తం ఉద్యోగ ఉన్నతి కొరకు ప్రయత్నాలు చేయవచ్చు. అందరి మన్ననలు పొందుదురు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో మనసుకి కష్టం కలిగించే సంఘటనలు ప్రధమ వారంలో చోటుచేసుకోనును. అకాల భోజనం. నిద్రలేమి, శిరోబాధ ఎదురగును. ద్వితియ వారంలో జీవిత భాగస్వామితో విరోధాలు ఏర్పడును. శత్రుభయం, మానసిక ఆందోళనలు తరచుగా ఎదురగును. పితృ వర్గం వారి అనారోగ్య సమస్యలు తగ్గును. 25 వ తేదీ తదుపరి స్థాన చలన ప్రయత్నాలు ఫలిస్తాయి. అనవసర ప్రయాణాలు చేయవలసి వస్తుంది. ఈ మాసంలో 17, 23, 30 వ తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya, Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో మానసిక ఆందోళన అధికం అవుతుంది. అనారోగ్య సమస్యలు, ప్రభుత్వ అధికారుల వలన ఇబ్బందులు ఎదుర్కొంటారు. సహచరుల వలన ఉద్యోగ జీవనంలో సమస్యలు ఏర్పడతాయి. జూదం వలన గౌరవ హాని మరియు తన్మూలక ధన నష్టం పొందుతారు. వినరాని మాటలు వినుట మానసికంగా ఇబ్బంది పెడుతుంది. వృత్తి జీవనంలోని వారికి, వ్యాపార రంగంలోని వారికి ఈ మాసం కలసి రాదు. వేగంగా పూర్తి కావలసిన పనులలో జాప్యం ఎదురగును. రుణ భారం పెరుగుతుంది. ఈ మాసంలో గ్రహ శాంతులు అవసరం.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో గృహంలోనూ, వృత్తి వ్యాపార ఉద్యోగ జీవనంలో అనుకూలమైన ఫలితాలు ఏర్పడతాయి. చక్కటి లాభకరమైన వాతావరణం అనుభవిస్తారు. అన్ని రంగముల వారికి ఈ మాసంలో సంతృప్తికరమైన ఫలితాలు ఏర్పడును. నూతన వాహన కొనుగోలు సంబంధ ప్రయత్నాలు లాభించును. గత కాలంగా వాయిదా వేస్తూ వస్తున్నా పనుల వలన ఈ మాసంలో కూడా ఇబ్బందులు ఎదుర్కొందురు. ఈ మాసంలో 16 నుండి 21 వ తేదీ మధ్య కలుషిత ఆహార సంబంధ అనారోగ్యం బాధించును. మాసాంతంలో అందరి మన్ననలు పొందగలుగుతారు. సంతోషకర రోజులు ఏర్పడతాయి.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసం గృహ నిర్మాణ సంబంధ పనులు ప్రారంభం అగును. నూతన ఋణాలు లభించును. ధనం సర్దుబాటు జరిగి ఆశించిన విధంగా బాధ్యతలు పుర్తిచేయగలరు. ఈ మాసంలో ధనాదాయం సామాన్యం. సంతాన ప్రయత్నాలు అనుకూలంగా ఉండవు. విదేశీ ప్రయత్నాలు లాభించును. సమాజంలో గౌరవం లభించును. వ్యాపార రంగంలోని వారికి అనుకోని మార్పులు సంభవించు సూచన. అధికారుల వలన తృతీయ వారంలో చికాకులు. స్నేహ వర్గం సహకారంతో ఇబ్బందులనుండి తప్పించుకొందురు. చివరి వారంలో కుటుంబ సభ్యుల మధ్య అవగాహన లోపించు సంఘటనలకు అవకాశం కలదు.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో వృత్తి వ్యాపార ఉద్యోగాల్లో సామాన్య ఫలితాలు ఎదురవుతాయి. మిత్రుల వలన లాభం పొందుతారు. ప్రధమ ద్వితియ వారాలలో కుటుంబ సభ్యుల తిరుగుబాటు, రక్త సంబందీకులతో ఘర్షణలు మానసికంగా ఇబ్బంది పెడతాయి. మాతృ వర్గీయుల ఆరోగ్య సమస్యల వలన కూడా ఆందోళన చెందుతారు. చివరి వారంలో మిత్రులతో ప్రయాణాల వలన కొంత సంతోషకర సమయం ఏర్పడుతుంది. ఈ మాసం మొత్తం స్పెక్యులేషన్ వలన నష్టం పొందు సూచనలు అధికం. ముఖ్యంగా తృతీయ వారం లో వ్యాపార పరంగా అధిక ఒత్తిడి ఎదుర్కొంటారు.. ఈ మాసంలో 7, 15, 24, 30 తేదీలు అంతగా అనుకూలమైనవి కావు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం ప్రధమ వారంలో వృత్తి వ్యాపారములు చక్కగా జరుగును. ధనాదాయం ఆశించిన విధంగా ఉంటుంది. నిరుద్యోగులకు చక్కటి ఉద్యోగ ప్రాప్తి లభిస్తుంది. ఈ మాసం లో 5,6 తేదీలు ఉద్యోగ ప్రయత్నాలకు అనుకూలమైనవి. ద్వితియ వారంలో దీర్గకాళిక ఋణములు తీర్చివేయగలుగుతారు. నూతన పరిచయాలు ఏర్పడి లాభపురితంగా పురోగామిస్తాయి. తృతీయ వారం నుండి సామాన్య ఫలితాలు లభిస్తాయి. మాతృ బంధుపరంగా కొద్దిపాటి మాట పట్టింపులు ఎదుర్కొంటారు. 25 వ తేదీ తదుపరి స్త్రీ సంతాన సంబంధ శుభకార్య వ్యయం చేస్తారు. ఇంటిలో ఉత్సాయపూరిత వాతావరణం ఉంటుంది.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో జాతకులు మానసికంగా ఉద్రేకపూరితంగా ఉందురు. అకారణమైన కోపం, చిరాకు ప్రదర్శిస్తారు. ధనాదాయం సామాన్యం. అనారోగ్య సమస్యలు కొనసాగును. కుటుంబ పరమైన వ్యయం కొంత తగ్గును. బంధు మిత్రుల ఆదరణ, చేయూత లభించును. తృతీయ వారంలో ఉద్యోగ జీవనంలో అనుకూలమైన మార్పులు ఏర్పడును. పై అధికారుల సహాయ సహకారాలు లభిస్తాయి. మాసాంతంలో వినోద సంబంధ వ్యయం ఏర్పడుతుంది. ఈ నెలలో 3,8,11,13,17 తేదీలు మంచివి కావు.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ధనాదాయం బాగుండును. వృత్తి వ్యాపారములు ఉత్సాహ పూరితంగా నడుస్తాయి. లాభపూరిత వాతావరణం ఏర్పడుతుంది. జీవిత భాగస్వామి మూలకంగా ఆర్ధిక లాభములు పొందుతారు. ప్రధమ వారంలో గృహంలో మార్పులు చేపడతారు, పుణ్యక్షేత్ర సందర్శన భాగ్యం లభిస్తుంది. ద్వితియ మరియూ తృతీయ వారంలో ఆకస్మిక వివాదములు చుట్టుముడతాయి. సమయం వృధా అవుతుంది. అనవసర ఖర్చులు ఎదురవుతాయి. వృత్తి వ్యాపారాలలో ఆదాయం సామాన్యంగా ఉంటుంది. తీసుకొన్న నిర్ణయాలలో చివరి నిమిషంలో మార్పులు చేపడతారు. ఉద్యోగ జీవనం సామాన్యంగా కొనసాగుతుంది. ఈ మాసంలో 28,29,30 తేదీలు అనుకూలమైనవి కావు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసం మొదటి వారంలో శరీర అనారోగ్యం బాదిస్తుంది. ప్రతీ పనియందు శ్రమానంతర కార్య జయం ఎదురగును. ఉద్యోగ జీవనం సామాన్యం. విద్యార్ధులకు మంచి అవకాశములు లభిస్తాయి. ద్వితీయ వారములో నూతన వధూవరులకు సంతాన ప్రాప్తి యోగం ఉన్నది. ఈ నెల 16, 17 తేదీలలో శత్రుత్వములు. తగవుల వలన అశాంతి ఎదుర్కొంటారు. చివరి వారంలో అనుకూల ఫలితాలు లభిస్తాయి. 22 వ తేదీ తదుపరి ధనాదాయంలో పెరుగుదల ఉంటుంది. మిత్ర వర్గంలో మంచి పేరు గడించగలుగుతారు. అవసరములకు ధనం సర్దుబాటు అవుతుంది.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో చక్కటి ధనాదాయం లభించు సూచన. తలపెట్టిన కార్యములందు జయము ప్రాప్తించును. చిన్న అనారోగ్య సమస్య వలన ఇబ్బంది పడి వెంటనే కోలుకుంటారు. ఉన్నత స్థాయి వ్యక్తుల పరిచయం లభిస్తుంది. చేస్తున్న వృత్తిలో గుర్తింపు, గౌరవ సత్కారములు పొందగలుగుతారు. వంశ పెద్దల ఆశీస్శులు లభిస్తాయి.మిత్రులతో ప్రియ సంభాషణలు గావిస్తారు. మాసాంతంలో కొద్దిపాటి విఘ్నములు ఎదుర్కొంటారు. ఈ మాసంలో 22, 24,25,27 తేదీలలో జాగ్రత్త అవసరం. అశుభ వార్తలు వినుటకు అవకాశం కలదు.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. వారసత్వ పరంగా జ్ఞాతుల వైరము కొనసాగును. చేయుచున్న వృత్తి వ్యాపారాలలో, ఉద్యోగ ప్రయత్నాలలో విజయము ప్రాప్తించును. ద్వితీయ వారంలో ఒక ఆకస్మిక చెడు సంఘటన ఏర్పడు సూచన. సోదర వర్గం వారికి మీరు సహాయ సహకారాలు అందించేదురు. మీ సహాయం వలన మీ భాత్రు వర్గం ఇబ్బందుల నుండి బయట పడతారు. తృతీయ వారంలో కుటుంబంలో గృహ సంతోషాలు. శుభకార్యములు జరుగును. సంతోష సమయం లభించును. ఈ మాసంలో 4, 13, 22 తేదీలు మంచివి కావు. ముఖ్యంగా 13 వ తేదీ జాగ్రత్తగా వ్యవహరించవలెను.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసం కుటుంబ సభులకు జ్వరతత్వం సంబంధ ఆరోగ్య సమస్యలను ఏర్పరచును. కష్టంతో పనులు పూర్తి అగును. నిల్వ ధనము కొంత వ్యయం అగును. మాస మధ్యమం నుండి లాభకరమైన, సంతోషకరమైన పరిస్థితి లభించును. వ్యాపార వర్గం వారికి ప్రభుత్వ సంబంధ ఆటంకములు ఎదురగును. ప్రతీ వ్యవహారం స్వయంగా పర్యవేక్షించవలసిన పరిస్థితులు ఏర్పడును. శ్రమ అధికమగును. 22 వ తేదీ తదుపరి నూతన ప్రయత్నాలు ఫలించవు. ఈ మాసంలో 1,7,9,11,25 తేదీలు అనుకూలమైన రోజులు కాదు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసం కూడా మిశ్రమ ఫలితాలను కలిగించును. మనసు నిలకడగా ఉండదు. చెడు ఆలోచనలు అధికంగా వస్తూ ఉండుట వలన మానసిక ఆందోళన అనుభవిస్తారు. ఆకస్మిక ప్రమాదాల నుండి తప్పించుకొంటారు. ఈ మాసంలో ధనాదాయం సామాన్యంగా ఉంటుంది. ప్రయత్నాలలో విఘ్నములు ఎదురైనా చివరికి పూర్తి అవుతాయి. తృతీయ వారం నుండి పరిస్థితులలో అనుకూలత ప్రారంభం అవుతుంది. సోదర వర్గం వారితో సమస్యలు తొలగును. వారి సహాయం లభిస్తుంది. ఈ మాసంలో 9 నుండి 13 వ తేదీల మధ్య వాహనాల విషయంలో, ప్రయాణాల విషయంలో జాగ్రత్త అవసరం.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ఆదాయం సామాన్యం. కుటుంబంలో గౌరవ ప్రతిష్టలు దెబ్బతింటాయి. ద్వితియ వారంలో ముఖ్యమైన పనులలో అవాంతరములు ఎదురగును. ఆస్తి వివాదములు పరిష్కారం అవ్వడంలో జాప్యం బాధిస్తుంది. వ్యవహార జయం కొరకు మిక్కిలి శ్రమించవలెను. కొత్త బాధ్యతలు ఆందోళన కలిగించవచ్చును. ఈ మాసంలో 1, 10,14,19,24,29 తేదీలు అనుకూలమైనవి కావు.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో నిల్వ ధనమును ఏర్పరచుకోనగాలుగుతారు. బంధు వర్గం సహకారం వలన అభివృద్ధి చెందుతారు. వృత్తి జీవనంలోని వారికి గౌరవం, ఆశించిన చక్కటి అభివృధ్హి లభిస్తుంది. మాస మధ్యమంలో గృహంలో శుభ కార్యములు నిర్వహించెదరు. తృతీయ వారములో విదేశీ జీవనం చేయువార్కి స్థిరాస్తి నష్టములు ఎదురగును. ఎదిగిన పుత్ర సంతానంతో విరోధములు ఏర్పడుతాయి. స్త్రీలను అపవాదులు బాదిస్తాయి. మనశ్శాంతి కోల్పోవుదురు. మాసాంతమునకు ప్రతీ వ్యవహారం శ్రమకరంగా ఉండును. ముఖ్యంగా 26, 27, 28 తేదీలు అనుకూలమైన రోజులు కావు.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో ప్రధమ వారంలో ధనాదాయం బాగున్నప్పటికీ అకారణంగా మాటపడుట, నింద ఏర్పడుట వంటి సమస్యలు కలవు. ఇతరులకు సంబంధించిన సున్నిత విషయాలలో దూరంగా ఉండుట మంచిది. పెద్ద వయస్సు వారికి ఉదర సంబంధమైన ఆరోగ్య సమస్య ఏర్పడును. వ్యాపార వ్యవహరాదులు మందకొడిగా కొనసాగును. ద్వితీయ వారంలో చేపట్టే కార్యములు విజయవంతం అగును. ఉద్యోగ రంగంలో ఆటంకములు ఉన్ననూ లక్ష్యాలు పూర్తిచెయగలరు. స్థానచలన ప్రయత్నాలు ఫలించును. మేధావి వర్గంలో స్థానం లభించును. తృతీయ, చతుర్ధ వారాలు ప్రోత్సాహకరంగా ఉండును. విద్యార్ధులకు ఇది చక్కటి ఫలితాలు కలిగించు మాసం.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో మేషరాశి వారికి అనుకూల ఫలితాలు ఎదురగును. తలపెట్టిన నూతన కార్యములు విజయం పొందును. సంతాన ప్రయత్నములు చేయు వారికి సంతాన పరంగా సంకల్ప సిద్ధి ఏర్పడును. ఈ మాసంలో 4,6,7 తేదీలలో విదేశీ సంబంధ ప్రయాణములు ఫలించును. 19, 20 తేదీలలో కుటుంబ పరమైన సౌఖ్యం అనుభవించేదురు. ప్రభుత్వ సంబంధ కాంట్రాక్టులకు, క్రీడా సంబంధ విజయములకు, నిరుద్యోగుల ఉద్యోగ ప్రయత్నములకు, ఉద్యోగ ఉన్నతి కొరకు చేయు ప్రయత్నములకు ఈ మాసం అనుకూల కాలం. ఈ మాసంలో మొత్తం మీద ధనాదాయంలో వృద్ది కనిపిస్తుంది. ఈ మాసంలో 13, 15, 26, 27 తేదీలు అనుకూలమైనవి కాదు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో అనుకూల ఫలితాలు లభించవు. సొంత బంధు వర్గం తో విరోధములు చికాకు పరచును. అకారణంగా తగాదాలు ఏర్పడును. ప్రేమ వ్యవహారములు నష్టపెట్టును. తలచిన పనులు జరగవు. అనేక అవరోధములు ఎదుర్కొనును. ఈ మాసంలో 14,15,17 తేదీలలో శిరోబాధ సంబంధ అనారోగ్యత. మాసాంతంలో అధిక ధనవ్యయం ఎదురగుటకు అవకాశం ఉన్నది. మొత్తం మీద ఈ మాసం అంత అనుకూలం కాదు.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో చక్కటి ఆదాయమునకు సూచనలు కలవు. శరీర అరోగ్యం సహకరించును. మనసు ఉల్లాసంగా ఉండును. గత కాలపు పెట్టుబడుల నుండి రాబడి ప్రారంభమగును. ద్వితీయ వారంలో పశు సంబంధ హాని ఎదుర్కొనుటకు సూచన ఉన్నది. 14, 15, 16, 17 తేదీలలో కార్యానుకులత ఉన్నది. ప్రభుత్వ రంగ ఉద్యోగం చేయు వారికి మంచి కాలం. దూర దేశ స్థిర నివాస ప్రయత్నములు లాభించును. ఆలోచనలు సక్రమంగా ఉండును. చిన్న పిల్లల విషయంలో జాగ్రత్త అవసరం.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. గృహంలో సంతోషములు మధ్యమ స్థాయిలో ఉంటాయి.బంధువులు మిత్రుల వలన సహకారము లభించి ఆశించిన కార్యములందు విజయం పొందుతారు. మాస మధ్యమంలో శుభకార్య సంబంధ వ్యయం ఎదుర్కొంటారు. 20వ తేదీ తదుపరి జీవిత భాగస్వామి సహాయ సహకారములు సంపూర్ణంగా పొందుతారు. సంతాన ప్రయత్నాలలో సఫలత పొందుతారు. నూతన ఉద్యోగ ప్రయత్నాలలో విజయం చివరి నిమిషములో వరిస్తుంది. ఈ మాసంలో 3,8,9,16 తేదీలు నూతన ప్రయత్నాలకు అనుకూలమైనవి. 2,11,19, 21, 29 తేదీలు అనుకూలమైనవి కావు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసం ప్రారంభ వారంలో అనగా 5,6,7 తేదీలలో కళత్ర సంబంధమైన చికాకులు ఎదురగును. నూతన వ్యక్తులతో పరిచయాలు సమస్యలను కలుగచేస్తాయి. ద్వితీయ వారం అంతా సామాన్యంగా ఉంటుంది. తీవ్ర ప్రతికూలత ఉండదు. తృతీయ వారం నుండి వృత్తి వ్యాపారములలో ధనాదాయం సామాన్యం. కుటుంబ సభ్యుల కొరకు శుభ సంకల్పములు గావిస్తారు. ఉద్యోగ జీవనంలో పోటీతత్వం ప్రదర్శిస్తారు. చివరి వారంలో అనగా 25, 27 తేదీలలో కార్యజయం, వ్యవహార అనుకూలత ఉన్నవి.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో ఉద్యోగ జీవనంలో లాభ పూరిత వాతావరణం ఎదుర్కొంటారు. నూతన పదవులు ప్రాప్తించును. గౌరవ ప్రదమైన జీవనం ఏర్పాటు చేసుకోగలుగుతారు. ద్వితియ వారంలో తలపెట్టు ప్రయాణములు ఫలించును. పరీక్షలు లేదా ఇంటర్వ్యూలు ఎదుర్కోను వారికి ఈ మాసంలో విజయం వరిస్తుంది. ఆశించిన ఉద్యోగం ప్రాప్తిస్తుంది. తృతీయ వారం సామాన్య ఫలితాలు ఎదురగును. మాసాంతంలో విశేష సౌఖ్యం, సుఖ ప్రాప్తి. వస్త్ర లేదా నూతన వస్తువుల అమరిక మొదలగు శుభ ఫలితాలు ఏర్పడతాయి. ఈ మాసంలో 1, 4, 6,10,15,18,22,27,29 తేదీలు అన్నివిధాల అనుకూలమైనవి.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో మీ పై అధికారుల వలన, కుటుంబ పెద్దల వలన మానసికంగా కొద్దిపాటి ఆందోళన ఎదుర్కోను సూచన. తోటి ఉద్యోగులతో లేదా యజమానితో అభిప్రాయ భేదాలు ఎదుర్కొందురు. ఈ మాసంలో వ్యాపార రంగంలోని వారికి ధనాదాయం సామాన్యం గా ఉంటుంది. మృగశిర నక్షత్ర జాతకులు 20 వ తేదీ తదుపరి చేయవలసిన ప్రయాణాలు వాయిదా వేయుట మంచిది. మాసాంతంలో కుటుంబ సభ్యులకు ఆరోగ్య సమస్యలు ఎదురగుట వలన ధన వ్యయం అదుపు తప్పును.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో చిన్న శస్త్ర చికిత్స జరుగుటకు అవకాశం ఉన్నది. గృహంలో చొరభయం ఏర్పడుతుంది. ద్వితియ వారం నుండి కార్యభారం పెరుగును. దూరప్రాంత ప్రయాణములకు సంబందించిన ఆలోచనలు కార్య రూపం దాల్చవు. మనోవేదన కలిగించు సంఘటనలు జరుగును. తీవ్ర ప్రయాస అనంతరం ప్రారంభించిన కార్యములు పూర్తి అగును. వ్యాపారాదులు సామాన్యంగా కొనసాగును.ఆదాయంలో పెరుగుదల కొరకు నూతన మార్గములు అన్వేషిస్తారు. ఉద్యోగ జీవనం సామాన్యంగా కొనసాగును. మాసాంతంలో అధికారుల, పెద్దల సహాయ సహకారములు లభించును. ఈ మాసంలో ప్రతీ సోమవారం ఈశ్వర అభిషేకాలు జరిపించుట మంచిది.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో చక్కటి అనుకూల ఫలితాలు లభిస్తాయి. మీ ప్రత్యర్ధులపై మీదే పై చెయ్ అవుతుంది. సకల కార్య విజయం మరియు ఆదాయంలో పెరుగుదల ఉంటుంది. మీరు తలపెట్టిన పనుల వలన మిక్కిలి గుర్తింపు లభిస్తుంది. గృహ నిర్మాణ పనులు వేగవంతంగా పూర్తీ చేయగలుగుతారు. సకాలంలో ధనం సర్దుబాటు అవుతుంది. ఈ మాసంలో 10 నుండి 14 తేదీల మధ్య వివాహ ప్రయత్నములు, సంతాన సంబంధ విషయాలకు అనుకూల కాలం. నూతన వస్తువులను కొనుగోలు చేస్తారు. జీవిత భాగస్వామితో చక్కటి సౌఖ్యత ఉంటుంది. బాగా ఎదిగిన సంతానం వలన సంతోషం పొందుతారు. ఈ మాసంలో 5,16,22, 30 తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో కొంత అనుకూల పరిస్థితులు ఏర్పడును. నూతన అవకాశములు లభించును. ఆరోగ్య సమస్యలు శాంతిమ్చును. పనిచేయుచున్న స్థలంలో అవమానుపడు సంఘటనలు ఎదురగును. సహచర ఉద్యోగుల నుండి సహకార లేమి ఎదుర్కొంటారు. కొత్త పరిచయాల విషయంలో జాగ్రత్త అవసరం. ఈ మాసంలో ప్రయాణములు వాయిదా వేయుట మంచిది. ఆదాయం సామాన్యం. కొన్ని విపరీత అనుభవాలు ఎదుర్కొనుటకు సూచనలు కలవు.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ఆరోగ్య సమస్యల వలన బాధపడుతున్నవారికి ఉపశమనం లభిస్తుంది. ఆరోగ్య సమస్యలు ఊహించని విధంగా సమసిపోతాయి. నిర్మాణ సంబంధమైన కార్యములకు ఈ మాసం అనుకూలమైన కాలం. ఉద్యోగ జీవనంలో పదోన్నతిని ఆశించవచ్చు. ఉదార స్వభావం వలన పేరు ప్రఖ్యాతలు గడిస్తారు. జీవిత భాగస్వామి సంబంధ విషయాలలో అనుకూలత అనుభవించుదురు. మనోబలం పెరుగును. గురువులు, పెద్దల సహాయ సహకారములు లభించును. ఎంతో కాలంగా ఎదురుచూస్తున్న శుభవార్త వినుదురు. అయితే ఈ మాసంలో గర్భిణి స్త్రీలు జాగ్రత్త వహించవలెను.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం వృద్ధి చెందును. కుటుంబ సమస్యలు తగ్గును. వివాహ ప్రయత్నాలు కష్టం మీద విజయవంతమగును. నూతన వ్యాపార విస్తరణకు ఇది మంచి కాలం. ఆశించిన విధంగా బంధువుల, స్నేహితుల సహాయ సహకారములు లభించును. 9 నుండి 17 వ తేదీ మధ్య తలపెట్టిన పనులన్నీ విజయవతం అగు సూచనలు కలవు. కోర్తు కేసులకు సంబంధించిన వ్యవహారాలలో స్థిరమైన ఆలోచనలతో పరిష్కారం లభించును. సంతానానికి సంబంధించిన అభివృద్ధి వార్తలు ఆనందాన్ని కలుగచేయును. పర్యాటక రంగంలో పనిచేయువారికి ఈ మాసంలో అతి చక్కటి అభివృద్ధి లభించును. ఈ మాసంలో ధనమునకు లోటు రాదు.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో వారసత్వ సంబంధమైన తగాదాల వలన చికాకులు ఏర్పడు సూచన. వ్యాపారములు సామాన్యంగా కొనసాగును. అశ్విని నక్షత్ర యువకులకు స్త్రీ సంబంధమైన విషయాలలో చికాకులు మరియు స్త్రీ సంబంధ ధన వ్యయం ఎదురగును. ఈ మాసంలో వివాహ ప్రయత్నములలో ఆటంకములు ఎదురగును. సోదర వర్గీయులతో మాట తగాదాలు కుటుంబంలో పరువు పోవు సంఘటనలు ఎదురగును. తృతీయ వారంలో జీవిత భాగస్వామికి అనారోగ్యం వలన వైవాహిక జీవన సుఖ రాహిత్యత ఎదురగును. గృహ మార్పిడి ప్రయత్నాలకు ఈ మాసంలో చివరి వారం అనుకూల కాలం.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం అనుకూలమైన కాలం. చక్కటి ఫలితాలు ఏర్పరచును. ఆర్ధికంగా మిక్కిలి మానసిక ప్రశాంతత అనుభవించుదురు. తలపెట్టిన పనులు విజయవంతం అగును. ఆశించిన ధన భాగ్యం చేతికి వచ్చును. ద్వితీయ మరియు తృతీయ వారములు విద్యార్ధులకు విజయం చేకుర్చును. కోరుకున్న పనులన్నీ జరుగును. అన్ని వర్గముల వారికి అదృష్టం కలసివచ్చును. జీవన మార్గంలో నూతన బాధ్యతలు లభించుట వలన గౌరవం, హోదా పెరుగును. వివాహ సంబంధ ప్రయత్నాలకు, సంతాన ప్రయత్నాలకు, వారసత్వ లేదా కోర్టు తగదాలందురాజీ ప్రయత్నాలకు ఈ మాసం అనువైనది.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో ప్రయత్నఆటంకములు ప్రారంభంలో కొద్దిగా ఉండినప్పటికి అంతిమంగా విజయం లభించును. ధనాదాయం నెమ్మదిగా పెరుగును. గతకాలపు ఋణములు క్రమంగా తొలగును. ద్వితియ మరియు తృతీయ వారములలో నూతన ఆదాయ మార్గములు లభించును. అవివాహితులకు 16 నుండి 20 వ తేదీలలో వివాహ ప్రయత్నములు ఫలించును. 23 వ తేదీ కుటుంబ పెద్దలతో ముఖ్యమైన విషయాలకై చర్చలు జరుపుటకు అనువైన కాలం. మానసిక ప్రసాంతత లభిస్తుంది.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో కుటుంబంలో కొద్దిపాటి ఘర్షణలు మరియు కుటుంబ సభ్యుల మధ్య మాట కలయికలో ఇబ్బందులు ఎదురవుతాయి. స్త్రీ సంబంధ వివాదాములలో చిక్కుకుందురు. వృత్తి వ్యాపారములు బాగుగానే ఉండును. దూరప్రాంత ప్రయాణములు ఫలిస్తాయి. కోర్టు వ్యవహారములలో అననుకూలత ఎదురగును. నిరుద్యుగులకు ఈ మాసం అంత అనుకూలమైనది కాదు. జీవితంలో శ్రమతో కూడిన అభివృద్ధి ఉండును. మాయా మొహాముల వలన నష్టపోవు సూచన ఉన్నది. ఈ మాసంలో 10 నుండి 13వ తేదీ మధ్య ప్రయాణములు చేయుట మంచిది కాదు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసం అనుకూలమైన ఫలితాలు ప్రసాదించును. దూర ప్రయాణ సంబంధ లాభములు పొందుతారు. జీవిత భాగస్వామి వలన సౌఖ్యం అనుభవిస్తారు. శుభ వార్తలు వింటారు. కందెన సంబంధ వ్యాపారములు చేయువారికి అధిక లాభములు ప్రాప్తించును. వివాహ ప్రయత్నములలో సంబంధములు నిశ్చయం అగును. తృతీయ వారంలో చిన్న అనారోగ్య సూచన లేదా వాహన ప్రమాదం నుండి తప్పించుకొను సంఘటనలు ఉన్నవి. 24 నుండి మాసాంతం మధ్య కాలంలో గృహ సంబంధమైన అవసరాల కొరకు ధనం వ్యయం చేస్తారు. ఈ మాసంలో చివరి వారం స్టాక్ మార్కెట్ తీవ్ర ఒడిదుడుకులకు లోనవుతుంది. ట్రేడింగ్ వ్యాపారం చేయు వారు మిక్కిలి జాగ్రత్తగా ఉండవలెను.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో మదిలో ఉన్న ఆలోచనలు అన్నీ కార్య రూపం దాల్చును. కుటుంబంలో చిన్నపాటి కలహపూరిత వాతావరణం ఏర్పడుతుంది. ధనాదాయం మాత్రం బాగుండును. న్యాయబద్ధమైన ధనార్జన లభిస్తుంది. వృత్తి వ్యాపారములలో జయం ప్రాప్తిస్తుంది. కోర్తు వ్యవహరములలో అనుకూలత ఉండదు. వివాహ సంబంధ విషయాల్లో చర్చలు మధ్యవర్తుల ద్వారా కలసివచ్చును. కానీ ఈ మాసంలో సంతాన సంబంధ ప్రయత్నాలు కలసిరావు. ఈ మాసంలో 7,8,9,10 తేదీలలో నూతన పెట్టుబడులు లాభించును.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో అంత అనుకూల ఫలితాలు లేవు. ఆత్మీయుల వియోగ వార్త వినవలసి రావచ్చును. కొట్టిపాటి కష్టం మీద వ్యవహార విజయాలు పొందుతారు. గురు గ్రహ బలం వలన సంతాన ప్రయత్నాలు చేయువారికి చక్కటి పుత్ర సంతాన ప్రాప్తికి సూచనలు ఉన్నవి. కుటుంబ సమాగములందు పాల్గొంటారు. స్త్రీ లకు వారసత్వ భాగ్యం ప్రాప్తించును.జీవిత భాగస్వామి వలన ఆర్ధికంగా బలపడుదురు. ఇతరులను విమర్శించకుండా ఉండుట మంచిది. ఈ నెలలో 6, 27 తేదీలు మంచివి కావు.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2, 3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో జీవిత భాగస్వామితో చక్కటి అనుకూలత సిద్ధిస్తుంది. సౌకర్యవంతమైన జీవనం లభిస్తుంది. మీ అవసరముల కోసం బంధు వర్గంపై ఆధారపడకుండా ఉండుట మంచిది. ఈ మాసంలో వృధా వ్యయం తగ్గును. ద్వితియ వారంలో విందు భోజన యోగం ఉన్నది. వాహనముల వలన ఇబ్బందులు ఎదుర్కొంటారు. విదేశీ లేదా ఉద్యోగ ప్రయత్నములలో కష్టం మీద విజయం ప్రాప్తిస్తుంది. విదేశీ జీవన ప్రయత్నాలకు 7,8,9,10 తేదీలలో ప్రయత్నించుట మంచిది. ఈ మాసంలో 3, 12, 17, 23, 27 తేదీలు అనుకూలమైనవి కావు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో కోర్టు వ్యవహారములలో విజయం లభిస్తుంది. ఆరోగ్య సమస్యలు నుండి ఉపశమనం పొందుతారు. కుటుంబ కార్యముల కోసం ధన వ్యయం చేస్తారు. నూతన వ్యాపారములు మధ్యమ ఫలితాలను ఇస్తాయి. ద్వితియ వారం నుండి ఉద్యోగ జీవనంలో అనుకోని ఒడిదుడుకులు ఎదురగును. నిర్దేశించుకున్న లక్ష్యాలు పూర్తి కావు. ప్రయాణములు వలన అలసట అనుభవిస్తారు. లాటరీల వలన నష్టపోవుదురు. మీ వ్యావహారిక స్థాయి ని మించి హామీలు ఇచ్చుట పనికిరాదు. ముఖ్యంగా హస్త నక్షత్ర జాతకులు ఇతరులతో మాటలాడు సందర్భంలో జాగ్రత్తగా ఉండవలెను.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ఆదాయ వ్యయములు సమానంగా ఉండును. కుటుంబ సహకారం వలన బాధ్యతలు సక్రమంగా నిర్వర్తించుదురు. గతకాలపు వాయిదా పడిన పనులు పూర్తిఅగును. జీవిత భాగస్వామి సంబంధిత విషయాలలో చక్కటి సౌఖ్యత ఏర్పడును. అవివాహితుల వివాహ ప్రయత్నములు ఫలించును. ఈ మాసంలో పౌరాహిత్యం నిర్వహించువారికి అంత మంచిది కాదు. దానములు స్వీకరించునపుడు ఆలోచించుట మంచిది.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో కొద్దిపాటి అననుకూల పరిస్థితులు ఎదుర్కొందురు. ప్రధమ వారంలో స్థాన భ్రంశం, విద్యావిఘ్నత, శరీరమునకు హాని వంటి ప్రతికూల ఫలితాలు ఎదుర్కొంటారు. వ్యాపార రంగం వార్కి ఆదాయంలో తగ్గుదల ఆందోళన కలుగ చేస్తుంది. తలపెట్టిన వ్యవహారములందు సమస్యల వలన మానసిక అశాంతి ప్రారంభం అవుతుంది. అనవసర ఖర్చులు బాదించును. ద్వితియ మరియు తృతీయ వారములలో ఉద్యోగ జీవులకు శ్రమకరంగా ఉండును. ఈ మాసంలో 4, 7 9, 12, 13, 18, 21, 27 తేదీలు అనుకూలమైనవి కావు.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో కూడా అనుకూలమైన ఫలితాలు ఏర్పడును. వారసత్వ సంబంధ స్థిరాస్థి లభించుట వలన కుటుంబానికి ఒక లాభం. యువ దంపతుల సంతాన కోర్కెలు సిద్ధించును. ధనాదాయం బాగుండును. నూతన గృహోపకరణాలు ఏర్పరచుకొందురు. శుభాకార్యక్రమాలలో పాల్గొంటారు. వ్యక్తిగత జీవనంలో సౌఖ్యం. ఉత్సాహవంతంగా కాలం గడుచును. ఉద్యోగ జీవనంలో చురుకుదనంతో అధికారుల మన్ననలు పొందుతారు. ఆరోగ్యం సహకరించును. చివరి వారంలో ఆత్మీయుల నుండి ఒక శుభ వార్త. దూరప్రాంత ప్రయాణాల వలన కొద్దిపాటి శారీరక ఒత్తిడి. ఈ మాసంలో 4, 5, 9, 11, 15, 18, 24, 25, 29 తేదీలు నూతన ఆలోచనలు ఆచరణలో పెట్టుటకు అనుకూలమైనవి.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసం మేషరాశి వారికి అనుకూలమైన కాలం. చేస్తున్న వృత్తి వ్యాపార ఉద్యోగాదులలో వ్యతిరేకత తగ్గుతుంది. పై అధికారుల వలన ప్రశంసలు పొందుతారు. స్థాన చలన ప్రయత్నాలు ఫలిస్తాయి. నూతన వ్యాపారములు ప్రారంభించవచ్చు. ఉద్యోగ మార్పిడికి ఈ మాసం అనుకూలమైన కాలం. ఈ మాసంలో కూడా జీవిత భాగస్వామి అనారోగ్య సమస్యలు కొనసాగును. చివరి వారంలో ఆకస్మిక ప్రయాణాలు ఎదురగు సూచన. మాసం చివరికి శారీరక శ్రమ పెరుగును.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో చక్కటి ఫలితాలు ఎదురగును. పెద్ద వయస్సు వారికి శరీర అనారోగ్య సమస్యలు తొలగును. ఆరుద్ర నక్షత్ర జాతకులకు మాత్రం శత్రు సంబంధ చికాకులు ఎదురగును. అవివాహితుల వివాహ ప్రయత్నాలు ఫలించును. మాస మధ్యమంలో ధార్మిక కార్యక్రమాల కొరకు వ్యయం చేస్తారు. 18 వ తేదీ తదుపరి కుటుంబ పరమైన ఒక వ్యవహార విజయం ఉన్నది. వృత్తి వ్యాపారదులలో జయం పొందుతారు. ఈ మాసంలో 23,24,25, తేదీలలో వ్యాపార వర్గమునకు అతి చక్కటి కాలం. ఈ తేదీలలో చేయు నూతన పెట్టుబడులు లాభించును. అదేవిధంగా ఈ మాసంలో 11, 17, 20 తేదీలలో ప్రారంభించు ప్రయాణములు ఫలించును. మాసాంతంలో స్త్రీ సంబంధ సౌఖ్యం ఏర్పడును.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో వృత్తి, ఉద్యోగ, వ్యాపారములలో ఆశించిన స్థాయిలో లాభములు పొందుతారు. సమయానికి అందరి సహకారము లభించును. దూర ప్రయనములు చేయుట కలసివచ్చును. విలువైన డాక్యుమెంట్స్ కనిపించకుండా పోవుట వలన ఇబ్బంది పడతారు. కుటుంబ సభ్యుల అనారోగ్య కారణంగా ధనవ్యయం ఎదురగును. తృతీయ వారంలో దైవదర్శన మరియు పుణ్య క్షేత్ర సందర్శన భాగ్యం లభిస్తుంది. మాసాంతమునకు కుటుంబంలో చక్కటి ప్రశాంత వాతావరణం ఉంటుంది. ఈ మాసంలో 5,14,20,29 తేదీలు మంచివి కావు.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ఆశించిన స్థాయిలో లాభములు ఏర్పడును. నూతన వాహన లేదా సొంత గృహ ప్రయత్నములు లాభించును. గత కాలంలో స్తంభించిన వ్యాపార వ్యవహారములు తిరిగి ప్రారంభం అగును. నూతన స్థలములను సందర్శన చేస్తారు. ద్వితియ వారంలో కార్యగతి అనుకూలంగా ఉండును. మంచి మాటలతో వ్యవహారములు పూర్తి చేస్తారు. తృతీయ వారంలో మాతృవర్గం సహకారంతో వివాహ ప్రయత్నములు లాభించును. ఈ మాసంలో 18 నుండి 24వ తేదీల మధ్య ఉద్యోగ మార్పు ప్రయత్నాలు ఫలించును.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో అకారణంగా మీ పై స్థాయి వ్యక్తులతో కలహములు ఏర్పడును. ఆత్మీయుల మరణం ఎడబాటు కలుగచేస్తుంది. తలపెట్టిన ప్రయత్నములలో ఆటంకములు ఎదురగును. ఈ మాసంలో ధనాదాయం సామాన్యం. గృహంలో పెద్ద వయస్సు వారికి ఆరోగ్య భంగములు ఎదురగును. ఈ మాసంలో 19, 20, 21 తేదీలలో భూ సంబంధమైన లేదా సొంత గృహ సంబంధమైన ప్రయత్నములలో కదలిక ఏర్పడును. చివరి వారం సంతాన సంబంధ ప్రయత్నములు చేయువారికి శుభ ఫలితాలు కలుగచేయును. వ్యాపార రంగంలోని వారికి ఈ మాసం అంతా మద్యమ ఫలితాలు లభిస్తాయి.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం అంత అనుకూలమైన ఫలితాలను కలిగించదు. ధన వ్యయము అధికం అగును. ఆకస్మిక సమస్యలు వెంటాడును. ప్రయాసతో కూడిన కార్య జయం ఏర్పడుతుంది. ఉష్ణ సంబంధమైన అనారోగ్యములు బాధించును. నూతన ఉద్యోగ ప్రయత్నాలలో చికాకులు ఎదురగును. ఈ మాసంలో ఒక పర్యాయం కేతు గ్రహ శాంతి జరిపించిన మంచిది. ఈ మాసంలో 1, 3, 12, 13, 19, 27 తేదీలు అనుకూలమైనవి కావు.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో అజాగ్రత్త వలన మాటపడు సంఘటనలు ఎదురగును. 6,7,8 తేదీలలో వాహన ప్రమాదమునకు లేదా ఆకస్మిక అస్వస్థతతో బాధ పడు అవకాశం ఉన్నది. ద్వితియ వారం కూడా అంత మంచిది కాదు. ప్రారంభించిన కార్యములందు అననుకూలమైన ఫలితాలు ఎదురగును. ప్రాణ స్నేహితులతో విభేదాలు మానసికంగా బాధను కలుగచేస్తాయి. తృతీయ వారం నుండి గతంలో ఆగిపోయిన విదేశీ జీవన సంబంధ ప్రయత్నాలు తిరిగి ప్రారంభమగును. మాసాంతంలో జీవిత భాగస్వామితో సౌఖ్యం. ఈ మాసంలో 10, 14, 26, 27 తేదీలు అవివాహితుల వివాహ ప్రయత్నములకు మంచిది.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసం కూడా అనుకూలమైన కాలం. సజ్జన సాంగత్యం లభించును. వినోద కార్యక్రమాలలో పాల్గోనేదురు. వస్త్ర, గృహ, ధన లాభములు పొందుతారు. ఋణ బాధలు తొలగును. కుటుంబంలో మానసికంగా ఆనందకరమైన వాతావరణం ఏర్పడుతుంది. అవసరమైన ధనం చేతికి సకాలంలో అందును. భవిష్యత్ గురించిన బాటలు ఏర్పడును. ఉద్యోగ స్థిరత్వం లభిస్తుంది. ఈ మాసంలో 19 వ తేదీ తదుపరి మాసాంతం వరకూ చక్కటి లాభకరమైన పరిస్థితులు ఏర్పడతాయి. అవివాహితుల వివాహ ప్రయత్నాలు ఫలించును.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో అనుకూలమైన ఫలితాలు పొందుదురు. వృత్తి వ్యాపారములలో ఆశించిన లాభం పొందుతారు. ఆర్ధిక ఋణములు తీరును. తలచిన ప్రతీ ఆర్ధిక కార్యం విజయం పొందును. రావలసిన ధనం చేతికి వచ్చును. కళత్ర వర్గం వారితో వైరం బాదిస్తుంది. వివాహ ప్రయత్నములకు ఈ మాసం అనుకూలమైనది కాదు.ఈ మాసంలో 7,8,9 తేదీలలో ఆదాయం బాగుండును. 13, 16, 19 వ తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ప్రారంభం నుండి ధన సంబంధ ఒత్తిడి తగ్గును. సంతానం వలన చక్కటి సౌఖ్యత అనుభవిస్తారు. శుభకార్య సంబంధ వ్యవహారములు బంధు మిత్రుల ఆదరణ వలన పూర్తి చేయుదురు. వ్యాపార వ్యవహారములు సామాన్యంగా కొనసాగును. ఆదాయం పెరుగును. తృతీయ వారం నుండి నిర్దేశించుకున్న లక్ష్యాలు పూర్తికావడంలో అనేక అవాంతరములు ఎదురగును. ఉద్యోగ జీవనంలో స్థానచలనం పొందుతారు. మాసాంతంలో విదేశాలలో ఉన్నవారి సహకారం లభిస్తుంది. వారి ఆదరణ వలన సమస్యల నుండి బయట పడతారు. మాసాంతంలో మాతృ వర్గీయులకు ఆరోగ్య సమస్యలు.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసం కూడా అంత అనుకూలంగా ఉండదు. ఆదాయం మరింత తగ్గును. నిల్వ ధనం కూడా వ్యయమగును. ఆశించిన ఫలితాలు లభించవు. పెట్టుబడులు వృధా అగును. ఒంటరితనం బాధించును. పరిస్థితులు అనుకూలించక నిరాశ ఆవహించును. పితృ వర్గం వారి వలన ఖర్చులు పెరుగుతాయి. ఈ మాసంలో ఒక పర్యాయం రాహు గ్రహ శాంతి జపములు జరిపించుకోనుట మంచిది. ఈ మాసంలో 2,3,16,17,25,26 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో కుటుంబంలో ఒక మార్పు ఏర్పడును. మూత్రపిండాల వ్యాధితో బాధపడు వారికి ఇది మంచి మాసం కాదు. వ్యాపారాదులు సామాన్యంగానే నడుచును. భాగస్వామ్య వ్యాపారాదులు బాగా ఆదాయం కలుగచేయును. జీవిత భాగస్వామి సహకారం ఆశించిన రీతిగా లభించును. రుణభారం తగ్గును. కుటుంబ వ్యక్తుల అభివృద్ధికి కృషి చేయుదురు. భాత్రు వర్గానికి మీ సలహాలు లాభాన్ని కలుగచేయును. విఘ్నాలు ఎదురైనా కార్యజయం పొందుదురు. గతకాలంలో చేసిన తప్పులు సరిదిద్దుకోనుటకు ఒక అవకాశం లభించును. నిరుద్యోగులకు ఆశించిన ఉద్యోగ ఫలితాలు.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో వారసత్వ సంపద కు సంబందించిన సమస్యల ప్రభావం కొద్దిగా తగ్గును. ఈ మాసం సంతాన ప్రయత్నములకు అనుకూలమైన కాలం కాదు. దైవ ఆశీస్శులు మరియు వంశ పెద్దల ఆశీస్సులు అవసరం. భరణీ నక్షత్ర జాతకులకు ఈ మాసం కోర్టు వ్యవహరములలో అనుకూలత ఎదురగును. ప్రభుత్వ సంబంధ పెద్దల పరిచయం వలన లాభ పడుదురు. బంధు వర్గ కలయికలు ఆనందము కలుగచేయును. వృత్తి వ్యాపారములలో సామాన్య ఫలితాలు ఎదురగును. అశ్విని నక్షత్ర జాతకులకు మాసాంతంలో శిరోబాధ వలన సమస్యలు ఉన్నాయి.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం కూడా అనుకూలమైన కాలం. తలపెట్టిన ప్రతీ ప్రతీ కార్యం విజయం పొందును. ఆశించిన విధంగా బంధువులు, స్నేహితుల తోడ్పాటు లభించుట వలన కష్టములు నుండి బయటపడుడురు. బంధు వర్గంతో కలయికలు ఆహ్లాదకరమైన వాతావరణాన్ని ఏర్పరుస్తాయి. కోర్టు వ్యవహారములలో అనుకూల తీర్పులు పొందుతారు. వ్యాపార వర్గాల వారికి ఆశించిన ధన ప్రాప్తి లభిస్తుంది. అనారోగ్య సమస్యల నుండి ఉపశమనం పొందుతారు. ఈ మాసంలో కూడా అన్ని రంగముల వారికి అనుకూలత ఉన్నది.ఈ మాసంలో 5,9,17,26 తేదీలలో మాత్రం కొంత మిశ్రమ ఫలితాలు ఏర్పడతాయి.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో ఇష్టమైన వ్యక్తులతో ప్రయాణములు చేస్తారు, ఆశించిన విధంగా ధనం సమయానికి సర్దుబాటు అవుతుంది. సంతానం వలన చక్కటి సౌఖ్యత అనుభవించుదురు. ప్రధమ వారంలో భాగస్వామ్య వ్యాపారములలో నష్టం ఎదురగును. ద్వితియ వారంలో ఇరుగుపొరుగు వారితో తగాదాలు ఏర్పడును. ఉద్యోగ జీవనంలో ఆకస్మికంగా ఒత్తిడి అధికం అవుతుంది. పుబ్బ నక్షత్ర జాతకులకు 16,17,18 తేదీలలో గౌరవ హాని సంఘటనలు ఎదురగును. మాసాంతంలో శ్రమ అధికం అగును. ఆరోగ్య భంగములు కూడా ఎదురగు సూచన ఉన్నది.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ఉద్యోగ జీవనంలో ఉన్నతి లేదా జీతం లో పెరుగుదల ఆశించవచ్చు. పితృ వర్గీయుల ఆదరణ మరియు ప్రేమ పొందుదురు. మిత్రుల వలన లాభపడతారు. ప్రధమ వారంలో భాగస్వామ్య వ్యాపారములు విజయవంతమగును. నూతన కాంట్రాక్టులు, ప్రాజెక్టులు లభించును. అవివాహితులకు శుభవార్త. తృతీయ వారంలో ధైర్య సాహసములు పెరుగును. ఇతరులకు ఇచ్చిన ముఖ్య వస్తువు లేదా ధనం తిరిగి వచ్చుట కష్టం. ఈ మాసంలో 4, 9, 17, 20, 26 తేదీలు అనుకూలమైనవి కావు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో వ్యపారాదులలో ఆశించిన లాభములు ఉంటాయి. ఉద్యోగ జీవనంలో ప్రమోషన్లు ఆశించవచ్చు. ఉత్సాహ పూరిత కాలం. పదోన్నతి వలన అధిక గౌరవాన్ని గడిస్తారు. నూతన ఆలోచనలు కార్యరూపం దాల్చును. ప్రభుత్వ కాంట్రాక్టు సంబంధ వ్యాపార కార్యములలో మాత్రం ఆటంకములు ఎదురగును. పోటీదారుల వలన పరాభవం పొందుతారు. తొందరపాటు నిర్ణయాల వలన వృధా ప్రయాణములు చేయవలసి వస్తుంది. ఈ మాసంలో 10,11,12,13,14 తేదీలలో ధన వ్యయం అధికంగా ఏర్పడుతుంది. మాసాంతంలో జీవన మార్గంలో ఆర్ధిక పరమైన చికాకులు ఎదురగును. ముఖ్యంగా 29, 30 తేదీలలో ధనం ఇతరులకు ఇవ్వరాదు.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం కూడా అనేక అవాంతరములను, ఇబ్బందులను కలిగించును. ఉద్యోగ జీవనంలో ఊహించని నష్టములు ఏర్పడు సూచన ఉన్నది. జీవిత భాగస్వామితో మాట కలయికలో విభేదాలు ఎదురగును. మానసిక అశాంతి బాధించును. సంతానం వలన సమాజంలో గౌరవ హాని ఎదుర్కొంటారు. స్థిరాస్తి సమస్యలు, వృధా వ్యయము కూడా భాదించు సూచన. మాసాంతంలో భవిష్యత్ గురించిన అనవసర భందోళనలు ఉండగలవు. ఈ మాసంలో 26 నుండి మాసాంతం వరకూ మంచి సమయం కాదు.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో సమస్యలు తగ్గుముఖం పడతాయి. వ్యక్తిగత మరియు కుటుంబ పరమైన జీవనంలో విశేష సంతోషములు, సుఖములు అనుభవిస్తారు. తలపెట్టిన నూతన కార్యములు విజయవంతం అవుతాయి. వ్యాపార రంగంలోని వారికి చక్కటి ధన లాభం లభిస్తుంది. కుటుంబ సభ్యుల ప్రోత్సాహం వలన మీ ఆలోచనలను కార్యాచరణలో పెట్ట గలుగుతారు. ప్రభుత్వ ఉద్యోగం ఆశిస్తున్న వారికి శుభవార్త లభిస్తుంది. రాజకీయ రంగంలోని వారు సమాజంలో పేరు ప్రఖ్యాతలు సంపాదించుకోగలుగుతారు. ఈ మాసంలో 3,9 తేదీలు సంతాన సంబంధ ప్రయత్నములకు అనుకూలం.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో శరీర ఆరోగ్యం బాగుండును. ఉల్లాసంగా బంధువులతో, మిత్రులతో చేరికలలో పాల్గొంటారు. సంతాన ప్రయత్నాలు ఫలిస్తాయి. దూర స్నేహితలతో కలయిక మానసికంగా ఉల్లాసము కలుగచేస్తుంది. జీవిత భాగస్వామితో పరుష వాక్కులు మాట్లాడట వలన దీర్ఘకాలం పాటు సంబంధ భాందవ్యాలు దెబ్బతింటాయి. వృత్తి వ్యాపారములు అనుకూలంగానే కొనసాగును. మాసాంతంలో పేరు ప్రఖ్యాతలు పొందగలుగుతారు. నిల్వ ధనమును తిరిగి ఏర్పరచుకోనగలుగుతారు. ఈ మాసంలో 8, 16, 24 మరియు 30 తేదీలు అనుకూలమైనవి కావు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో కూడా అనుకూలమైన ఫలితాలు పొందుతారు. ధనాదాయం పెరుగును. కుటుంబంలో సంతోషకర సమయం ఏర్పడుతుంది. పిన్న వయస్సు వారికి కెరీర్ పరంగా ఆశించిన వృద్ధి లభిస్తుంది. విదేశీ ప్రయత్నాలు కూడా ఆటంకాలు తొలగి విజయవంతం అవుతాయి. కళత్ర వర్గం వారితో వైరములు తొలగుతాయి. గౌరవ మర్యాదలు సంఘపరంగా పెరుగును. ఈ మాసంలో 5,7,12,27 తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసం వృత్తి, ఉద్యోగ, వ్యాపారముల వారికి సామాన్య ఫలితాలు ఏర్పరచును. స్త్రీ సంబంధమైన ధన వ్యయం ఎదురగును. భూ సంబంధ విషయాలలో క్రయవిక్రయాల వలన నష్టం ఎదుర్కొందురు. సంతాన లేమి దంపతుల సంతాన ప్రయత్నాలు ఫలించును. ఆదాయం గత మాసముల కన్నా మెరుగ్గా ఉండును. ద్వితియ వారంలో విలువైన సమాచారం లభించును. కుటుంబ వాతావరణంలో ఆనందకర సంఘటనలు ఉంటాయి. ఆర్ధిక లావాదేవీలలో జాగ్రత్త వహించవలెను. ప్రయాణములు సఫలమగును. కొత్త ప్రణాళికలు రచించుటకు ఈ మాసం సరైన సమయం.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో ఆర్ధికంగా కొంత అనుకూలత ఉన్నప్పటికీ కుటుంబ చికాకులు కొనసాగును. సొంత మనుష్యుల నిరాదరణ బాధించును. దూర ప్రాంత స్థిర నివాస ప్రయత్నాలు కష్టం మీద ఫలించును. 12, 13 తేదీలలో శరీరమునకు అలసట అధికం అవుతుంది. 20 నుండి 25 వ తేదీ మధ్య ఉద్యోగ జీవనలో లక్ష్య సాధన కొరకు ఒత్తిడి అధికం అవుతుంది. కార్య విఘ్నతలు ఎదురగును. ఆలోచనలు రాణించవు.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం సామాన్యం. ఆరోగ్య సమస్యలు కొద్దిగా చికాకులను కలుగచేయును. ఉద్యోగ వ్యాపార వ్యవహరాదులు సామాన్యంగా కొనసాగును. నిత్య జీవనంలో శ్రమ అధికంగా ఏర్పడును. అయినా గృహ భాద్యతలను సకాలంలో పూర్తి చేయగలరు. ప్రమోషన్లు ఆశించువారికి ఆశాభంగములు. ఈ మాసంలో 6, 10, 13, 14, 20, 27 తేదీలు అనుకూలమైనవి కావు. ముఖ్యంగా ఈ తేదీలందు ప్రయాణాలు చేయకుండా ఉండుట మంచిది.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో అంతగా అనుకూల ఫలితాలు ఏర్పడవు. మీ తప్పు లేకుండానే గౌరవ హాని ఎదురగుతుంది. వడ్డీ వ్యాపారం చేయు వారికి ఆకస్మిక నష్టములు ఎదురగును. తలపెట్టిన నూతన కార్యములందు విజయం అంత త్వరగా లభించదు. విద్యార్జన చేయువారికి, విదేశీ స్థిర నివాస ప్రయత్నాలు చేయు వారికి ఈ మాసంలో ఆటంకాలు ఎదురగును. ద్వితియ మరియు తృతీయ వారంలలో కారణ రహితంగా గొడవలు, కలహములు ఎదురగును. జీవిత భాగస్వామి సంబందీకులతో తగాదాలు ఇబ్బంది పెడతాయి. నాలుగవ వారం వివాహ ప్రయత్నములకు మాత్రం శుభం. మొత్తం మీద ఈ మాసంలో ఆశించిన ధనం చేతికి అందదు. ఈ మాసంలో 7, 9, 13 తేదీలు అనుకూలమైనవి కాదు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో గృహ సంబంధమైన సంతోషాలు పొందుతారు. జీవిత భాగస్వామి వర్గం వారి వలన తోడ్పాటు లభించి లాభములు పొందుతారు. ద్వితియ వారంలో అనవసరమైన పనులకు ధనం వృధా చేస్తారు. తోటి ఉద్యోగులతో అభిప్రాయ భేదాలు ఎదురవుతాయి. తృతీయ వారంలో సామాన్య ఫలితాలు ఎదురవుతాయి. చతుర్ధ వారం నుండి ఉద్యోగ జీవనం లోని వారి స్థానచలనము కొరకు చేయు పనులు లాభించును. కోరుకొన్న విధంగా నూతన వాహన సౌఖ్యం ఏర్పరచుకోనగలుగుతారు. సంతాన సంబంధ శుభవార్త లభిస్తుంది. ఈ మాసంలో 1, 8, 20, 22 తేదీలు మంచివి కావు.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో ఆశించిన రుణాలు లభిస్తాయి. శరీర ఆరోగ్యం సహకరించును. 10 వ తేదీ వరకూ తలపెట్టిన ప్రయత్నములు అతి కష్టం మీద విజయమగును. 14 వ తేదీ నుండి 19 వ తేదీ వరకూ వ్యాపార విస్తరణకు అనుకూలమైన సమయం. కొన్ని సమస్యలకు మధ్యవర్తిత్వం ఫలించును. కోర్టు తీర్పులు అనుకూలంగా ఉండును. గృహ సంతోషములు మధ్యమం. ఈ మాసంలో 7,25,30 తేదీలు అనుకూలమైనవి కావు. 5,6 తేదీలలో వివాహ ప్రయత్నములకు అనుకూలం. కళత్ర సౌఖ్యత అనుభవిస్తారు. మొత్తం మీద ధనాదాయం బాగుండును.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో వ్యాపారాదులలో జయం ఎదురగును. ఆశించిన ధన ప్రాప్తి లభిస్తుంది. ఉద్యోగ జీవనంలో అనుకూలమైన మార్పులు చోటు చేసుకుంటాయి. వృత్తి పనులు చేయు వారికి ఆశించిన ధన లాభములు లభిస్తాయి. ద్వితియ వారం సామాన్య ఫలితాలు ఏర్పరుస్తాయి. తృతీయ వారంలో కుటుంబంలో మానసిక అశాంతి నెలకొంటుంది. జీవిత భాగస్వామితో ప్రతికూలత ప్రారంభం అవుతుంది. కుటుంబ సభ్యుల అనారోగ్యములకు ధనవ్యయం చేయవలసి వస్తుంది. చివరి వారం బాగుండును. ముఖ్యంగా దూరప్రాంత లేదా విదేశీ నివాస ప్రయత్నములు ఫలించును. ఈ మాసంలో బంధు మిత్రులతో జాగ్రత్తగా వ్యవహరించుట మేలు. ఈ మాసంలో ప్రధమ మరియు చతుర్ధ వారములు మంచివి.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో భూ లేదా గృహ సంబంధమైన విక్రయాదుల వలన ధన ప్రాప్తి లభిస్తుంది. సువర్ణ సంబధమైన పెట్టుబడులు లాభములు ఏర్పరచును. కుటుంబ జీవనంలో సంతోషకరమైన వాతావరణం ఉంటుంది. ధనాదాయం బాగుంటుంది. ఆశించిన రంగంలో విద్యార్ధులకు గుర్తింపు మరియు విజయం లభిస్తాయి. మాస మధ్యమంలో ధర్మ కార్య సంబంధమైన పనులకు వ్యయం చేస్తారు. ఇష్ట దైవ సందర్శన భాగ్యం లభిస్తుంది. మిత్రుల సహకారంతో పనులు పూర్తిచేయగలుగుతారు. నూతన పెట్టుబడులు పెట్టుటకు ఈ మాసం మంచి సమయం. అవివాహితులకు కూడా ఈ మాసంలో వివాహ సంబంధమైన శుభ ఫలితాలు ఉన్నవి.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం ప్రారంభంలో అతిగా నమ్మిన వ్యక్తుల వలన నమ్మక ద్రోహం, ధన వ్యయం ఎదుర్కొంటారు. 13 వ తేదీ తదుపరి లాభకరమైన పరిస్థితి మొదలగును. గత రెండు నెలలలో ఎదుర్కొన్న చికాకులు క్రమేపి తొలగును. నిలిచిన వ్యాపార వృత్తులు తిరిగి ప్రారంభమగును. వివాహ సంబంధ ప్రయత్నాలు, నూతన గృహం కొరకు చేయు ప్రయత్నములు ఫలించును. స్త్రీ సంబంధ విషయాలలో అనుకూలత ఏర్పడును. చివరి వారంలో నూతన ఆదాయ మార్గములు లభించును. పితృ వర్గం వారి నుండి ఎంతో కాలంగా ఎదురుచూస్తున్న సహకారం లభించును.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో కూడా గత మాసంలో వలె శుభ ఫలితాలు కొనసాగును. తలపెట్టిన నూతన ఆలోచనలు కార్య రూపం దాల్చును. ఇష్ట దైవ సందర్శన భాగ్యం లభిస్తుంది. కుటుంబంలోని స్త్రీ సనాతనం వలన వ్యయం ఎదురవును. ప్రభుత్వ ఉద్యోగ జీవనంలో వారికి ఆశించిన అనుకూల మార్పులు లభిస్తాయి. ఈ మాసంలో 7, 9, 16 తేదీలు వ్యాపార రంగం లోని వారికి మంచిది. యువకులకు చివరి వారం లో దారితప్పే ఆలోచనలతో మనఃశాంతి లోపించును. జీవిత భాగస్వామి తో అపార్ధాలు ఏర్పడును.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో ప్రేమ వ్యవహారములందు అవరోధములు ఎదురగును. మానసిక వ్యధ బాధిస్తుంది. అకాల భోజనముల వలన శిరోబాధ, నిద్రలేమి ఇబ్బంది పెట్టును. ఉద్యోగ జీవనంలో కష్టం మీద లక్ష్యాలను పూర్తి చేయగలుగుతారు. విరామం లేకుండా శ్రమించ వలసి వస్తుంది. చివరి వారములో రహస్య సమాచారములు తెలుసుకుంటారు. ఈ మాసంలో ఆవేశం తగ్గించుకోనిన మంచిది. శ్రమతో కూడిన ప్రయాణములు ఎదురగును. ఈ మాసంలో 9,13,15,22 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసం ప్రధమ వారంలో ఒక అశుభ వార్త వినడానికి సూచన ఉన్నది. మనోవిచారం అనుభవిస్తారు. తెలిసిన వ్యక్తుల వలన ఆర్ధిక లాభములు ఏర్పడతాయి. ద్వితియ వారంలో ఉద్యోగ జీవనంలో కొంచం ఒడిదుడుకులు ఎదుర్కుంటారు. ఉద్యోగ నష్టమునకు సూచనలు లేవు. రక్త సంబంధ అనారోగ్య విషయాల పట్ల జాగ్రత్త అవసరం. తృతీయ వారంలో అధికంగా వృధా వ్యయం ఎదురగును. అదృష్ట రాహిత్యత బాదిస్తుంది. చివరి వారం నుండి పరిస్టితులలో అనుకూలత ప్రారంభం అవుతుంది. మాత్రు వర్గీయుల సహకారం లభించుట వలన ఒక సమస్య నుండి బయట పడతారు.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసం మొదటి వారంలో ఒక ముఖ్య వ్యవహారం ఆటంకములను పొందును. పోలీసుల వలన లేదా కోర్టుల వలన వేధింపులు ఉండగలవు. మానసిక ఆందోళన అధికం అవుతుంది. ఈ మాసంలో కూడా ధనాదాయం సామాన్యం. 12 వ తేదీ తదుపరి వృత్తి వ్యాపారదులు అభివృద్దిలో ఉండును. 19, 20 వ తేదీలలో యంత్ర సంబంధమైన సమస్యలు. 22 వ తేదీ తదుపరి సమస్యల తీవ్రత తగ్గును. ఉద్యోగ జీవనం సామాన్యంగా కొనసాగును.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో పరిస్టితులు మెరుగుపడును. భాగస్వామ్య వ్యాపారములు మాత్రం లాభించవు. తోదరపడి పెట్టుబడులు పెట్టుట మంచిది కాదు. మాస మధ్యమంలో ఇష్ట దైవ దర్శన భాగ్యం లభిస్తుంది. పారమార్ధిక చింతన అధికం అవుతుంది. ఆర్ధిక పరిస్థితులు నెమ్మదిగా సంతృప్తికరంగా పరిణామం చెందుతాయి. బంధు మిత్రులతో జరుపు మాటలందు జాగ్రత్త అవసరం. వివాహ, సంతాన ప్రయత్నములు లాభించును. ఈ మాసం పుత్ర సంతానము పొందుటకు అనుకూలమైన కాలం.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో ఆర్ధికంగా కొద్దిపాటి ఒడిదుడుకులు ఏర్పడును. ఉహించని రీతిలో ఒక ధన నష్టం ఏర్పడుటకు అవకాశం ఉన్నది. నూతన పెట్టుబడులు ఈ మాసంలో మంచి ఫలితాలు ఇవ్వవు. ద్వితీయ వారంలో ధనవ్యయం అంచనాలు దాటును. ఉద్యోగ కార్యాలయంలో రాజకీయాలకు దూరంగా ఉండుట మంచిది. ఈ మాసంలో నూతన ఋణాలు తీసుకొనుట లేదా ఇతరుల రుణాలకు హామీలు ఇచ్చుట మంచిది కాదు. ఈ మాసంలో శనికి శాంతి జపములు జరిపించుకోనుట మంచిది.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసం కొద్దిగా మంచి ఫలితాలనే ఏర్పరచును. గత కాలంలో అర్ధాంతరంగా నిలిచిపోయిన కార్యములు ఊహించని విధంగా తరిగి ప్రారంభించబడి పూర్తి అగుట మిమ్మల్ని అబ్బుర పరుస్తుంది. తలచిన పనులు సకాలంలో పూర్తి అవుతాయి. ధనాదాయం బాగుండును. సమాజహితమైన పనులలో పాల్గొంటారు. ఈ మాసంలో 15 నుండి 25 వ తేదీ మధ్య నూతన ఉద్యోగ ప్రయత్నాలకు అనుకూల కాలం. పై అధికారుల సహకారం లభించి లాభపడతారు. వ్యాపారములలో చక్కటి ధనప్రాప్తి లభిస్తుంది. బాగా ఎదిగిన స్త్రీ సంతానం వలన సంతోష పడతారు. వారి ఉన్నతి మీకు పేరు ప్రఖ్యాతలు కలుగచేయును.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం మిశ్రమ ఫలితాలను ఏర్పరచును. వ్యాపార రంగంలో పోటీదారులు అధికం అగును. ఆదాయంలో కొంత తగ్గుదల ఎదుర్కొంటారు. ఆర్ధిక పరంగా కొద్దిపాటి మానసిక అశాంతి ఎదుర్కొందురు. తల్లితో లేదా మాతృ వర్గీయులతో విభేదాలు ఏర్పడటానికి సూచనలు అధికంగా ఉన్నాయి. ఈ మాసంలో 10,11,12 తేదీలలో విహార యాత్రలు లేదా గృహ సంతోషాలు లభిస్తాయి. ప్రభుత్వ రంగ ఉద్యోగులకు ఈ మాసంలో 22,23,24,25 తేదీలలో ఉన్నత అధికారుల వలన ఇబ్బందులు ఎదురగును. ఉద్యోగ జీవన భద్రత కు సంబంధించిన భయం, ఆందోళన అనుభవించుదురు. ఈ కాలంలో ఉద్యోగ మార్పు కొరకు ప్రయత్నించుట మంచిది కాదు. వైద్య రంగంలోని వారు 22 నుండి 26 వ తేదీల మధ్య కాలంలో జాగ్రత్తగా ఉండవలెను.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో సామాన్య ఫలితాలు ఎదురగును. నూతన పనులు ఆరంభించుటకు ఈ మాసం అనుకూలమైన కాలం కాదు. భూ సంబంధ విషయాల్లో లేదా వారసత్వ సంబంధ విషయాల్లో చికాకులు అధికం అగును. ద్వితియ వారంలో నూతన వస్త్ర లాభం ఉంటుంది. 13 వ తేదీ నుండి ధనదాయంలో పెరుగుదల కనిపిస్తుంది. తృతీయ వారంలో కుటుంబంలో చక్కటి వాతావరణం నెలకొంటుంది. ఆనందపూరిత సమయం అనుభవిస్తారు. 23 వ తేదీ నుండి మాసాంతం వరకూ చేతిలో ధనం నిలువదు. వృధా వ్యయం ఎదుర్కొందురు. ప్రారంభించిన ప్రయాణములు అనేక చికాకులు ఎదుర్కొనును.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో ప్రతికూల ఫలితాలు ఎదురవుతాయి. ఆదాయంలో తగ్గుదల కనిపిస్తుంది. ఆశించిన స్థాయిలో ధన లాభములు ఏర్పడవు. వ్యాపార రంగంలో ధననష్టములకు అవకాశములు కలవు. మిత్ర వర్గమునకు సంభందిచిన ఒక అశుభ వార్త వినవలసి రావచ్చు. ఉద్యోగ జీవనంలో మార్పులకు అవకాశం లభించదు. ఈ మాసంలో సంతాన ప్రయత్నములు మాత్రం కలసి వచ్చును. చక్కటి సంతాన ప్రాప్తి యోగం ఉన్నది. 10వ తేదీ తదుపరి నూతన శిక్షణలు పొందు అవకాశం ఉన్నది. మాసాంతంలో నూతన వ్యవహారములు ప్రారంబించుట వాయిదా వేసుకొనుట మంచిది. ఒక పర్యాయం శనికి తైలాభిషేకం జరిపించిన మంచిది.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో నూతన పదవులు ప్రాప్తించును. గౌరవ ప్రధానమైన జీవనం గావిస్తారు. ఉన్నత అధికారుల మన్ననలు పొందుతారు. కుటుంబ సభ్యుల వినోద సంబంధమైన కోరికల వలన ధనం వ్యయం చేస్తారు. ద్వితియ వారంలో సోదరీ వర్గం వారికి వ్యక్తిగత జీవనంలో ఒక నష్టం ఏర్పడు సూచన. మానసికంగా ఆందోళన చెందుతారు. కుటుంబ సభ్యుల మధ్య చర్చలకు మధ్యవర్తిత్వం వహిస్తారు. ఈ మాసంలో ధనాదాయం సామాన్యం. వ్యాపారములలో అంతగా ప్రోత్సాహం ఉండదు. ఈ మాసంలో 2,6,15, 26 తేదీలు మంచివి కావు.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో కూడా అనుకూల వాతావరణం కొనసాగును. వ్యవహారాలలో శ్రమకు తగిన ఫలములు పొందును. జీవిత భాగస్వామి వర్గం వారికి ఈ మాసం ఆరోగ్య పరంగా మంచి కాలం కాదు. గృహంలో కొద్దిపాటి ఆందోళనపూరిత వాతావరణం ఏర్పడుతుంది. విద్యార్దులు, విదేశీ ప్రయత్నములు చేయువారు, స్థాన చలనమునకు ప్రయత్నించు వారికి తృతీయ వారం నుండి శుభ ఫలితాలు ప్రారంభం అగును. మనోవంచా ఫలసిద్ధి ఏర్పడును. పేరు ప్రఖ్యాతలు గడించగలుగుతారు.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో కొద్దిపాటి చికాకులు, గృహ లేదా భూ సంబంధమైన నష్టములు ప్రాప్తించును. ద్వితియ వారంలో కుటుంబ సభ్యులలో ఒకరికి శస్త్ర చికిత్స లేదా తీవ్ర అనారోగ్యం వలన ఆందోళన ఎదురగును. తదనుకూల ధన వ్యయం వలన ఆర్ధిక సంబంధ ఒత్తిడి ఎదుర్కొందురు. కార్మిక రంగంలోని వారికి శ్రమ అధికమగును. రోహిణి నక్షత్ర జాతకులకు కళత్ర వర్గం వారి వలన చికాకులు ఎదుర్కొందురు. వ్యాపార వర్గంలోని వారికి మిత్రుల సహకారం వలన ప్రయోజనం లభిస్తుంది. ఈ మాసంలో 22, 23 తేదీలు మరియు 28, 29 తేదీలు అనుకూలం కాదు. ఈ మాసంలో ప్రతీ దినం వృషభ రాశి వారునవ గ్రహ స్తోత్రములు చదువుకొనుట మంచిది.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో చికాకులు ఉన్నప్పటికీ చివరకు విజయం సిద్దించును. జీవిత భాగస్వామి సంబంధ విషయాల్లో ప్రతికూలత కొనసాగును. శత్రు జయం ప్రాప్తించును. మీరు చేయు పనులలో గుర్తింపు లభిస్తుంది. పురాణ శ్రవణ పుణ్య ఫలం లభిస్తుంది. ఈ మాసంలో ధనాదాయం సామాన్యం. ఉద్యోగ మార్పు ప్రయత్నములకు ఈ మాసం అనుకూలమైన కాలం. కుటుంబ సభ్యుల ప్రవర్తనా తీరు ఆశించిన విధంగా అనుకూలంగా ఉండదు. చివరి వారంలో శ్రమ అధికం అగును.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసం విద్యార్ధులకు అనుకూలమైన ఫలితాలను కలిగించును. విద్యా సంబంధమైన ప్రయత్నాలలో జయం. కార్య సిద్ధి. వినోదములు. కుటుంబంలో సంతోషములు. గృహంలో శుభకార్యములు. ఈ మాసంలో దాదాపు అన్ని విషయాలలో అనుకూలత ఉండును. ఉద్యోగ జీవనంలో ఉన్నత స్థితి ఆశించవచ్చు. నూతన వాహన మరియు వివాహాది సంబంధ విషయాలలో లాభములు పొందుతారు. సంతాన ప్రయత్నములు ఫలించును.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ప్రయత్నములు నెమ్మదిగా విజయం పొందును. కోర్టు తీర్పులు ప్రతికూలం. నిత్య జీవనంలో శ్రమ అధికమగును. పలుమార్లు ప్రయత్నించిన మీదట కార్య జయం ఏర్పడును. ఆధ్యాత్మిక కార్యక్రమాలలో పాల్గోనేదురు. ఉన్నత వర్గముల వారితో పరిచయం. తన్మూలక గౌరవం పొందుతారు. మాసాంతంలో గృహ మార్పిడి ప్రయత్నాలు విజయం పొందుతాయి. స్థాన చలనముకు అవకాశం. ఈ మాసంలో 8,13, 21,22, 28 తేదీలు అనుకూలమైనవి కావు.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసం అనుకూలమైన మాసం. నూతన గృహ లేదా వాహన కోరిక నెరవేరును. అవసరమైన ధనం చేతికి లభించును. ఇతరుల సమస్యల్లో మధ్యవర్తిత్వం నిర్వర్తించడం వలన కొన్ని ఇబ్బందులు ఎదుర్కొందురు. ఆలోచనలు సక్రమంగా ఉండును. పనుల భారం వలన అలసట ఏర్పడును. తోటి ఉద్యోగులతో తృతీయ వారంలో విభేదాలు బాధించును. క్రయవిక్రయాలలో లాభం పొందుతారు. కొత్త విషయాల పట్ల ఆసక్తి పెరుగును. జీవిత భాగస్వామి కి కొద్దిపాటి అనారోగ్య సమస్య.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసం ప్రారంభం నుండే ఆర్ధికంగా పడుతున్న ఇబ్బందులు తొలగును. ధనాదాయం వృద్ధి చెందును. ఆశించిన విధంగా ధన సంబంధ పరిస్థితులు ఏర్పడును. వ్యాపార విస్తరణకు, ఉద్యోగ మార్పిడి ప్రయత్నాలకు ఈ మాసం అనుకూలమైనది. ఆధ్యాత్మిక కార్యక్రమాలలో పాల్గొని మనో ఉల్లాసాన్ని పొందుదురు. వ్యక్తిగత ప్రతిభ, పని నైపుణ్యం ఉద్యోగ జీవులకు చక్కటి గుర్తింపు ఏర్పరచును. 22 వ తేదీ నుండి కొద్దిపాటి శారీరక బలహీనత బాధించును. కుటుంబంలో నూతన వ్యక్తుల చేరిక ఆనందాన్ని కలుగచేయును.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో జీవితంలో నూతన బాధ్యతలు చేపడతారు. గౌరవ ప్రధమైన జీవనం ఏర్పరచుకోగలరు. అన్ని విధములా ఆరోగ్యం సహకరించును. వృత్తి వ్యపారాదులలో మంచి లాభములు గడిస్తారు. కుటుంబ జీవనంలో పట్టుదలకు పోవడం వలన నష్టములు ఎదురవుతాయి. స్వార్ధం వదిలి ఆలోచించడం మంచిది. ఉద్యోగ జీవనంలో ఆకస్మిక అనుకూలమైన మార్పులు ఎదురగును. నూతనంగా ఏర్పడిన కుటుంబ బాధ్యతల వలన మానసికంగా అధిక ఆలోచనలు గావిస్తారు. అన్ని వర్గముల వారికి ఆదాయం సామాన్యం. వైవాహిక మరియు సంతాన సంబంధ విషయాలకు ఈ మాసం అనుకూలమైన కాలం.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో అనుకూల ఫలితాలు ఏర్పడవు. చేస్తున్న ఉద్యోగ నష్టం లేదా స్థిరాస్తి సంబంధమైన నష్టం ఎదురగును. మానసిక అధైర్యం పొందుదురు. ఆత్మీయుల వలన మానసిక ప్రసాంతత లభిస్తుంది. తలపెట్టిన కార్యములందు అనేక కార్య విఘ్నములు ఎదురగును. ఈ మాసంలో 18, 19 తేదీలలో తలపెట్టిన ప్రయాణములలో ప్రమాద సూచన ఉన్నది. జాగ్రత్త అవసరం. ఈ మాసంలో కాలం అంతగా కలసి రాదు. శని దేవునికి శాంతి జపములు జరిపిమ్చుకోనుట మంచిది. నూతన కార్య ప్రారంభములు చేయకుండుట మంచిది.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో గృహంలో విందు భోజనములు నిర్వహించెదరు. అతిధుల ఆతిధ్యంతో సమయం గడిచిపోతుంది. గృహంలో నూతన వస్తువులు అమరుతాయి. సంతానం యొక్క ప్రవర్తన మీకు ఇబ్బందిగా అనిపిస్తుంది. తృతీయ వారంలో ఉద్యోగ జీవనంలో ఉన్నతి లభించు సూచనలు ఉన్నవి. ఆశించిన ప్రమోషన్లు లభిస్తాయి. అందరి మన్ననలను పొందుదురు. మాసాంతంలో సామాన్య ఫలితాలు ఎదురగును. గృహ మార్పిడి ప్రయత్నాలు ఫలిస్తాయి. ఈ మాసంలో 8,11,20,26 తేదీలు అనుకూలమైనవి కావు.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో గత మాసములోని బాధలు తొలగును. ఒత్తిడి తగ్గును. ప్రధమ మరియు ద్వితియ వారములలో శత్రువిజయం ప్రాప్తిస్తుంది. జీవనంలో ఉన్నతిని ఆశించవచ్చు. స్త్రీ సంతాన సంభందమైన చికాకులు ఎదుర్కొంటారు. శుభకార్య ప్రయత్నాలు లాభించును. మాసాంతంలో స్థిరమైన ఆలోచనలు చేయుట కష్టముగా ఉండును. ముఖ్యంగా 13 నుండి 22వ తేది మధ్య కాలం అనుకూలంగా లేదు. 23వ తేదీ నుండి పరిస్థితులలో అనుకూలమైన మార్పులు ప్రారంభం అగును. ఈ మాసంలో నిత్యం నవగ్రహ స్తోత్రములు చదువుట మంచిది.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో జన్మ స్థానానికి కెరీర్ పరంగా స్థానచలనముకు అవకాశం ఉన్నది. ప్రయత్నాలు చేయవచ్చు. మాస ప్రారంభంలో కొద్దిపాటి అనారోగ్య సమస్యలు ఎదురగును. సేవకుల వలన ఆర్ధిక ద్రోహం పొందుటకు అవకాశములు అధికంగా ఉన్నవి. ఈ మాసం లో 19 వ తేదీ తదుపరి ఉద్యోగ జీవనంలో ఉన్నతి ఉన్నది. జీవన మార్గంలో ఆశించిన అభివృద్ధి పొందుతారు. మాసాంతంలో జీవిత భాగస్వామితో అతి చక్కటి అనుకూలత ప్రాప్తించును. నూతన కార్యములందు విఘ్నములు తొలగును. యంత్ర సంబంధ లాభములు గడిస్తారు. మొత్తం మీద ఈ మాసంలో ఆర్ధికంగా బాగుంటుంది.",
          Path: Karkata,
           Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో వివాహ ప్రయత్నములు చేయువారికి, ఆర్ధిక ఋణములు కొరకు ప్రయత్నించు వారికి అనుకూల ఫలితాలు లభిస్తాయి. బంధువుల వలన ఆర్ధిక లాభములు గడిస్తారు. ప్రభుత్వ ఉద్యోగులకు కెరీర్ పరంగా వాహనముద్రిదికార యోగం ప్రాప్తిస్తుంది. నూతన పరిచయాలు ఏర్పడతాయి. సంతాన ప్రయత్నాలు చేయు వారికి స్త్రీ సంతతి ప్రాప్తిస్తుంది. ఈ మాసంలో ధనాదాయం బాగుండును. ద్వితియ తృతీయ వారాలలో వ్యాపార జీవనంలోని వారికి ఒత్తిడులు అధికం అగును. 22 వ తేదీ తదుపరి ప్రేమ వ్యవహారాల వలన అపఖ్యాతి పాలగుతారు. 27 వ తేదీ తదుపరి కుటుంబ సభ్యుల కొరకు నూతన వస్తువులను కొనుగోలు చేస్తారు.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో సొంత గృహ సంబంధమైన పనులు కార్యాచరణలో కి వచ్చుట వలన సంతోషములు అనుభవిస్తారు. వృషభ రాశి వారికి ఈ మాసం గృహ మార్పిడికి మంచి సమయం. అయితే మృగశిరా నక్షత్ర జాతకులకు ఈ మాసంలో అన్ని విషయాలలో కొద్దిపాటి వ్యతిరేకతతో పనులు పూర్తి అగును. ఈ మాసం అన్ని రకాల కోర్సులు చేయు విద్యార్ధులకు మంచి కాలం. విద్యార్ధులకు మరియు ఉద్యోగులకు ఈ మాసంలో 9,10.16.25 తేదీలు విదేశీ ప్రయత్నములకు, ఉద్యోగ ప్రయత్నములకు అనుకూలమైనవి. ఈ మాసంలో 4, 28 తేదీలలో శ్రమ అధికంగా ఉండును.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో జీవిత భాగస్వామికి ఆరోగ్య సమస్యలు, స్త్రీలకు గర్భ సంబంధమైన అనారోగ్య సమస్యలు ఎదురగును. ధనాదాయం సామాన్యంగా ఉంటుంది. రావలసిన ధనం సమయానికి అందుట కష్టం. మాసం మధ్య నుండి వృత్తి వ్యాపారములలో అనుకూలత ప్రారంభం అవుతుంది. సంతాన ప్రయత్నములు దైవ ఆశీస్శులతో సాఫల్యం పొందును. ఆర్ధిక విషయాలలో ఆశించిన పురోగతి లభిస్తుంది. ఉద్యోగ జీవనం వదిలి వ్యాపార రంగంలోనికి అడుగు పెట్టాలి అనే భావన మనస్సులో ప్రారంభం అవుతుంది.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనుర్ రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో సొంత గృహ సౌఖ్యం కొరకు చేయు రుణ ప్రయత్నములు ఫలించును. స్త్రీ మూలక ధనప్రాప్తి అనుభవిస్తారు. బంధువులతో కలయిక నెలసరి వ్యయాన్ని కొద్దిగా అదుపు తప్పునట్టు చేయును. వ్యాపారములలో ప్రభుత్వ సంబంధ ఇబ్బందులు ఎదురవుతాయి. 18 వ తేదీ తదుపరి ఆడంబరాలకు మిక్కిలి వృధా వ్యయము చేయుదురు. నాలుగవ వారంలో ఉద్యోగ జీవనంలోని వారికి తీవ్ర ఒత్తిడి ఎదురవుతుంది. ఈ మాసంలో 8,15,17,20 తేదీలు అనుకూలమైనవి కావు.",
          Path: Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యారాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో నూతన వ్యాపారాలు, ఆలోచనలు కార్యరూపం దాల్చును. ఆర్ధిక పరిస్థితులు సంతృప్తికరంగా ఉండును. కళత్ర మూలక సంతోషాలు . ధనం అవసరములకు తగిన విధంగా సర్దుబాటు అవుతుంది. ఉద్యోగులకు పదోన్నతి లభించు అవకాశం ఉన్నది. పేరు ప్రఖ్యాతలు తిరిగి సంపాదిన్చుకోగాలుగుతారు. సంతాన ప్రయత్నములకు ఈ మాసం మంచి కాలం. ద్వితియ వారంలో ఒక ముఖ్యమైన వస్తువు పోగట్టుకొనే సూచన. ట్రేడింగ్ వ్యాపారం చేయువారికి ఈ మాసం మంచి కాలం. స్వ ఆరోగ్య విషయంలో జాగ్రత్త అవసరం. రాజకీయంగా పలుకుబడి పెరుగును. శత్రుబాధలు కొంతవరకూ తగ్గును.",
          Path:Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభరాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: " మాసంలో శరీర ఆరోగ్యం సహకరించును. మానసిక ఉల్లాసం అనుభవిస్తారు. స్థాన చలన ప్రయత్నములకు అనుకూల కాలం. ఆగిపోయిన్ పనులలో ఆటంకములు తొలగును. అధికారులతో వివాదాలు సమసిపోతాయి. ధనాదాయం బాగుండును. ఉద్యోగ మార్పిడి ప్రయత్నాలు మాత్రం విఫలం అగును. ఆశించిన ప్రమోషన్లు లభించుట కష్టం. పెద్దల సలహాలు పాటించుట మంచిది. ఈ మాసంలో 11, 15, 22, 29 తేదీలు అనుకూలమైన రోజులు కావు.",
          Path:Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకరరాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో ఉన్నతమైన ఫలితాలు పొందుతారు. వ్యక్తిగత జీవనంలోని సమస్యలు పరిష్కారమగును. అపోహలు తొలగును. ఆరోగ్య పరంగా మాత్రం జాగ్రత్త వహించాలి. నూతన వాహన లేదా ప్రయాణ సౌఖ్యం. ఆదాయం ఆశాజనకంగా ఉంటుంది. కార్యభారం లేకుండా పనులు పూర్తిచెయగలరు. నూతన పరిచయాలు ఏర్పడుతాయి. వ్యాపారాదులు సామాన్యం.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీనరాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. ద్వితీయ వారంలో ఉద్యోగ జీవనలో ఆందోళన కలిగించు వాతావరణం ఎదురగును. ఆకస్మిక వివాదాలు ఎదుర్కొంటారు. అన్ని వర్గముల వారికి ధనాదాయం తగ్గును. బంధు వర్గం వలన ఒత్తిడులు ఎదురగును. వారు మిమ్మల్ని కోరిన సహకారం ఇవ్వలేరు. స్త్రీలకు మాస మధ్యమంలో శస్త్ర చికిత్స ఏర్పడు సూచన. తృతీయ వారం నుండి సమస్యలు అధికం అగును. అనేక చికాకులు, గృహ సంబంధమైన విషయాలలో అధిక వ్యయం మానసికంగా ఇబ్బందులు ఏర్పరుస్తుంది. మొత్తం మీద ఈ మాసంలో జాగ్రత్తగా ఉండడం అవసరం.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేషరాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం అనుకూలమైన ఫలితాలను కలుగచేయును. గత మాసం కన్నా ధన వ్యయం కొద్దిగా తగ్గును. వైవాహిక జీవనంలో సమస్యలు కూడా తగ్గును. మనో నిగ్రహం అవసరం. అనవసర పట్టుదలకు పోవుట వలన సమస్యలు ఎదుర్కొందురు. ద్వితీయ మరియు తృతీయ వారములు ఉద్యోగ మార్పిడికి అనుకూల కాలం. మాసాంతానికి ఆర్ధిక పరిస్థితులలో అనుకూలత ఏర్పడుతుంది. ఈ మాసంలో 3,8,17,24,26 తేదీలు అనుకూలమైన ఫలితాలు ఇచ్చును.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో వ్యాపారములో లాభములు పెరుగును. చక్కటి ప్రోత్సాహం లభిస్తుంది. వ్రుత్తి వ్యాపారాదులలో పోటీదారులు తొలగును. ప్రభుత్వ కాంట్రాక్టులు లభిస్తాయి. ప్రధమ వారంలో కళత్ర సంబంధ అనారోగ్యం వలన మనస్తాపం ఎదురగును. మిత్రుల సహకారంతో కొన్ని సమస్యల నుండి బయట పడతారు. ద్వితియ వారంలో ఆకస్మిక ధన వ్యయములు ఎదుర్కొంటారు. విదేశీ ఉద్యోగ ప్రయత్నాలలో జాప్యం ఏర్పడుతుంది. ఊహించని అడ్డంకులు ఎదురవుతాయి. ఒక పర్యాయం శనికి శాంతి జపములు జరిపించుకోనుట వలన అడ్డంకులు తొలగుతాయి. తృతీయ వారం నుండి మాసాంతం వరకూ సామాన్య ఫలితాలు ఎదురగును. చివరి వారంలో రాత్రి పూట చేయు ప్రయాణాలలో మిక్కిలి జాగ్రత్త అవసరం.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహరాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో కళత్ర వర్గం వారి వలన సంతోషకరమైన వాతావరణం నెలకొంటుంది. నూతన వ్యక్తుల పరిచయాలు ఏర్పడుట లేదా నూతన వస్తువల అమరిక జరుగును. వివాహాది ప్రయత్నాలలో కదలిక ప్రారంభం ఐయ్యి వివాహం నిచ్చయం అగును. ఈ మాసంలో ఆదాయం సామాన్యం. రాజకీయవర్గంలోనివారికి అవమానములు ఎదురగును. భూసంబంధ లావాదేవీలు లాభించును. తృతీయ వారం నుండి గృహంలో శుభకార్య సంభదమైన వ్యయం అధికం అగును. వ్యయములకు అవసరమగు ధనం లభించును. మాసాంతములో చేయు ప్రయాణాలు లాభించును. విందు వినోదములలో పాల్గొంటారు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులారాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో సంతాన ప్రయత్న సంబంధ వ్యయం ఎదురగును. గృహంలో వేడుకలు. బంధువుల కలయికలు. సహా పంక్తి భోజనాలలో పాల్గొంటారు. ఉద్యోగ సంబందంగా అఖస్మిక ప్రయాణములు చేయవలసి వస్తుంది. ఈ మాసంలో ధనాదాయం సామాన్యం. విద్యార్ధులకు దూర ప్రాంత విద్య కొరకు చేయు ప్రయత్నములు లాభించును. వంశ పెద్దల ఆశీస్సులు పుష్కలంగా లభిస్తాయి. ఈ మాసంలో 4,5,6 తేదీలలో వాహన ప్రయాణ విషయంలో జాగ్రత్త అవసరం.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో ప్రధమ వారంలో సామాన్య ఫలితాలు ఎదురవుతాయి. ద్వితియ వారం నుండి ఊహించని అపవాదులు. మాటపాడు సంఘటనలు ఎదుర్కొంటారు. ఈ మాసంలో ఆదాయ వ్యయములు సమానం. వ్యవహార చిక్కుల వలన కొన్ని కార్యములను మధ్యలోనే విడిచిపెట్టుట జరుగుతుంది. 20 వ తేదీ తదుపరి పరిస్థితులలో అనుకూలత ప్రారంభం అగును. పెంపుడు జంతువుల వలన సమస్యలు ఎదుర్కొందురు. మాసాంతంలో కుటుంబ సంతోషాలు పొందగలుగుతారు. ఈ మాసంలో 11, 12, 13, 17 తేదీలు అనుకూలమైనవి కావు.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో కూడా ధనాదాయం సామాన్యం. ఈ మాసంలో కూడా కొద్దిపాటి మానసిక ఆందోళన ఎదురగును. అతిగా నమ్మిన వారి వలన మోసం, ధన వ్యయం ఎదురగును. సోదర వర్గం వారితో విరోధములు మానసికంగా ఇబ్బంది ఏర్పరచును. జీవిత భాగస్వామి నుండి మానసికంగా ఆశించిన సహకారం లభించదు. తృతీయ వారంలో స్వల్ప కాలిక ఆరోగ్య సమస్యలు వలన శారీరక పీడ అనుభవించును. ఈ మాసంలో 22 వ తేదీ తదుపరి కొద్దిపాటి అనుకూల ఫలితాలు లభిస్తాయి. ముఖ్యంగా చివరి వారంలో 28, 29 తేదీలలో శుభం.",
          Path:Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభరాశి",
          Vyayam: "8",
        },
      },
    ],
    [
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో మొదటి వారం అంత అనుకూల ఫలితాలు ఇవ్వదు. ఆ తదుపరి నూతన ఆదాయ మార్గములు లభిస్తాయి. వ్యాపారములో అభివృద్ధి ఆశించిన విధంగా ఉంటుంది. పిత్రు వర్గీయులతో విరోధములు చికాకు కలుగ చేస్తాయి. తృతీయ వారంలో ఊహించని రీతిలో ఒక ఆకస్మిక కష్టం ఎదురగును. అనుకోని ఖర్చులు పెట్టవలసి వస్తుంది. వివాహ ప్రయత్నములలో ఆశాభంగమునకు గురి అవుతారు. ఈ మాసంలో 5,7,25,29 తేదీలు అనుకూలమైనవి కావు. ఈ రోజులలో ఋణములు తీసుకొనుట, ఇతర ఆర్ధిక పరమైన విషయాలలో పాల్గొనుట మంచిది కాదు.",
          Path: Dhanushu,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో జరుగుతూ ఉన్న పనులలో అఖస్మిక విఘ్నములు ఎదురవుతాయి. ఈ మాసమంతా సామాన్య ఫలితాలు పొందుతారు. పితృ సంబంధ విషయాల వలన మానసిక చికాకులు ఎదుర్కొంటారు. వృత్తి జీవనం వారికి, వ్యాపారస్థులకు సామాన్య ఆదాయం లభిస్తుంది. ఉద్యోగ జీవనంలోని వారికి ఉద్యోగ మార్పిడికి మార్గములు లభిస్తాయి. వివాహ ప్రయత్నములలో ఏర్పడిన స్తంభన తొలగును. విద్యార్ధులకు పోటీ పరీక్షలలో ప్రతికూలమైన వాతావరణం ఎదురవుతుంది. రైతులకు పంట దిగుబడి ఆశించిన విధంగా ఉండదు.",
          Path:Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో విలువైన ఆభరణములు కొనుగోలుచేయుదురు. వ్యక్తిగత జీవితంలో శుభం జరుగును. మొదట ప్రయత్న విఘ్నములు ఉన్నప్పటికీ అంతిమంగా విజయం పొందుదురు. వాహన, భూ, వస్త్ర సౌఖ్యం ఏర్పడును. వ్యాపారములు లాభించును. వంశ పెద్దల సహకారం వలన కుటుంబ కలహాలు తొలగును. గృహంలో వేడుకలు జరుగును. అన్నివిధములా ఈ మాసం అనుకూలమైన కాలం.",
          Path: Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో గృహంలో చికాకులు తొలగును. ధనాదాయం బాగుండును. వృత్తి వ్యాపారములలో చక్కటి లాభములు ఆర్జిస్తారు. శుభకరమైన ఫలితాలు మరియు కార్య సిద్ధి పొందగలుగుతారు. బహుమతులు ప్రాప్తించును. ప్రయాణ సౌఖ్యం. తృతీయ వారంలో అపకీర్తి, శరీరక రుగ్మతలు బాదించును. ఈ మాసంలో ధనానికి లోటు రాదు. చివరి వారం సామాన్య ఫలితాలు ఎదురగును.",
          Path: Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో తగాదాల వలన కోర్టుకేసులు లేదా పోలిసుల జోక్యం వలన చికాకులు. వ్యాపార వ్యవహారాలు సామాన్యం. అవసరాలకు సరిపడు ధనాదాయం. మిత్రుల తోడ్పాటు లభించును. ప్రయానములక ఆరోగ్య భంగములు. జీవిత భాగస్వామి కొరకు ధనం వ్యయం చేయుదురు. అతిగా ఆలోచించడం వలన నష్టపోవు సంఘటనలు. వ్యక్తిగత జీవనంలో సౌఖ్యం. సంతాన ప్రయత్నాలు ఫలప్రదం.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో కూడా మిశ్రమ ఫలితాలు ఎదుర్కొంటారు. సొంత మనుష్యులతో విరోధములు మరియు గృహంలో అనవసర ఖర్చులు కొనసాగును. ప్రయత్నాలలో ఆటంకములు తీవ్ర నిరాశ ను ఏర్పరచును. ప్రయాసతో కూడిన ప్రయాణములు చేయవలసి వస్తుంది. జూదం వలన నష్టం పొందుతారు. జీర్ణ వ్యవస్థకు సంబందించిన సమస్యలు బాధిస్తాయి. 18 వ తేదీ తదుపరి కొంత అనుకూలత. నూతన ఆదాయ మార్గముల కొరకు చేయు ప్రయత్నాలు ఫలిస్తాయి. మాసాంతంలో కొద్దిపాటి సౌఖ్యం అనుభవిస్తారు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో అతి కష్టం మీద నిరుద్యోగులకు ఆశించిన ఉద్యోగం లభించును. ధనాదాయం బాగుండును. రాజకీయంగా పై స్థానాలలో ఉన్న వ్యక్తులతో ఉన్న పరిచయాల వలన లాభములు ఏర్పడును. వృత్తి వ్యాపారములు చేయు వారికి సామాన్య ఫలితాలు ఎదురగును. తృతీయ వారంలో గృహంలో ఆకస్మిక శుభకార్యములు నిర్వహించెదరు. బంధు సమాగమం వలన లాభపడతారు. మాసాంతంలో వ్యక్తిగత జీవితానికి సంబందించిన ఒక శుభవార్త మరియు స్త్రీ మూలక లాభము పొందుతారు. ఈ మాసంలో పునర్వసు నక్షత్ర జాతకుల వివాహ ప్రయత్నములు ఫలించును.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో నలుగురిలో మీకు ప్రత్యేకమైన గుర్తింపు లభిస్తుంది. ఉద్యోగ జీవనంలో వారికి పదోన్నతి లభిస్తుంది. వ్యాపారాదులలో ఆశించిన స్థాయిలో లాభములు పొందుతారు. కుటుంబములో మీ మాటకు విలువ పెరుగును. సంకల్ప సిద్ధి ఏర్పడును. ద్వితియ వారంలో విహార యాత్రలలో పాల్గొంటారు. సంతోషకర సమయం. ఈ మాసంలో 16 తేదీ నుండి నిలకడైన జీవనం లభిస్తుంది. ఉద్యోగ మార్పిడి ప్రయత్నాలకు ఇది మంచి కాలం. జీవిత భాగస్వామితో సోఖ్యం. ఉన్నత వర్గం వారితో స్నేహ సంభందాలు ఏర్పడును. తన్మూలక లాభములు పొందుతారు.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాస ప్రారంభంలో సొంత మనుష్యులతో వివాదములు నెలకొంటాయి. ఆకస్మిక అనారోగ్య సూచన ఉన్నది. చర్మ సంభండ దోషములు ఇబ్బంది పెట్టును. తలచిన కార్యములు అటంకములను ఎదుర్కొనా చివరికి పూర్తి అగును. వృధా వ్యయములు తరచుగా ఏర్పడును. 16వ తేదీ నుండి పరిస్థితి అనుకులించును. విద్యార్ధులకు, విదేశీ విద్య కొరకు ప్రయత్నించు వారికి తృతీయ వారం నుండి మంచి అనుకూల కాలం ప్రారంభం అగును. కార్యమార్గంలో చిన్న చిన్న ఆటంకములు ఉన్నప్పటికీ వ్యవహార విజయం ఉండును. మాసాంతంలో ఆనందమయ సమయం అనుభవిస్తారు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో ధన ఆదాయం ఆశించిన స్థాయిలో ఉండదు. మాసం మొత్తం సామాన్య ఫలితాలు ఏర్పడును. అవివాహితులకు వివాహ ప్రయత్నాలలో కష్టం మీద సఫలత లభిస్తుంది. అతిగా ఆలోచించడం వలన చక్కటి అవకాశములను కోల్పోవుదురు. ఈ మాసంలో వృత్తి వ్యాపారాదులు సామాన్యంగా కొనసాగును. ఈ మాసంలో 9,13,17,23 మరియు 29 తేదీలు మంచివి కావు.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం సంతోషకరమైన ఫలితాలను కలిగించును. జీవన మార్గంలో సుఖ ప్రాప్తి లభించును. ఈ మాసంలో ఆశించిన ధనం లభించును. ప్రత్యర్ధులపై విజయం మరియు సకల కార్య విజయాలు పొందుతారు. సంసార సంబంధమైన సంతోషాలు పరి పూర్ణంగా పొందగలుగుతారు. దీర్ఘకాలికంగా పరిష్కారం అవ్వని వారసత్వ సంబంధ సమస్యలు పరిష్కారం పొందును. ప్రభుత్వ సంబంధ వ్యవహారములు లాభించును. ఈ మాసంలో 2, 6, 8, 11, 21, 26 తేదీలు కార్యానుకులత కలిగించును.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో మాసంలో దీర్గకాళిక అనారోగ్యం తో బాధ పడుతున్న వారికి శరీర ఆరోగ్యం బాగుండును. మనసు ఉల్లాసంగా ఉండును. విందు వినోదాలలో పాల్గోనేదురు. ఈ మాసంలో 10, 14, 15 తేదీలలో ప్రయాణాలలో జాగ్రత్త అవసరం. ద్వితియ వారంలో సంతాన సంబంధ అనారోగ్యం బాధించును. ఇష్టమైన వారికి సంబంధించిన అశుభ వార్త వినుటకు అవకాశం ఉన్నది. ఈ మాసంలో తృతీయ వారం నుండి అనగా 21 వ తేదీ తదుపరి కార్య సిద్ధి లభించును. ప్రయత్న విజయములు ప్రాప్తించును. దూర స్నేహితుల కలయిక వలన ఆనందం ఏర్పడుతుంది. నెల మొత్తం ధనాదాయం సామాన్యం. నూతన దంపతుల సంతాన ప్రయత్నములు కూడా లాభించును.",
          Path: Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో సమస్యలు కొంత తగ్గుముఖం పడతాయి. తగాదాలలో మీ వాదనలకు విలువ లభిస్తుంది. బంధువుల కలయిక వలన సంబంధాలు మెరుగుపడును. ఎంతో కాలంగా వాయిదా పడుతున్న పనులు పూర్తి అగును. జీవిత భాగస్వామి తో సమస్యలు తగ్గును. 22 వ తేదీ తదుపరి సంతోషకర వార్తలు వింటారు. జీవనంలో కోరుకున్న విధంగా ఉన్నతి లభిస్తుంది. ఈ మాసంలో ఆదాయంలో పెరుగుదల పొందుతారు. సువర్ణ పెట్టుబడులు చేస్తారు.",
          Path: Dhanushu,

          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసం గృహ సంబంధ నిర్మానములకు, ఋణ ప్రయత్నములకు మంచిది. ద్వితియ వారంలో ప్రభుత్వ సన్మానం లేదా ప్రభుత్వ సంబంధ ప్రయోజనం పొందుతారు. కార్య సిద్ధి యోగం ఉన్నది. దూర ప్రాంత ప్రయాణములు అనుకున్న రీతిలో ముగుస్తాయి. తృతీయ వారంలో స్వల్ప కాలిక అనారోగ్య సమస్యలు బాదిస్తాయి. శస్త్ర చికిత్స అవసరం ఉండదు. చివరి వారంలో సామాన్య ఫలితాలు ఎదురవుతాయి. కళత్రముతో చికాకులు కొనసాగును.",
          Path:Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ఆదాయం సామాన్యం. తలపెట్టిన కార్యములందు అవకాశములు చేజారిపోవును. నూతన ఆదాయ మార్గముల కొరకు శ్రమించవలెను. పురుష సంతానం వలన సమస్యలు ఎదురగును. వృత్తి నైపుణ్యాలు పెంచుకోవాల్సిన అవసరం ఏర్పడును. తగాదాల వలన అశాంతి అనుభవిస్తారు. ఇచ్చిపుచ్చుకొను వ్యవహారములలో జాగ్రత్త వహించవలెను. స్త్రీలకూ ఆరోగ్య సమస్యలు ఉన్నవి.",
          Path: Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో మానసిక ఒత్తిడి అధికం అవుతుంది. కానీ ఆదాయం బాగుండును. రావలసిన ధనం చేతికి వచ్చును. ద్వితియ వారంలో వాహన ప్రమాద సూచన. తృతీయ వారం ధన వ్యయం, ఆరోగ్య విషయాలలో అననుకూలత, ఆశాభంగములు వంటి సమస్యలు బాదించును. 24, 25, 26 తేదీలలో ఉద్రేకం వలన నష్టం కొని తెచ్చుకుంటారు.",
          Path: Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసంలో కూడా ఏలినాటి శని ప్రభావం అధికం. వైవాహిక జీవనంలో అననుకూల ఫలితాలు. ద్వితీయ తృతీయ వారాలలో కొద్దిపాటి ఆరోగ్య సమస్యలు. భవిష్యత్ ప్రణాళికలు రచించుటకు లేదా పెద్ద నిర్ణయాలు తీసుకొనుటకు 21 వ తేదీ తదుపరి కొంత అనుకూలమైన కాలం. ఈ మాసంలో 9,10,11 తేదీలలో ప్రమాదం లేదా వాహనముల వలన సమస్యలు.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసం కొంచం అనుకూల ఫలితాలు ఏర్పరచును. కుటుంబ పరమైన వేధింపులు తగ్గుతాయి. వ్యవహార నష్టములు తగ్గుముఖం పడుతాయి. నూతన వ్యాపారములు ప్రారంభించుటకు ఈ మాసం అనుకూలమైన కాలం. ఉద్యోగ జీవనంలో మార్పులకు కూడా ఈ మాసం అనుకూలమైనది. వ్యయం అదుపులోకి వస్తుంది. కాలి నరములకు సంబంధించిన ఆరోగ్య సమస్యలు ఇబ్బందులు పెడతాయి. ఈ మాసంలో తృతీయ మరియు చతుర్ధ వారములు పితృ వర్గీయులకు మంచిది కాదు. ఈ మాసంలో 11 నుండి 16 వరకూ నూతన కార్య ప్రారంభములకు అనుకూలమైన రోజులు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసంలో గౌరవ హోదాలు పెరుగును. ఆశించిన విధంగా నూతన పదవుల ప్రాప్తించును. చిరు ఉద్యోగులకు జీవన స్థిరత్వం లభిస్తుంది. కుటుంబ సంతోషములు ఏర్పడుతాయి. సొంత గృహ నిర్మాణ సంబంధ లాభములు పొందుతారు. విదేశీ సంబంధ స్థాన చలనత ఏర్పడుతుంది. గృహంలో ఖరీదైన నూతన వస్తువులను అమర్చుకుంటారు. ఈ మాసంలో 22వ తేదీ తదుపరి ప్రారంభించు నూతన వ్యాపారములు లాభించును. మాసాంతంలో ఉద్యోగ భవిష్యత్ గురించిన ఆందోళన పూర్తిగా తొలగుతుంది. ఈ మాసంలో 1,6,10,19 తేదీలు అంత అనుకూలమైనవి కావు.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో అన్నివిధాలా ఆదాయం బాగుండును. ఈ మాసం విదేశీ ప్రయత్నములు, వివాహ ప్రయత్నములు, సంతాన ప్రయత్నములకు చక్కటి అనువైన కాలం. మాస మధ్యమంలో ఆకస్మిక ప్రయాణములు చేయవలసి వస్తుంది. పితృ వర్గీయులకు ఈ మాసం అశుభం కలుగ చేయు సూచన ఉన్నది. ఈ మాసంలో 20,21,22 తేదీలలో మిత్ర సంబంధ వ్యవహారములలో నష్టం ఏర్పడును. చివరి వారంలో శారీరక శ్రమ అధికం అగుట వలన అలసట అనుభవిస్తారు. నరముల సంబందిత సమస్యలు బయట పడు అవకాశం ఉన్నది. మానసిక విచారం ఎదుర్కొందురు.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. షేర్ మార్కెట్లో పెట్టుబడులు పెట్టిన వారికి నష్టములు ఎదురగును. ద్వితియ వారంలో దూరప్రాంతము నుండి ఒక అశుభ వార్త వినవలసి వస్తుంది. గృహ వాతావరణం అశాంతిని కలిగి ఉండును. మాస మధ్యలో ఖర్చులు అధికంగా ఉండును. కోర్టు వ్యవహరములు చిరాకు కలుగ చేయును. చివరి వారంలో ఒంటరితనం బాధించును. కళాకారులకు ప్రోత్సాహకరమైన కాలం. విద్యార్దులు శ్రమించవలెను. అన్యకార్యములపై శ్రద్ధ తగ్గించ వలెను. ఈ మాసంలో 2,6,7,15,29 తేదీలు అనుకూలమైనవి కావు.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో జీవిత భాగస్వామి సంబంధిత ధన లాభములు ఏర్పడును. స్వార్జిత పరంగా కూడా ఆర్ధికంగా చక్కటి సౌఖ్యం అనుభవిస్తారు. పుష్యమి నక్షత్ర జాతకులకు ఈ మాసంలో స్టాక్ మార్కెట్ ట్రేడింగ్ కలసి వస్తుంది. మానసిక ఉల్లాసం అనుభవిస్తారు. తృతీయ వారం పూర్తి అగు వరకూ అనుకూలంగా ఉంటుంది. చివరి వారం నుండి ఉద్యోగ జీవనంలో ఒత్తిడి అధికం అవుతుంది. సమయా భావం వలన ఇబ్బంది పడతారు. ఉద్యోగంలో తోటివారి సహకారం లభించదు. మీ నుండి సహాయం పొందిన వారు కూడా మీకు సహకారం అందించకుండా ఉండుట మిమ్మల్ని బాధిస్తుంది.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసంలో కూడా చక్కటి అనుకూలమైన ఫలితాలు ఏర్పడును. ప్రధమ మరియు ద్వితీయ వారాలు అన్ని విధములా సౌకర్యవంతమైన జీవనాన్ని ప్రసాదించును. తృతీయ వారంలో మాత్రం సుఖ వ్యాధుల వలన బాధ పొందు సూచన ఉన్నది. ఈ మాసం అంతా ధనాదాయం బాగుండును. ఆర్ధిక పరమైన సంకల్పములు నెరవేరును. బంధు వర్గం వారితో మాత్రం జాగ్రత్త అవసరం. మొత్తం మీద ఈ మాసం ఉత్సాహపూరిత సమయం.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో వృత్తి, ఉద్యోగ, వ్యాపార వ్యవహారాలలో సామాన్య ఫలితాలు ఎదురగును. ధనాదాయం కూడా సామాన్యం. ద్వితీయ మరియు తృతీయ వారాలలో అవివాహితుల కు వివాహ సంబంధ సంతోషాలు ఎదురగును. నూతన వాహన సౌఖ్యం ఏర్పరచు కొనగలరు. తృతీయ వారం నుండి తలపెట్టిన పనులందు కార్య విఘ్నతలు ఎదురగును. ప్రతీ పనికి శ్రమించవలెను. ఉద్యోగస్తులకు కూడా చికాకులు ఎదురగును. స్త్రీలకు ఈ మాసంలో అంతగా అదృష్టం కలసిరాదు. ముఖ్యంగా 19, 20, 29 తేదీలలో అధికంగా సమస్యలు ఎదురగును. మాసాంతంలో కుటుంబ పెద్దవారితో వ్యవహార చిక్కులు ఎదురగును.",
          Path: Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "8",
        },
      },
      {
        DhanasuRasi: {
          Aadayama: "11",
          Avamanam: "5",
          Facolor: "బ్రైట్ నియాన్ పింక్(ప్రకాశవంతమైన నియాన్ పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "మూల 1,2,3,4 పాదములు లేదా పుర్వాషాడ 1,2,3,4 పాదములు లేదా ఉత్తరాషాడ 1 వ పాదములో జన్మించినవారు ధనస్సురాశి చెందును.",
          Namestart:
            "మూల:/యే/యో/బా/బీ,పూర్వాషాడ:/బూ/ధా/భా/ఢా,ఉత్తరాషాడ:/బే వీటికి ధనస్సురాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఏర్పడతాయి. ఆత్మీయులకు సంబంధించిన ఒక విషయం ఆందోళన కలుగచేస్తుంది. ఆదాయం బాగుండును. ధన సమస్యలు తొలగును. ద్వితియ మరియు తృతీయ వారాలలో ఉత్తమ ఫలితాలు పొందుతారు. కుటుంబ జీవన ప్రమాణాలు పెంచగలుగుతారు. జీవిత భాగస్వామి తోడ్పాటు సంపూర్ణంగా లభించుట వలన మానసిక ప్రశాంతత ఏర్పడుతుంది. పుత్ర పౌత్రాభివృద్ధి అనుభవిస్తారు.",
          Path: Dhanushu,

          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "ధనస్సు రాశి",
          Vyayam: "5",
        },
        KanyaRasi: {
          Aadayama: "5",
          Avamanam: "2",
          Facolor: "ఎమెరాల్డ్ గ్రీన్(పచ్చ ఆకుపచ్చ రంగు)",
          Fanum: "1",
          Nakshatra:
            "ఉత్తర 2,3,4 పాదములు లేదా హస్త 1,2,3,4 పాదములు లేదా చిత్త 1,2 పాదములలో జన్మించిన వారు కన్యారాశికి చెందును.",
          Namestart:
            "ఉత్తర ఫల్గుణి:/టో/పా/పీ,హస్త:/వూ/షం/ణా/ఢా,చిత్త:/పే/పో ఈ మూడింటిలోని అక్షరాలకు కన్యారాశి.",
          Para: "ఈ మాసంలో పరిస్థితులు అనుకూలంగా ఉండును. భూ లాభం పొందుతారు. అదృష్టం కలసివస్తుంది. వ్యపారాదులలో అధికమైన ధనప్రాప్తి లబిస్తుంది. సుఖసంతోషాలు సంపూర్ణంగా ప్రాప్తిస్తాయి. విందులు, వేడుకలలో పాల్గోనేదురు. వస్త్ర లాభం ఉన్నది. జీవిత భాగస్వామి తో మనస్పర్ధలు తొలగును. ఈ మాసంలో 16, 17, 18, 19 తేదీలు నూతన వ్యాపార వ్యవహారములకు మంచివి. అయితే లోహ సంబంధ వ్యాపారములు ఈ నెలలో మొదలు పెట్టుట మంచిది కాదు. ఈ మాసంలో చేయు ఇతర పెట్టుబడులు లాభించును.",
          Path:Kanya,
          Rajapujyam: "5",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కన్యా రాశి",
          Vyayam: "5",
        },
        KumbhaRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "ఫైర్బ్రిక్(ఫైర్బ్రిక్ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ధనిష్ఠ 3,4 పాదములు లేదా శతభిషం 1,2,3,4 పాదములు లేదా పూర్వాభాద్ర 1,2,3,4 పాదములలో జన్మించినవారు కుంభరాశి కి చెందును.",
          Namestart:
            "ధనిష్ట:/గూ/గే,శతభిషం:/గో/సా/సీ/సూ,పూర్వాభద్ర:/సే/సో/దా వీటికి కుంభరాశి.",
          Para: "ఈ మాసంలో ధనాదాయం పెరుగును. కుటుంబములో స్త్రీల అనారోగ్యం కొనసాగును. స్థిరాస్తి ఋణములు కొంతవరకూ తీరును. సోదర వర్గం వారికి ఖ్యాతి పెంపొందుతుంది. ప్రయాణ మూలక ఆరోగ్య భంగములు ఎదురగును. ఆహార విషయంగా సమస్యలు పొందుతారు. ఈ మాసంలో ఆశించిన ఉద్యోగ అవకాశములు లభించును. అన్య స్త్రీ సంబధాలు ఇబ్బందులను కలిగించును. ఈ మాసంలో 26, 27 తేదీలలో అవాంచిత తగాదాలు ఎదుర్కొందురు.",
          Path: Kumbha,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కుంభ రాశి",
          Vyayam: "14",
        },
        MakaraRasi: {
          Aadayama: "14",
          Avamanam: "1",
          Facolor: "బ్రన్ట్ పింక్(కాల్చిన గులాబీ రంగు)",
          Fanum: "3",
          Nakshatra:
            "ఉత్తరాషాడ 2,3,4 పాదములు లేదా శ్రవణం 1,2,3,4 పాదములు లేదా ధనిష్ఠ 1,2, పాదములలో జన్మించినవారు మకరరాశికి చెందును.",
          Namestart:
            "ఉత్తరాషాడ:/బో/జా/జీ,శ్రవణం:/జూ/జే/జో//ఖా,ధనిష్ట:/గా/గీ వీటికి మఖరరాశి.",
          Para: "ఈ మాసంలో బాగుండును. ధనాదాయం పెరుగును. వృత్తి ఉద్యోగ వ్యపారాదులలో ప్రోత్సాహం. పై అధికారుల వలన కొద్దిగా అశాంతి. కీళ్ళ సంబంధమైన అనారోగ్యం బాదించును. కానీ ప్రమాదం ఉండదు. వ్యాపార విస్తరణకు ఈ మాసం మంచి సమయం. వ్యవహారములు సానుకూలంగా పూర్తి అగును. గృహంలో ఆకస్మిక శుభకార్యములు. ఆధిపత్య పోరు కొంత బాధించును.",
          Path: Makara,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మకర రాశి",
          Vyayam: "14",
        },
        MeenaRasi: {
          Aadayama: "11",
          Avamanam: "4",
          Facolor: "టుర్క్కువోయిస్(మణి రంగు)",
          Fanum: "7",
          Nakshatra:
            "పూర్వాభాద్ర 4వ పాదము లేదా ఉత్తరాభాద్ర 1,2,3,4 పాదములు లేదా రేవతి 1,2,3,4 పాదములలో జన్మించిన వారు మీనరాశికి చెందును.",
          Namestart:
            "పూర్వా బాధ్ర:/దీ,ఉత్తరా బాధ్ర:ధు/శ్చం/చా/ధా,రేవతి:/దే/దో/చా/చీ వీటికి మీనరాశి.",
          Para: "ఈ మాసం ఆశించిన శుభ ఫలితాలు కలుగచేయును. వ్యాపార, ఉద్యోగ వ్యవహారాలలో విజయం. పై అధికారుల వలన మన్ననలు. నూతన కార్యములను ప్రారంభించవచ్చు. అవకాశములు సద్వినియోగం చేసుకుందురు. కుటుంబ వాతావరణం అనుకూలంగా ఉండును. శ్రమకు తగిన గుర్తింపు. జీవన విధానాలలో పురోగతి. ఖర్చులు తగ్గును. కాలం కలసివచ్చును.",
          Path: Meena,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మీన రాశి",
          Vyayam: "5",
        },
        MeshaRasi: {
          Aadayama: "8",
          Avamanam: "3",
          Facolor: "ఆష్ గ్రెయ్(బూడిద రంగు)",
          Fanum: "5",
          Nakshatra:
            "అశ్విని నక్షత్రం 1,2,3,4 పాదములు లేదా భరణి నక్షత్రం 1,2,3,4 పాదములు లేదా కృత్తిక నక్షత్రం 1వ పాదములో జన్మించినవారు మేషరాశికి చెందును.",
          Namestart:
            "అశ్వని:చూ/చే/చో/లా,భరణి:లీ/లూ/లే/లో,కృత్తిక:/ఆ ఈ ఊ ఏ పైన కన బరచిన అక్షరాలకు మేషరాశి.",
          Para: "ఈ మాసంలో శరీర ఆరోగ్య సంబంధ విషయాలలో అనుకూలత ఏర్పడును. శత్రుత్వములు తొలగును. మానశిక శాంతి లభించును. వృత్తిలో ప్రోత్సాహకర వాతావరణం ఉంటుంది. ద్వితియ వారం, తృతీయ వారములలో జీవితంలో చక్కటి పురోగత లభిస్తుంది. కోర్టు తీర్పులు అనుకూలం గా లభించును. నాలుగవ వారం నుండి చేపట్టిన కార్యములందు స్తబ్ధత. శ్రమ అధికం. ప్రయత్న పూర్వక విజయాలు.",
          Path: RashiIcons,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మేష రాశి",
          Vyayam: "14",
        },
        MithunaRasi: {
          Aadayama: "5",
          Avamanam: "6",
          Facolor: "ఆరంజ్ గోల్డ్(నారింజ బంగారం రంగు)",
          Fanum: "1",
          Nakshatra:
            "మృగశిర 3,4 పాదములు లేదా ఆరుద్ర 1,2,3,4 పాదములు లేదా పునర్వసు 1,2,3 పాదములలో జన్మించిన వారు మిధునరాశికి చెందును.",
          Namestart:
            "మృగశిర:కా/కీ,ఆరుద్ర:కూ/ఖం/జ/ఛా,పునర్వసు:కే/కో/ హ,ఈ మూడింటికి మిధునరాశి.",
          Para: "ఈ మాసం ప్రధమ వారంలో మిత్రులతోనూ, అధికారులతోనూ విభేదములు ఎదురగును. ద్వితియ వారంలో మిశ్రమ ఫలితాలు పొందుతారు. తృతీయ వారంలో సంతాన సంబంధ విషయాల్లో సౌఖ్యత అనుభవిస్తారు. కుటుంబ వాతావరణంలో ఆనందాలు ఏర్పడుతాయి. భాత్రు వర్గం వారితో తగాదాలలో విజయం పొందుతారు. ఈ మాసంలో 20 వ తేదీ నుండి ఆర్ధిక పరమైన విషయాలలో అనుకూల ఫలితాలు పొందుదురు. ఆకస్మిక ధన లాభాములకు అవకాశం ఉన్నది.",
          Path: Midhuna,
          Rajapujyam: "3",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "మిధున రాశి",
          Vyayam: "5",
        },
        SimhaRasi: {
          Aadayama: "2",
          Avamanam: "2",
          Facolor: "వాంపైర్ గ్రెయ్(పిశాచ బూడిద రంగు)",
          Fanum: "7",
          Nakshatra:
            "మాఘ 1,2,3,4 పాదములు లేదా పుబ్బ 1,2,3,4, పాదములు లేదా ఉత్తర 1వ పాదంలో జన్మించిన వారు సింహరాశికి చెందును.",
          Namestart:
            "మఖ:/మా/మి/మూ/మే,పూర్వ ఫల్గుణి:మో/టా/టీ/టూ,ఉత్తర ఫల్గుణి:టే/,ఈ మూడింటికి సింహరాశి.",
          Para: "ఈ మాసంలో కూడా ఆరోగ్య సమస్యలు కొనసాగును. ఆశించిన స్థాయిలో కుటుంబం నుండి సహకారం లభించదు. ప్రభుత్వ ఉద్యోగులకు ఆశించని స్థాన చలనం ఏర్పడుతుంది. పిల్లలలో మానసిక చాంచల్యత ఆందోళన కలుగ చేస్తుంది. ఈ మాసంలో 17, 18 తేదీలలో ప్రయాణాలు చేయుట వాయిదా వేసుకొనుట మంచిది. ద్విచక్ర వాహన దారులకు వాహన ప్రమాద సూచన ఉన్నది. 20 వ తేదీ తదుపరి వృత్తి ఉద్యోగ వ్యపారాదులలో జయం లభిస్తుంది. సౌకర్య వంతమైన జీవనం అనుభవిస్తారు. మాసాంతానికి అన్నివిధములా పరిస్థితులు అనుకూలంగా ఉండును.",
          Path:Simha,
          Rajapujyam: "2",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "సింహ రాశి",
          Vyayam: "14",
        },
        TulaRasi: {
          Aadayama: "2",
          Avamanam: "5",
          Facolor: "రెడ్ ఫాక్స్(ఎరుపు నక్క రంగు)",
          Fanum: "1",
          Nakshatra:
            "చిత్త 3,4 పాదములు లేదా స్వాతి 1,2,3,4 పాదములు లేదా విశాఖ 1,2,3 పాదములులో జన్మించినవారు తులారాశికి చెందును.",
          Namestart:
            "చిత్త:/రా/రి,స్వాతి:/రూ/రే/రో/లా,విశాఖ:/తీ/తూ/తే,వీటికి తులారాశి.",
          Para: "ఈ మాసంలో కూడా కొద్దిపాటి కష్టములు ఎదురగును. ఒక పర్యాయం తిరుమల శ్రీ వెంకటేశ్వర స్వామి వారి దర్శనం చేసుకొనుట మంచిది. ఆరోగ్య మందగమనం ఆందోళన కలిగించును. తృతీయ వారం నుండి ఆహార అలవాట్లు, వ్యసనాల పట్ల జాగ్రత్త అవసరం. ఈ మాసంలో 23,24, 27,29 తేదీలలో నూతన కార్యములు, వివాహము కొరకు చేయు ప్రయత్నములు లాభించును.",
          Path: Tula,
          Rajapujyam: "1",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "తులా రాశి",
          Vyayam: "8",
        },
        karkatakaRasi: {
          Aadayama: "14",
          Avamanam: "6",
          Facolor: "బాషఫుల్ పింక్(ధైర్యవంతమైన పింక్ రంగు)",
          Fanum: "7",
          Nakshatra:
            "పునర్వసు నక్షత్ర 4 వ పాదం లేదా పుష్యమి నక్షత్ర 1,2,3,4 పాదములు లేదా ఆశ్లేషా నక్షత్ర 1,2,3,4 వ పాదములలో జన్మించిన వారు కర్కాటకరాశికి చెందును.",
          Namestart:
            "పునర్వసు:/హీ/,పుష్యమి:హు/హే/హో/డా,ఆశ్లేష:డీ/డూ/డే/డో వీటికి కర్కాటకరాశి.",
          Para: "ఈ మాసంలో చక్కటి ప్రోత్సాహకరమైన వాతావరణం ఉంటుంది. అన్ని విధములా లాభ పడతారు. కెరీర్ పరంగా ఆశించిన స్థాయిలో అభివృద్దిని పొందుదురు. వంశ గౌరవం పెరుగుతుంది. నూతన వ్యాపార ప్రయత్నాలు విజయవంతం అవుతాయి. గౌరవ ప్రదమైన జీవనం లభిస్తుంది. దగ్గరి బంధువులకు సంబంధించిన ఒక అశుభ వార్త వినవలసి వస్తుంది. మొత్తం మీద ఈ మాసంలో ధనాదాయం కొంత వృద్ది చెందుతుంది. ప్రత్యర్ధులపై మీదే పై చేయి అవుతుంది.",
          Path: Karkata,
          Rajapujyam: "6",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "కర్కాటక రాశి",
          Vyayam: "2",
        },
        vrischikaRashi: {
          Aadayama: "8",
          Avamanam: "5",
          Facolor: "పర్పల్ సెజ్ బుష్(ఊదా సేజ్ బుష్ రంగు)",
          Fanum: "5",
          Nakshatra:
            "విశాఖ 4 వ పాదము లేదా అనురాధ 1,2,3,4 పాదములు లేదా జ్యేష్ఠ 1,2,3,4 పాదములులో జన్మించినవారు వృశ్చిక రాశికి చెందును.",
          Namestart:
            "విశాఖ:/తో,అనూరాధ:/నా/నీ/నూ/నే,జ్యేష్ట:/నో/యా/యీ/యూ వీటికి వృశ్చికరాశి.",
          Para: "ఈ మాసం మిశ్రమ ఫలితాలను కలిగించును. కారణం తెలియని మానసిక భయం ఏర్పడు సూచన. మనసున తొందరపాటుతనం వలన మీ బాధ్యతలు సక్రమంగా నిర్వర్తించ లేరు. పై అధికారుల వలన ఒత్తిడి అధికం అగును. ద్వితియ వారంలో సంతాన సంబంధ ప్రయత్నాలు ఫలించును. కుటుంబ ఖర్చులు కూడా అధికమగును. ఈ మాసంలో 14, 15 తేదీలలో చేయు ప్రయానములందు జాగ్రత్త వహించవలెను.",
          Path: Vrushika,
          Rajapujyam: "4",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృశ్చిక రాశి",
          Vyayam: "14",
        },
        vrishabhaRashi: {
          Aadayama: "2",
          Avamanam: "3",
          Facolor: "ఇండిగో(నీలిరంగు)",
          Fanum: "1",
          Nakshatra:
            "మీ జన్మ నక్షత్రం కృత్తిక 2,3,4 పాదములు లేదా రోహిణి 1,2,3,4 పాదములు లేదా మృగశిర 1,2 పాదములలో ఒకటి ఐయిన మీది వృషభరాశి.",
          Namestart:
            "కృత్తిక:ఈ/ఊ/ఏ,రోహిణి:ఈ/వా/వీ/వూ,మృగశిర:వే/వో ఈ మూడింటికి వృషభరాశి.",
          Para: "ఈ మాసంలో మిశ్రమ ఫలితాలు ఎదురగును. ధనాదాయం కొంత తగ్గును. గృహ సంతోషములు సామాన్యం. ధన వ్యయం కొంత అదుపు తప్పుతుంది. కుటుంబంలో నూతన వ్యక్తుల చేరికలు ఏర్పడటానికి అవకాశం ఉన్నది. తలవంపులు ఎదుర్కోను సంఘటనలు ఉన్నవి. ఇతరులతో మాట్లాడు సందర్భాలలో మాట జాగ్రత్త అవసరం. ఈ మాసంలో 17, 18 తేదీలలో అనవసర తగాదాలు ఎదుర్కొందురు. నాలుగవ వారంలో మానసిక ప్రసాంతత మరియు కార్య సిద్ధి లభించును. ఈ మాసంలో 3, 11, 20 తేదీలు నిరుద్యోగుల ఉద్యోగ ప్రయత్నములకు మంచిది. ఆశించిన ఉద్యోగ ప్రాప్తి లభించును.",
          Path: Vrushabha,
          Rajapujyam: "7",
          RasiDates: "ది.09-ఏప్రిల్-2024 నుండి ది.30-మార్చి-2025 వరకు",
          Samvatsaram: "శ్రీ క్రోధి నామ సంవత్సరం",
          Title: "వృషభ రాశి",
          Vyayam: "8",
        },
      },
    ],

  ]
}